import { useSelector } from "react-redux";
import {
  GetData,
  PostDataWithToken,
  PostImageDataWithToken,
} from "../../../api/apiHelper";
import { GetData_V3, PostDataWithToken_V3 } from "../../../api/apiHelper_V3";
import store from "../../../store";
import {
  apiErrorReponse,
  dispatchQuickQuote,
  quickQuoteResult,
  removeApiErrorMessage,
  saveApiErrorReponse,
} from "../../../store/actions/userActions";
import {
  quickQuotePayloadObj,
  createOnlinePolicyObj,
  createQuotePayloadObj,
  motorQuotationObj,
  submitProposalRequest,
  kycStatus,
  kycObj,
} from "../../../store/reducers/userReducers";
import { sendErrorMessage } from "../../Services/PosService";
import { policyResultArr } from "./MakeModelArr";
import axios from "axios";
import moment from "moment";
import ZunoPayment from "../../pages/Additional/ZunoPayment";
import { useNavigate } from "react-router-dom";
import CryptoJS from 'crypto-js'
import { error } from "jquery";
import { decryptAes256Text } from "../../utility/constants";
// Logo, Api_name, PolicyId, ApiId, RegisterNumber, Make, Model, variant,
// BasicODPremium, BasicTPPremium, PAForOwner, NetPremium, GST, FinalPremium,
// MinIdv, MaxIdv, StartDate, EndDate, discount, insurer

export const createCustomer = (postdata) => {
  let formData = new FormData();
  for (let key in postdata) {
    formData.append(key, postdata[key]);
  }
  PostImageDataWithToken("motor/create-customer", formData).then((response) => {
    if (response?.status === true) {
      dispatchQuickQuote("customerId", response?.data?.id);
    } else {
      // sendErrorMessage(response)
    }
  });
};

export const savePolicyToDatabase = (apiRequestQQ) => {
  let obj = createOnlinePolicyObj(apiRequestQQ);
  let formData = new FormData();
  for (let key in obj) {
    formData.append(key, obj[key]);
  }
  // formData.append("posId", store.getState().root.userDetails.id);
  formData.append("customerId", store.getState().root.apiRequestQQ.customerId);
  PostImageDataWithToken("motor/fill-policy-data", formData).then(
    (response) => {
      if (response.status === true) {
        dispatchQuickQuote("policyId", response.data.id);
      } else {
        // sendErrorMessage(response)
      }
    }
  );
};

export const changePolicyStatus = (status, leadId) => {
  let postdata = {
    status: status,
    leadId: leadId,
  };
  PostDataWithToken("motor/change-lead-status", postdata).then((response) => {
    if (response.status === true) {
      console.log("status changed");
    } else {
      toast.info(response.message);
    }
  });
};

function generateRandom7DigitNumber() {
  const min = 1000000; // Minimum 7-digit number
  const max = 9999999; // Maximum 7-digit number
  return Math.floor(Math.random() * (max - min + 1)) + min;
}


function generateUniqueId() {
  const prefix = "PLCYEXPT";
  const randomNumber = generateRandom7DigitNumber();
  return `${prefix}${randomNumber}`;
}



function HmacSHA256(message, secret) {
  try {
    const sha256_HMAC = crypto.createHmac('sha256', secret);
    const raw = sha256_HMAC.update(message).digest();

    let ls_sb = '';
    for (let i = 0; i < raw.length; i++) {
      ls_sb += char2hex(raw[i]);
    }
    return ls_sb;
  } catch (error) {
    console.error(error);
    return null;
  }
}


function char2hex(x) {
  const arr = [
    '0', '1', '2', '3',
    '4', '5', '6', '7',
    '8', '9', 'A', 'B',
    'C', 'D', 'E', 'F'
  ];

  const c = [arr[(x & 0xF0) >> 4], arr[x & 0x0F]];
  return c.join('');
}

// Digit API

export const digitAPICall = (apiRequestQQ) => {
  const min = 10000000000;
  const max = 99999999999;

  const randomNumber = Math.floor(Math.random() * (max - min + 1) + min);

  const uniqueNumber = "Digit" + randomNumber;

  dispatchQuickQuote("ApiUniqueNumber.Digit", uniqueNumber);

  const newData = {
    ...apiRequestQQ,
    ApiUniqueNumber: uniqueNumber,
    PolicyIssueDate: new Date().toLocaleDateString("en-GB").toString(),
  };

  console.log("New Digit Data", newData);
  PostDataWithToken("motor/digit", quickQuotePayloadObj(newData))
    .then((response) => {
      if (response.status === true) {
        store.dispatch(removeApiErrorMessage("digit"));

        let {
          enquiryId,
          contract,
          vehicle,
          netPremium,
          grossPremium,
          serviceTax,
          discounts,
        } = response.data;

        let ncbDiscount = 0;
        if (discounts?.otherDiscounts && discounts.otherDiscounts.length > 0) {
          let index = discounts.otherDiscounts.findIndex(
            (item) => item.discountType === "NCB_DISCOUNT"
          );
          if (index > -1) {
            ncbDiscount =
              discounts.otherDiscounts &&
              discounts.otherDiscounts[index].discountAmount;
          }
        }
        store.dispatch(
          quickQuoteResult(
            policyResultArr(
              "/assets/images/logos/partners/digit.png",
              "digit",
              "",
              enquiryId,
              vehicle.licensePlateNumber,
              vehicle.make,
              vehicle.model,
              "",
              contract.coverages.ownDamage.netPremium
                ? contract.coverages.ownDamage.netPremium
                : 0,
              contract.coverages.thirdPartyLiability.netPremium
                ? contract.coverages.thirdPartyLiability.netPremium
                : 0,
              contract.coverages.personalAccident.selection === true
                ? contract.coverages.personalAccident.netPremium
                : false,
              netPremium,
              serviceTax.totalTax,
              grossPremium,
              apiRequestQQ?.idvRangeSet
                ? apiRequestQQ?.initialIdvData.digit.min
                : vehicle.vehicleIDV.minimumIdv || 0,
              apiRequestQQ?.idvRangeSet
                ? apiRequestQQ?.initialIdvData.digit.max
                : vehicle.vehicleIDV.maximumIdv || 0,
              vehicle.vehicleIDV.idv || 0,
              contract.startDate,
              contract.endDate,
              discounts.totalLoadingAmount,
              "Go Digit General Insurance Ltd",
              apiRequestQQ.NewPolicyType,
              contract.coverages.addons.roadSideAssistance.selection === true
                ? contract.coverages.addons.roadSideAssistance.netPremium
                : false,
              contract.coverages.addons.engineProtection.selection === true
                ? contract.coverages.addons.engineProtection?.netPremium
                : false,
              contract.coverages.addons.tyreProtection.selection === true
                ? contract.coverages.addons.tyreProtection?.netPremium
                : false,
              contract.coverages.addons.rimProtection.selection === true
                ? contract.coverages.addons.rimProtection?.netPremium
                : false,
              contract.coverages.addons.consumables.selection === true
                ? contract.coverages.addons.consumables?.netPremium
                : false,
              contract.coverages.accessories.electrical.selection,
              contract.coverages.accessories.nonElectrical.selection,
              contract.coverages.addons.returnToInvoice.selection === true
                ? contract.coverages.addons.returnToInvoice?.netPremium
                : false,
              false,
              false,
              false,
              contract.coverages.unnamedPA.unnamedPaidDriver.selection === true
              ? contract.coverages.unnamedPA.unnamedPaidDriver.netPremium
              : 0,
              contract.coverages.addons.personalBelonging.selection === true
                ? contract.coverages.addons.personalBelonging?.netPremium
                : false,

              contract.coverages.addons.partsDepreciation.selection === true
                ? contract.coverages.addons.partsDepreciation?.netPremium
                : false,

              // false,

              contract.coverages.addons.keyAndLockProtect.selection === true
                ? contract.coverages.addons.keyAndLockProtect?.netPremium
                : false,

              contract.coverages.personalAccident.selection === true
                ? contract.coverages.personalAccident.netPremium
                : 0,

              contract.coverages.unnamedPA.unnamedPaidDriver.selection === true
                ? contract.coverages.unnamedPA.unnamedPaidDriver.netPremium
                : 0,

              contract.coverages.accessories.electrical.selection === true
                ? contract.coverages.accessories.electrical.insuredAmount
                : 0,

              contract.coverages.accessories.nonElectrical.selection === true
                ? contract.coverages.accessories.nonElectrical.insuredAmount
                : 0,

              0,

              ncbDiscount,

              contract.coverages.accessories.cng.selection === true
                ? contract.coverages.accessories.cng.insuredAmount
                : false,

              contract.coverages.legalLiability.paidDriverLL.selection === true
                ? parseFloat(
                    contract.coverages.legalLiability.paidDriverLL.netPremium.split(
                      " "
                    )[1]
                  )
                : false
            )
          )
        );
      } else {
        // const error = response.data.error.validationMessages[0];

        const error = response

        saveApiErrorReponse(
          "digit",
          '',
          "/assets/images/logos/partners/digit.png"
        );
        // sendErrorMessage(response)
      }
    })
    .catch((err) => console.log(err));
};

export const digitCreateQuoteApiCall = async (apiRequestQQ) => {
  try {
    let response = await PostDataWithToken(
      "motor/digit-create-quote",
      createQuotePayloadObj(apiRequestQQ)
    );
    return response;
  } catch (err) {
    console.log(err);
    alert("something went wrong");
  }
};

export const digitPaymentApi = (applicationId, ApiUniqueNumber) => {
  let postData = {
    applicationId: applicationId,
    cancelReturnUrl: process.env.REACT_APP_ERROR_URL,
    successReturnUrl: process.env.REACT_APP_SUCCESS_URL,
    ApiUniqueNumber: ApiUniqueNumber,
  };
  PostDataWithToken("motor/digit-payment", postData).then((response) => {
    console.log("payment linkkkkkk", response.data);
    if (response.status === true) {
      window.location.href = response.data;
    }
  });
};

export const downloadDigitPdf = (applicationId, ApiUniqueNumber) => {
  let postData = {
    policyId: applicationId,
    ApiUniqueNumber: ApiUniqueNumber,
  };
  PostDataWithToken("motor/digit-pdf-download", postData).then((res) => {
    if (res.status === true) {
      window.location.href = res.data.schedulePath;
    }
  });
};

//Bajaj

export const bajajApiCall = (apiRequestQQ) => {
  const min = 10000000000;
  const max = 99999999999;

  const randomNumber = Math.floor(Math.random() * (max - min + 1) + min);

  const uniqueNumber = "Bajaj" + randomNumber;

  dispatchQuickQuote("ApiUniqueNumber.Bajaj", uniqueNumber);

  const newData = {
    ...apiRequestQQ,
    ApiUniqueNumber: uniqueNumber,
    PolicyIssueDate: new Date().toLocaleDateString("en-GB").toString(),
  };

  console.log("New Bajaj Data", newData);
  PostDataWithToken("motor/bajaj", quickQuotePayloadObj(newData))
    .then((response) => {
      if (response.status === true && response.data.errorcode === 0) {
        if (response.data.errorcode === 0 || response.data !== "") {
          let { transactionid, premiumsummerylist, premiumdetails } =
            response.data;
          store.dispatch(removeApiErrorMessage("bajaj"));
          store.dispatch(
            quickQuoteResult(
              policyResultArr(
                "/assets/images/logos/partners/bajaj.png",
                "bajaj",
                "",
                transactionid,
                apiRequestQQ.RegistrationNumber,
                apiRequestQQ.MakeName,
                apiRequestQQ.ModelName,
                apiRequestQQ.VariantName,
                getDataOfBajajAct(premiumsummerylist, "OD", "od"),
                getDataOfBajajAct(premiumsummerylist, "ACT", "act"),
                getDataOfBajajAct(premiumsummerylist, "PA_DFT", "act"),
                premiumdetails.netpremium,
                premiumdetails.servicetax,
                premiumdetails.finalpremium,
                apiRequestQQ?.idvRangeSet
                  ? apiRequestQQ?.initialIdvData.bajaj.min
                  : parseInt(premiumdetails.totaliev) -
                      (parseInt(premiumdetails.totaliev) * 0) / 100,
                apiRequestQQ?.idvRangeSet
                  ? apiRequestQQ?.initialIdvData.bajaj.max
                  : parseInt(premiumdetails.totaliev) +
                      (parseInt(premiumdetails.totaliev) * 22) / 100,
                premiumdetails.totaliev,
                apiRequestQQ.PolicyStartDate,
                apiRequestQQ.PolicyEndDate,
                getDataOfBajajAct(premiumsummerylist, "COMMDISC", "od"),
                "Bajaj Allianz General Insurance Company Limited",
                apiRequestQQ.NewPolicyType,
                getDataOfBajajAct(premiumsummerylist, "S1", "od"),
                getDataOfBajajAct(premiumsummerylist, "S4", "od"),
                false,
                false,
                false,
                getDataOfBajajAct(premiumsummerylist, "ELECACC", "od"),
                getDataOfBajajAct(premiumsummerylist, "NELECACC", "od"),
                false,
                false,
                false,
                getDataOfBajajAct(premiumsummerylist, "VOLEX", "od"),
                getDataOfBajajAct(premiumsummerylist, "PA", "act"),
                getDataOfBajajAct(premiumsummerylist, "S14", "od"),
                getDataOfBajajAct(premiumsummerylist, "S3", "od"),
                getDataOfBajajAct(premiumsummerylist, "S13", "od"),
                0,
                0,
                0,
                0,
                0,
                premiumdetails.ncbamt !== "null" ? premiumdetails.ncbamt : 0,
                getDataOfBajajAct(premiumsummerylist, "CNG", "act"),
                getDataOfBajajAct(premiumsummerylist, "LLO", "act")
              )
            )
          );
        } else {
          // const error = response.message;
          const error = response
          saveApiErrorReponse(
            "bajaj",
            '',
            "/assets/images/logos/partners/bajaj.png"
          );
        }
      } else {
        const error = response
        saveApiErrorReponse(
          "bajaj",
          'hi',
          "/assets/images/logos/partners/bajaj.png"
        );
        // sendErrorMessage(response)
      }
    })
    .catch((err) => console.log(err));
};

export const bajajIssueApi = (apiRequestQQ) => {
  PostDataWithToken(
    "motor/bajajIssuePolicy",
    createQuotePayloadObj(apiRequestQQ)
  )
    .then((response) => {
      if (response.status === true) {
        if (response.data.errorcode === 0) {
          // window.location.href = `http://webservicesuat.bajajallianz.com/Insurance/WS/new_cc_payment.jsp?requestId=${apiRequestQQ.PaymentId}&Username=webservice@policyexpert.com&sourceName=WS_MOTOR`
          window.location.href = `https://general.bajajallianz.com/Insurance/WS/new_cc_payment.jsp?requestId=${apiRequestQQ.PaymentId}&Username=webservice@policyexpert.com&sourceName=WS_MOTOR`;
          // window.location.href = `http://webservicesint.bajajallianz.com/Insurance/WS/new_cc_payment.jsp?requestId=${apiRequestQQ.PaymentId}&Username=webservice@policyexpert.com&sourceName=WS_MOTOR`
        } else {
          alert(response.data.errorlist[0].errtext);
        }
      }
    })
    .catch((err) => console.log(err));
};

export const downloadBajajPdf = (applicationId, ApiUniqueNumber) => {
  let postData = {
    policynum: applicationId,
    ApiUniqueNumber: ApiUniqueNumber,
  };
  PostDataWithToken("motor/bajaj-pdf-download", postData).then((res) => {
    if (res.status === true) {
      console.log("response.data.fileByteObj", res.data.fileByteObj);
      downloadBase64File(res.data.fileByteObj, "PEIB_" + applicationId);
      // window.location.href = res.data.schedulePath;
    }
  });
};

//HDFC

export const HDFCCalculatePremium = async (apiRequestQQ) => {
  // debugger
  try {
    const min = 10000000000;
    const max = 99999999999;

    const randomNumber = Math.floor(Math.random() * (max - min + 1) + min);

    const uniqueNumber = "HDFC" + randomNumber;
    dispatchQuickQuote("ApiUniqueNumber.HDFC", uniqueNumber);

    const newData = {
      ...apiRequestQQ,
      ApiUniqueNumber: uniqueNumber,
      PolicyIssueDate: new Date().toLocaleDateString("en-GB").toString(),
    };

    console.log("New HDFC Data", newData);

    const response = await PostDataWithToken(
      "motor/HDFC",
      quickQuotePayloadObj(newData)
    );

    if (response.status === true) {
      const responseIDV = await PostDataWithToken(
        "motor/HDFCIDV",
        quickQuotePayloadObj(apiRequestQQ)
      );

      console.log("HDFC IDV", responseIDV);

      let policyData =
        apiRequestQQ.VehicleType == "4w"
          ? response.data.Resp_PvtCar
          : apiRequestQQ.VehicleType == "2w"
          ? response.data.Resp_TW
          : apiRequestQQ.VehicleType == "gcv"
          ? response.data.Resp_GCV
          : apiRequestQQ.VehicleType == "pcv" && response.data.Resp_PCV;

      const { TransactionID } = response.data;
      dispatchQuickQuote("PaymentAmount", policyData.Total_Premium);
      console.log("PaymentAmount", policyData.Total_Premium);

      removeApiErrorMessage("HDFC");

      store.dispatch(
        quickQuoteResult(
          policyResultArr(
            "/assets/images/logos/partners/hdfc.png",
            "HDFC",
            "",
            TransactionID,
            apiRequestQQ.RegistrationNumber,
            apiRequestQQ.MakeName,
            apiRequestQQ.ModelName,
            apiRequestQQ.VariantName,
            policyData.Basic_OD_Premium,
            policyData.Basic_TP_Premium,
            (policyData.PAOwnerDriver_Premium = !0
              ? policyData.PAOwnerDriver_Premium
              : false),
            policyData.Net_Premium,
            policyData.Service_Tax,
            policyData.Total_Premium,
            responseIDV?.data?.CalculatedIDV?.MIN_IDV_AMOUNT,
            responseIDV?.data?.CalculatedIDV?.MAX_IDV_AMOUNT,
            policyData.IDV,
            apiRequestQQ.PolicyStartDate,
            apiRequestQQ.PolicyEndDate,
            "",
            "HDFC ERGO GENERAL INSURANCE COMPANY LTD",
            apiRequestQQ.NewPolicyType,

            false,
            // Rsa

            policyData.Vehicle_Base_ENG_Premium != 0
              ? policyData.Vehicle_Base_ENG_Premium
              : false,
            // engine protector

            policyData.Vehicle_Base_TySec_Premium != 0
              ? policyData.Vehicle_Base_TySec_Premium
              : false,

            false,
            // rim

            policyData.Vehicle_Base_COC_Premium != 0
              ? policyData.Vehicle_Base_COC_Premium
              : false,
            policyData.Electical_Acc_Premium != 0
              ? policyData.Electical_Acc_Premium
              : false,
            policyData.NonElectical_Acc_Premium != 0
              ? policyData.NonElectical_Acc_Premium
              : false,
            policyData.Vehicle_Base_RTI_Premium != 0
              ? policyData.Vehicle_Base_RTI_Premium
              : false,
            false,
            policyData.Vehicle_Base_NCB_Premium != 0
              ? policyData.Vehicle_Base_NCB_Premium
              : false,
            // ncb

            policyData.VoluntartDisc_premium != 0
              ? policyData.VoluntartDisc_premium
              : false,
            policyData.UnnamedPerson_premium != 0
              ? policyData.UnnamedPerson_premium
              : false,
            policyData.Loss_of_Use_Premium != 0
              ? policyData.Loss_of_Use_Premium
              : false,
            // loss of pb

            policyData.Vehicle_Base_ZD_Premium != 0
              ? policyData.Vehicle_Base_ZD_Premium
              : false,
            false,
            // key relpacement

            policyData.PAOwnerDriver_Premium,
            policyData?.UnnamedPerson_premium,
            policyData?.Electical_Acc_Premium,
            policyData?.NonElectical_Acc_Premium,
            policyData?.VoluntartDisc_premium,
            policyData?.NCBBonusDisc_Premium,
            policyData?.BiFuel_Kit_TP_Premium +
              policyData?.BiFuel_Kit_OD_Premium,
            policyData?.PaidDriver_Premium != 0
              ? policyData?.PaidDriver_Premium
              : false
          )
        )
      );

      console.log("congratulations", response.data);

      return response;
    } else {
      const error = response
      saveApiErrorReponse(
        "HDFC",
        '',
        "/assets/images/logos/partners/hdfc.png"
      );
    }
    // throw new Error("Response status is not true");
  } catch (error) {
    console.error("Error in HDFCCalculatePremium:", error);
    throw error;
  }
};

const getDataOfBajajAct = (premiumsummerylist, paramref, key) => {
  console.log("get data of bajaj", paramref, key);
  const index = premiumsummerylist.findIndex(
    (item) => item.paramref === paramref
  );
  if (index > -1) {
    console.log("----------", premiumsummerylist[index][key]);
    return premiumsummerylist[index][key];
  } else {
    return false;
  }
};

//Shriram

export const shriramGenerateProposal = (apiRequestQQ, state) => {
  PostDataWithToken("motor/shriram", quickQuotePayloadObj(apiRequestQQ))
    .then((response) => {
      if (response.status === true) {
        const { GetQuotResult } = response.data;
        const { POL_SYS_ID, CoverDtlList, VehicleIDV, PROPOSAL_NO } =
          GetQuotResult;
        // dispatchQuickQuote("PaymentAmount", policyData.Total_Premium);
        if (GetQuotResult.ERROR_CODE == 0) {
          store.dispatch(
            quickQuoteResult(
              policyResultArr(
                "/assets/images/logos/partners/shriram.png",
                "Shriram",
                PROPOSAL_NO,
                POL_SYS_ID,
                apiRequestQQ.RegistrationNumber,
                apiRequestQQ.MakeName,
                apiRequestQQ.ModelName,
                apiRequestQQ.VariantName,
                getShriramData(
                  CoverDtlList,
                  apiRequestQQ.IsVehicleNew == true
                    ? "Basic Premium - 1 Year - OD"
                    : "Basic Premium - OD"
                ),
                getShriramData(
                  CoverDtlList,
                  apiRequestQQ.IsVehicleNew == true
                    ? "TP Total"
                    : "Basic Premium - TP"
                ),
                getShriramData(
                  CoverDtlList,
                  apiRequestQQ.IsVehicleNew == true
                    ? "GR36B3-PA-Paid Driver, Conductor,Cleaner - 3 Year - TP"
                    : "GR36B3-PA-Paid Driver, Conductor,Cleaner - TP"
                ),
                getShriramData(CoverDtlList, "Total Premium"),
                apiRequestQQ.IsVehicleNew === true
                  ? getShriramData(CoverDtlList, "IGST(18.00%)")
                  : parseFloat(getShriramData(CoverDtlList, "CGST(0.00%)")) +
                      parseFloat(
                        getShriramData(CoverDtlList, "SGST/UTGST(0.00%)")
                      ),
                getShriramData(CoverDtlList, "Total Amount"),
                parseInt(VehicleIDV) - (parseInt(VehicleIDV) * 30) / 100,
                parseInt(VehicleIDV) + (parseInt(VehicleIDV) * 30) / 100,
                VehicleIDV,
                apiRequestQQ.PolicyStartDate,
                apiRequestQQ.PolicyEndDate,
                getShriramData(
                  CoverDtlList,
                  apiRequestQQ.IsVehicleNew == true
                    ? "De-Tariff Discount - 1 Year - OD"
                    : "De-Tariff Discount - OD"
                ),
                "SHRIRAM GENERAL INSURANCE COMPANY LTD",
                apiRequestQQ.NewPolicyType,
                getShriramData(
                  CoverDtlList,
                  apiRequestQQ.IsVehicleNew == true
                    ? "Road Side Assistance - 1 Year - OD"
                    : "Road Side Assistance - OD"
                ),
                getShriramData(
                  CoverDtlList,
                  apiRequestQQ.IsVehicleNew == true
                    ? "Engine Protector - 1 Year - OD"
                    : "Engine Protector Loading - OD"
                ),
                false,
                false,
                getShriramData(
                  CoverDtlList,
                  apiRequestQQ.IsVehicleNew == true
                    ? "Consumable - 1 Year - OD"
                    : "Consumable - OD"
                ),
                getShriramData(
                  CoverDtlList,
                  apiRequestQQ.IsVehicleNew == true
                    ? "GR41-Cover For Electrical and Electronic Accessories - 1 Year - OD"
                    : "GR41-Cover For Electrical and Electronic Accessories - OD"
                ),
                getShriramData(
                  CoverDtlList,
                  apiRequestQQ.IsVehicleNew == true
                    ? "Cover For Non Electrical Accessories - 1 Year - OD"
                    : "Cover For Non Electrical Accessories - OD"
                ),
                false,
                false,
                false,
                false,
                getShriramData(
                  CoverDtlList,
                  apiRequestQQ.IsVehicleNew == true
                    ? "GR36B2-PA Cover For Passengers (Un-Named Persons) - 1 Year - TP"
                    : "GR36B2-PA Cover For Passengers (Un-Named Persons) - TP"
                ),
                getShriramData(
                  CoverDtlList,
                  apiRequestQQ.IsVehicleNew == true
                    ? "Personal Belonging - 1 Year - OD"
                    : "Personal Belonging - OD"
                ),
                getShriramData(
                  CoverDtlList,
                  apiRequestQQ.IsVehicleNew == true
                    ? "Nil Depreciation Loading - 1 Year - OD"
                    : "Nil Depreciation Loading - OD"
                ),
                getShriramData(
                  CoverDtlList,
                  apiRequestQQ.IsVehicleNew == true
                    ? "Key Replacement - 1 Year - OD"
                    : "Key Replacement - OD"
                ),
                getShriramData(
                  CoverDtlList,
                  apiRequestQQ.IsVehicleNew == true
                    ? "GR36B3-PA-Paid Driver, Conductor,Cleaner - 3 Year - TP"
                    : "GR36B3-PA-Paid Driver, Conductor,Cleaner - TP"
                ),
                getShriramData(
                  CoverDtlList,
                  apiRequestQQ.IsVehicleNew == true
                    ? "GR36B2-PA Cover For Passengers (Un-Named Persons) - 1 Year - TP"
                    : "GR36B2-PA Cover For Passengers (Un-Named Persons) - TP"
                ),
                getShriramData(
                  CoverDtlList,
                  apiRequestQQ.IsVehicleNew == true
                    ? "GR41-Cover For Electrical and Electronic Accessories - 1 Year - OD"
                    : "GR41-Cover For Electrical and Electronic Accessories - OD"
                ),
                getShriramData(
                  CoverDtlList,
                  apiRequestQQ.IsVehicleNew == true
                    ? "Cover For Non Electrical Accessories - 1 Year - OD"
                    : "Cover For Non Electrical Accessories - OD"
                ),
                false,
                getShriramData(CoverDtlList, "NCB Discount  - OD"),
                getShriramData(
                  CoverDtlList,
                  apiRequestQQ.IsVehicleNew == true
                    ? "GR42-Outbuilt CNG/LPG-Kit-Cover - 1 Year - TP"
                    : "GR42-Outbuilt CNG/LPG-Kit-Cover - OD"
                ),
                getShriramData(
                  CoverDtlList,
                  apiRequestQQ.IsVehicleNew == true
                    ? "Legal Liability Coverages For Paid Driver - TP"
                    : "Legal Liability Coverages For Paid Driver - TP"
                )
              )
            )
          );
        }
      }
    })
    .catch((err) => console.log(err));
};

export const ShriramGeneratPolicy = async (apiRequestQQ) => {
  try {
    let response = await PostDataWithToken(
      "motor/shriram-create-quote",
      createQuotePayloadObj(apiRequestQQ)
    );
    return response;
  } catch (err) {
    console.log(err);
    alert("something went wrong");
  }
};
const getShriramData = (CoverDtlList, CoverDesc) => {
  const index = CoverDtlList.findIndex((item) => item.CoverDesc === CoverDesc);
  if (index > -1) {
    return CoverDtlList[index].Premium;
  } else {
    return false;
  }
};

export function downloadBase64File(contentBase64, fileName) {
  const linkSource = `data:application/pdf;base64,${contentBase64}`;
  const downloadLink = document.createElement("a");
  document.body.appendChild(downloadLink);

  downloadLink.href = linkSource;
  downloadLink.target = "_self";
  downloadLink.download = fileName;
  downloadLink.click();
}

//Kotak------------------------------------------------

export const KotakAPICall = async (apiRequestQQ) => {
  const min = 10000000000;
  const max = 99999999999;

  const randomNumber = Math.floor(Math.random() * (max - min + 1) + min);

  const uniqueNumber = "Kotak" + randomNumber;

  dispatchQuickQuote("ApiUniqueNumber.Kotak", uniqueNumber);

  const newData = {
    ...apiRequestQQ,
    ApiUniqueNumber: uniqueNumber,
    PolicyIssueDate: new Date().toLocaleDateString("en-GB").toString(),
  };

  console.log("New Kotak Data", newData);
  const response = await PostDataWithToken(
    "motor/kotak",
    quickQuotePayloadObj(newData)
  );
  if (response.status === true) {
    if (response.data.vErrorMsg === "Success") {
      const data = response.data;
      dispatchQuickQuote("KGIStatus", true);

      removeApiErrorMessage("Kotak");

      // debugger;
      if (apiRequestQQ?.idvRangeSet) {
        console.log(
          "apiRequestQQ?.initialIdvData.kotak.min",
          apiRequestQQ?.initialIdvData.Kotak.min,
          apiRequestQQ?.idvRangeSet
        );
      }
      store.dispatch(
        quickQuoteResult(
          policyResultArr(
            "/assets/images/logos/partners/kotak.png",
            "Kotak",
            data.vWorkFlowID,
            data.vQuoteId,
            apiRequestQQ.RegistrationNumber,
            data.vMake,
            data.vModel,
            data.vVariant,
            data.vOwnDamagePremium,
            data.vBasicTPPremium,
            data.vPACoverForOwnDriver !== "-"
              ? data.vPACoverForOwnDriver
              : false,
            data.vNetPremium,
            data.vGSTAmount,
            data.vTotalPremium,
            apiRequestQQ?.idvRangeSet
              ? apiRequestQQ?.initialIdvData.Kotak.min
              : parseInt(data.vFinalIDV) -
                  (parseInt(data.vFinalIDV) * 11) / 100,
            apiRequestQQ?.idvRangeSet
              ? apiRequestQQ?.initialIdvData.Kotak.max
              : parseInt(data.vFinalIDV) +
                  (parseInt(data.vFinalIDV) * 20) / 100,
            data.vFinalIDV,
            moment(data.vPolicyStartDate, "DD/MM/YYYY").format("YYYY-MM-DD"),
            moment(data.vPolicyEndDate, "DD/MM/YYYY").format("YYYY-MM-DD"),
            "",
            "KOTAK MAHINDRA GENERAL INSURANCE COMPANY LTD.",
            apiRequestQQ.NewPolicyType,
            data.vRSA != "0" ? data.vRSA : false,
            data.vEngineProtect != "0" ? data.vEngineProtect : false,
            data.nTyreCoverPremium != "0" ? data.nTyreCoverPremium : false,
            false,
            data.vConsumableCover != "0" ? data.vConsumableCover : false,
            data.vElectronicSI != "0" ? data.vElectronicSI : false,
            data.vNonElectronicSI != "0" ? data.vNonElectronicSI : false,
            data.vReturnToInvoice != "0" ? data.vReturnToInvoice : false,
            false,
            data.nNCBProtectPremium != "0" ? data.nNCBProtectPremium : false,
            data.vVoluntaryDeduction != "0" ? data.vVoluntaryDeduction : false,
            data.vPAForUnnamedPassengerPremium != "0"
              ? data.vPAForUnnamedPassengerPremium
              : false,
            data.nLossPersonalBelongingsPremium != "0"
              ? data.nLossPersonalBelongingsPremium
              : false,
            data.vDepreciationCover != "0" ? data.vDepreciationCover : false,
            data.nKeyReplacementPremium != 0
              ? data.nKeyReplacementPremium
              : false,
            data.vPACoverForOwnDriver !== "-"
              ? data.vPACoverForOwnDriver
              : false,
            data.vPAForUnnamedPassengerPremium != "0"
              ? data.vPAForUnnamedPassengerPremium
              : false,
            data.vElectronicSI != "0" ? data.vElectronicSI : 0,
            data.vNonElectronicSI != "0" ? data.vNonElectronicSI : 0,
            // data.vReturnToInvoice != "0" ? data.vReturnToInvoice : 0,
            data.vVoluntaryDeduction != "0" ? data.vVoluntaryDeduction : 0,
            data.vNCB,
            parseFloat(data.vCngLpgKitPremium) +
              parseFloat(data.vCngLpgKitPremiumTP),
            data.vLegalLiabilityPaidDriverNo
          )
        )
      );
    } else {
      // debugger
      dispatchQuickQuote("KGIStatus", false);

      // const error = response.data.vErrorMsg;
      const error = response
      saveApiErrorReponse(
        "Kotak",
        '',
        "/assets/images/logos/partners/kotak.png"
      );
    }
  } else {
    saveApiErrorReponse(
      "Kotak",
      '',
      "/assets/images/logos/partners/kotak.png"
    );
    // sendErrorMessage(response)
  }
  return new Promise((resolve) =>
    setTimeout(() => {
      resolve(response.data);
    }, 1800)
  );
};

export const kotakIssueApi = async (apiRequestQQ) => {
  try {
    const response = await PostDataWithToken(
      "motor/saveKotakProposal",
      createQuotePayloadObj(apiRequestQQ)
    );
    return response;
  } catch (err) {
    console.log(err);
  }
};

// Reliance ki API
export const RelianceAPICall = (apiRequestQQ) => {
  const min = 10000000000;
  const max = 99999999999;

  const randomNumber = Math.floor(Math.random() * (max - min + 1) + min);

  const uniqueNumber = "Reliance" + randomNumber;

  dispatchQuickQuote("ApiUniqueNumber.Reliance", uniqueNumber);

  const newData = {
    ...apiRequestQQ,
    ApiUniqueNumber: uniqueNumber,
    PolicyIssueDate: new Date().toLocaleDateString("en-GB").toString(),
  };

  console.log("New Reliance Data", newData);
  PostDataWithToken("motor/reliance", quickQuotePayloadObj(newData))
    .then((response) => {
      if (response.status === true) {
        if (response.data.MotorPolicy.status == "1") {
          const data = response.data.MotorPolicy;
          store.dispatch(
            quickQuoteResult(
              policyResultArr(
                "/assets/images/logos/partners/relience.png",
                "Reliance",
                data.QuoteNo,
                data.EndorsementNo,
                apiRequestQQ.RegistrationNumber,
                apiRequestQQ.MakeName,
                apiRequestQQ.ModelName,
                apiRequestQQ.VariantName,
                data.TotalOD,
                data.TotalLiabilityPremium,
                getRelianceValues(
                  data.lstPricingResponse,
                  "PA to Owner Driver"
                ),
                data.NetPremium,
                getRelianceTotalTax(data.LstTaxComponentDetails.TaxComponent),
                data.FinalPremium,
                apiRequestQQ?.idvRangeSet
                  ? apiRequestQQ?.initialIdvData.reliance.min
                  : parseInt(data.IDV) - (parseInt(data.IDV) * 10) / 100,
                apiRequestQQ?.idvRangeSet
                  ? apiRequestQQ?.initialIdvData.reliance.max
                  : parseInt(data.IDV) + (parseInt(data.IDV) * 20) / 100,
                data.IDV,
                apiRequestQQ.PolicyStartDate,
                apiRequestQQ.PolicyEndDate,
                false,
                "Reliance General Insurance Co Ltd",
                apiRequestQQ.NewPolicyType,
                false,
                false,
                false,
                false,
                false,
                getRelianceValues(
                  data.lstPricingResponse,
                  "Electrical Accessories"
                ),
                getRelianceValues(
                  data.lstPricingResponse,
                  "Non Electrical Accessories"
                ),
                false,
                false,
                false,
                getRelianceValues(
                  data.lstPricingResponse,
                  "Voluntary Deductible"
                ),
                getRelianceValues(
                  data.lstPricingResponse,
                  "PA to Unnamed Passenger"
                ),
                false,
                getRelianceValues(data.lstPricingResponse, "Nil Depreciation"),
                false,
                getRelianceValues(
                  data.lstPricingResponse,
                  "PA to Owner Driver"
                ),
                getRelianceValues(
                  data.lstPricingResponse,
                  "PA to Unnamed Passenger"
                ),
                getRelianceValues(
                  data.lstPricingResponse,
                  "Electrical Accessories"
                ),
                getRelianceValues(
                  data.lstPricingResponse,
                  "Non Electrical Accessories"
                ),
                getRelianceValues(
                  data.lstPricingResponse,
                  "Voluntary Deductible"
                ),
                getRelianceValues(data.lstPricingResponse, "NCB"),
                apiRequestQQ.NewPolicyType == "ThirdParty"
                  ? parseFloat(
                      getRelianceValues(
                        data.lstPricingResponse,
                        "Bifuel Kit TP"
                      )
                    )
                  : apiRequestQQ.NewPolicyType == "ODOnly"
                  ? parseFloat(
                      getRelianceValues(data.lstPricingResponse, "Bifuel Kit")
                    )
                  : parseFloat(
                      getRelianceValues(
                        data.lstPricingResponse,
                        "Bifuel Kit TP"
                      )
                    ) +
                    parseFloat(
                      getRelianceValues(data.lstPricingResponse, "Bifuel Kit")
                    ),
                getRelianceValues(
                  data.lstPricingResponse,
                  "Liability to Paid Driver"
                )
              )
            )
          );
        }
      } else {
        // sendErrorMessage(response)
      }
    })
    .catch((err) => console.log(err));
};

export const relianceGenerateProposal = (apiRequestQQ) => {
  PostDataWithToken(
    "motor/relianceGenerateProposal",
    createQuotePayloadObj(apiRequestQQ)
  )
    .then((response) => {
      if (response.status === true) {
        if (response.data.MotorPolicy.status == "1") {
          const data = response.data.MotorPolicy;
          let paymentUrl = `https://rgipartners.reliancegeneral.co.in/PaymentIntegration/PaymentIntegration?ProposalNo=${data.ProposalNo}&userID=100002&ProposalAmount=${data.FinalPremium}&PaymentType=1&Responseurl=${process.env.REACT_APP_SUCCESS_URL}&CKYC=${apiRequestQQ.KYC.Reliance}&IsDocumentUpload=False&PanNo=${apiRequestQQ.PanNumber}&IsForm60=false`;

          // console.log(paymentUrl);
          window.location.href = paymentUrl;
          // alert(paymentUrl);
        }
      } else {
        // sendErrorMessage(response)
      }
    })
    .catch((err) => console.log(err));
};

const getRelianceValues = (arr, CoverageName) => {
  if (Array.isArray(arr)) {
    let index = arr.findIndex((item) => item.CoverageName === CoverageName);
    if (index > -1) {
      if (CoverageName === "OD Discount") {
        let index2 = arr.findIndex(
          (item) => item.CoverageName === "Automobile Association Membership"
        );
        if (index > -1) {
          return arr[index].Premium;
        } else {
          return arr[index].Premium;
        }
      } else {
        console.log("Bifuel Kit", CoverageName, arr[index].Premium);
        return arr[index].Premium;
      }
    } else {
      return false;
    }
  } else {
    return false;
  }
};

const getRelianceTotalTax = (TaxComponent) => {
  let totalTax = 0;
  let i = 0;
  while (i < TaxComponent.length) {
    totalTax = totalTax + parseFloat(TaxComponent[i].Amount);
    i++;
  }
  return totalTax;
};

// Future General api---------------------------------------

export const FutureGeneralQuickQuote = (apiRequestQQ) => {
  // debugger
  const min = 10000000000;
  const max = 99999999999;

  const randomNumber = Math.floor(Math.random() * (max - min + 1) + min);

  const uniqueNumber = "Future" + randomNumber;

  dispatchQuickQuote("ApiUniqueNumber.Future", uniqueNumber);

  const newData = {
    ...apiRequestQQ,
    ApiUniqueNumber: uniqueNumber,
    PolicyIssueDate: new Date().toLocaleDateString("en-GB").toString(),
  };

  console.log("New Future Data", newData);
  PostDataWithToken("motor/futuregeneral", quickQuotePayloadObj(newData))
    .then((response) => {
      // debugger
      if(response.status === true){
      if (
        response.status === true &&
        (response.data.Root.Policy.Status !== "Fail" || response.data.Root.Status !== "Fail")
      ) {
        if (response.data.Root.Policy.Status === "Successful") {
          store.dispatch(removeApiErrorMessage("Future"));

          const data = response.data.Root.Policy;
          const table = response.data.Root.Policy.NewDataSet.Table1;
          store.dispatch(
            quickQuoteResult(
              policyResultArr(
                "/assets/images/logos/partners/fg.png",
                "Future",
                data.NewDataSet.Table.PolNo,
                data.ProductUINNo,
                apiRequestQQ.RegistrationNumber,
                apiRequestQQ.MakeName,
                apiRequestQQ.ModelName,
                apiRequestQQ.VariantName,
                getFutureGenValue(table, "IDV", "OD"),
                getFutureGenValue(table, "Gross Premium", "TP"),
                getFutureGenValue(table, "CPA", "TP") > 0
                  ? getFutureGenValue(table, "CPA", "TP")
                  : false,

                Math.round(
                  getFutureGenValue(table, "PrmDue", "OD") +
                    getFutureGenValue(table, "PrmDue", "TP")
                  - getFutureGenValue(table, "ServTax", "OD") -
                  getFutureGenValue(table, "ServTax", "TP")
                ),

                getFutureGenValue(table, "ServTax", "OD") +
                  getFutureGenValue(table, "ServTax", "TP"),

                Math.round(
                  getFutureGenValue(table, "PrmDue", "OD") +
                    getFutureGenValue(table, "PrmDue", "TP") 
                ),

                apiRequestQQ?.idvRangeSet
                  ? apiRequestQQ?.initialIdvData.Future.min
                  : parseInt(data.VehicleIDV) -
                      (parseInt(data.VehicleIDV) * 8) / 100,
                apiRequestQQ?.idvRangeSet
                  ? apiRequestQQ?.initialIdvData.Future.max
                  : parseInt(data.VehicleIDV) +
                      (parseInt(data.VehicleIDV) * 30) / 100,
                data.VehicleIDV,
                apiRequestQQ.PolicyStartDate,
                apiRequestQQ.PolicyEndDate,
                getFutureGenValue(table, "DISCPERC", "OD"),
                "FUTURE GENERALI INDIA INSURANCE COMPANY LTD.",
                apiRequestQQ.NewPolicyType,
                getFutureGenValue(table, "RODSA", "OD") > 0
                  ? getFutureGenValue(table, "RODSA", "OD")
                  : false,
                getFutureGenValue(table, "ENGPR", "OD") > 0
                  ? getFutureGenValue(table, "ENGPR", "OD")
                  : false,
                getFutureGenValue(table, 1, "OD") > 0
                  ? getFutureGenValue(table, 1, "OD")
                  : false,
                false,
                getFutureGenValue(table, "CONSM", "OD") > 0
                  ? getFutureGenValue(table, "CONSM", "OD")
                  : false,
                getFutureGenValue(table, "EAV", "OD") > 0
                  ? getFutureGenValue(table, "EAV", "OD")
                  : false,
                getFutureGenValue(table, "NEA", "OD") > 0
                  ? getFutureGenValue(table, "NEA", "OD")
                  : false,
                getFutureGenValue(table, 6, "OD") > 0
                  ? getFutureGenValue(table, 6, "OD")
                  : false,
                false,
                getFutureGenValue(table, 4, "OD") > 0
                  ? getFutureGenValue(table, 4, "OD")
                  : false,
                getFutureGenValue(table, "VD", "OD") > 0
                  ? getFutureGenValue(table, "VD", "OD")
                  : false,
                getFutureGenValue(table, "APA", "TP") > 0
                  ? getFutureGenValue(table, "APA", "TP")
                  : false,
                false,
                getFutureGenValue(table, "ZODEP", "OD") > 0
                  ? getFutureGenValue(table, "ZODEP", "OD")
                  : false,
                false,
                getFutureGenValue(table, "CPA", "TP") > 0
                  ? getFutureGenValue(table, "CPA", "TP")
                  : false,
                getFutureGenValue(table, "APA", "TP") > 0
                  ? getFutureGenValue(table, "APA", "TP")
                  : false,
                getFutureGenValue(table, "EAV", "OD") > 0
                  ? getFutureGenValue(table, "EAV", "OD")
                  : false,
                getFutureGenValue(table, "NEA", "OD") > 0
                  ? getFutureGenValue(table, "NEA", "OD")
                  : false,
                getFutureGenValue(table, "VD", "OD") > 0
                  ? getFutureGenValue(table, "VD", "OD")
                  : false,
                getFutureGenValue(table, "NCB", "OD")
                  ? getFutureGenValue(table, "NCB", "OD")
                  : false,
                getFutureGenValue(table, "CNG", "TP") > 0
                  ? getFutureGenValue(table, "CNG", "TP")
                  : false,
                getFutureGenValue(table, "LLDE", "TP") > 0
                  ? getFutureGenValue(table, "LLDE", "TP")
                  : false
              )
            )
          );
        }
      } else if(response.data.Root.Status === "Fail") {
        // debugger
        // const error = response.data.Root.Policy.ErrorMessage;

        // const error = response

        saveApiErrorReponse(
          "Future",
          '',
          "/assets/images/logos/partners/fg.png"
        );

        // sendErrorMessage(response)
      }
    }
  else{
    saveApiErrorReponse(
      "Future",
      '',
      "/assets/images/logos/partners/fg.png"
    );

  }})
    .catch((err) => console.log(err));
};

export const FutureGeneralInspection = (apiRequestQQ) => {
  try {
    const response = PostDataWithToken(
      "motor/future-inspection",
      createQuotePayloadObj(apiRequestQQ)
    );
    return response;
  } catch (err) {
    throw err;
  }
};

const getFutureGenValue = (arr, Code, Type) => {
  let index = arr.findIndex((val) => val.Code === Code && val.Type === Type);
  if (index > -1) {
    return arr[index].BOValue;
  } else {
    return 0;
  }
};

// Royal Sundram api call
export const RoyalSundramAPICall = (apiRequestQQ) => {
  const min = 10000000000;
  const max = 99999999999;

  const randomNumber = Math.floor(Math.random() * (max - min + 1) + min);

  const uniqueNumber = "Royal" + randomNumber;

  dispatchQuickQuote("ApiUniqueNumber.Royal", uniqueNumber);

  const newData = {
    ...apiRequestQQ,
    ApiUniqueNumber: uniqueNumber,
    PolicyIssueDate: new Date().toLocaleDateString("en-GB").toString(),
  };

  console.log("New Royal Data", newData);

  PostDataWithToken("motor/royalsundram", quickQuotePayloadObj(newData))
    .then((response) => {
      if (response.status === true) {
        console.log("response .data. royal . sundram", response.data);
        const { PREMIUMDETAILS } = response.data;
        const { Status, DATA } = PREMIUMDETAILS;
        if (Status.StatusCode === "S-0002") {
          // tyre
          const data = response.data;
          store.dispatch(
            quickQuoteResult(
              policyResultArr(
                "/assets/images/logos/partners/royal.jpg",
                "Royal",
                DATA.QUOTE_ID,
                DATA.QUOTE_ID,
                apiRequestQQ.RegistrationNumber,
                apiRequestQQ.MakeName,
                apiRequestQQ.ModelName,
                apiRequestQQ.VariantName,
                DATA.OD_PREMIUM.TOTAL_OD_PREMIUM,
                DATA.LIABILITY.BASIC_PREMIUM_INCLUDING_PREMIUM_FOR_TPPD,
                DATA.LIABILITY.UNDER_SECTION_III_OWNER_DRIVER !== "0.0"
                  ? DATA.LIABILITY.UNDER_SECTION_III_OWNER_DRIVER
                  : false,
                DATA.PACKAGE_PREMIUM,
                parseFloat(DATA.IGST) +
                  parseFloat(DATA.CGST) +
                  parseFloat(DATA.SGST),
                DATA.GROSS_PREMIUM,
                DATA.MINIMUM_IDV || 0,
                DATA.MAXIMUM_IDV || 0,
                DATA.MODEL_IDV ? DATA.MODEL_IDV : DATA?.IDV,
                apiRequestQQ.PolicyStartDate,
                apiRequestQQ.PolicyEndDate,
                DATA.CAMPAIGN_DISCOUNT,
                "Royal Sundaram General Insurance Co Ltd",
                apiRequestQQ.NewPolicyType,
                false,
                DATA.OD_PREMIUM.ENGINE_PROTECTOR != "0.0"
                  ? DATA.OD_PREMIUM.ENGINE_PROTECTOR
                  : false,
                DATA.OD_PREMIUM.TYRE_COVER != "0.0"
                  ? DATA.OD_PREMIUM.TYRE_COVER
                  : false,
                false,
                false,
                DATA.OD_PREMIUM.ELECTRICAL_ACCESSORIES != "0.0"
                  ? DATA.OD_PREMIUM.ELECTRICAL_ACCESSORIES
                  : false,
                DATA.OD_PREMIUM.NON_ELECTRICAL_ACCESSORIES != "0.0"
                  ? DATA.OD_PREMIUM.NON_ELECTRICAL_ACCESSORIES
                  : false,
                false,
                false,
                DATA.OD_PREMIUM.NCB_PROTECTOR != "0.0"
                  ? DATA.OD_PREMIUM.NCB_PROTECTOR
                  : false,
                DATA.OD_PREMIUM.VOLUNTARY_DEDUCTABLE != "0.0"
                  ? DATA.OD_PREMIUM.VOLUNTARY_DEDUCTABLE
                  : false,
                DATA.LIABILITY.UNNAMED_PASSENGRS != "0.0"
                  ? DATA.LIABILITY.UNNAMED_PASSENGRS
                  : false,
                DATA.OD_PREMIUM.LOSS_OF_BAGGAGE != "0.0"
                  ? DATA.OD_PREMIUM.LOSS_OF_BAGGAGE
                  : false,
                DATA.OD_PREMIUM.DEPRECIATION_WAIVER != "0.0"
                  ? DATA.OD_PREMIUM.DEPRECIATION_WAIVER
                  : false,
                DATA.OD_PREMIUM.KEY_REPLACEMENT != "0.0"
                  ? DATA.OD_PREMIUM.KEY_REPLACEMENT
                  : false,
                DATA.LIABILITY.UNDER_SECTION_III_OWNER_DRIVER !== "0.0"
                  ? DATA.LIABILITY.UNDER_SECTION_III_OWNER_DRIVER
                  : false,
                DATA.LIABILITY.UNNAMED_PASSENGRS != "0.0"
                  ? DATA.LIABILITY.UNNAMED_PASSENGRS
                  : false,
                DATA.OD_PREMIUM.ELECTRICAL_ACCESSORIES != "0.0"
                  ? DATA.OD_PREMIUM.ELECTRICAL_ACCESSORIES
                  : false,
                DATA.OD_PREMIUM.NON_ELECTRICAL_ACCESSORIES != "0.0"
                  ? DATA.OD_PREMIUM.NON_ELECTRICAL_ACCESSORIES
                  : false,
                DATA.OD_PREMIUM.VOLUNTARY_DEDUCTABLE != "0.0"
                  ? DATA.OD_PREMIUM.VOLUNTARY_DEDUCTABLE
                  : 0,
                DATA.NO_CLAIM_BONUS,
                DATA.LIABILITY.BI_FUEL_KIT_CNG,
                DATA.LIABILITY.TO_PAID_DRIVERS
              )
            )
          );
        }
      } else {
        // sendErrorMessage(response)
      }
    })
    .catch((err) => console.log(err));
};

export const RoyalSundramGenerateProposal = async (apiRequestQQ) => {
  try {
    const response = await PostDataWithToken(
      "motor/royalSundramProposal",
      createQuotePayloadObj(apiRequestQQ)
    );
    return response;
  } catch (err) {
    console.log("err---", err);
  }
};

// Zuno Requst ------------------

export const getZunoQuickQuote = async (apiRequestQQ) => {
  // debugger

  const min = 10000000000;
  const max = 99999999999;

  const randomNumber = Math.floor(Math.random() * (max - min + 1) + min);

  const uniqueNumber = "Zuno" + randomNumber;

  dispatchQuickQuote("ApiUniqueNumber.Zuno", uniqueNumber);

  const newData = {
    ...apiRequestQQ,
    ApiUniqueNumber: uniqueNumber,
    PolicyIssueDate: new Date().toLocaleDateString("en-GB").toString(),
  };

  console.log("New Zuno Data", newData);
  // debugger
  try {
    const response = await PostDataWithToken(
      "motor/zuno",
      quickQuotePayloadObj(newData)
    );
    if (response.status === true) {

      const contractDetails = response.data.contractDetails;
      console.log("contractDetails", contractDetails);

      const addOns = response.data.premiumDetails.individualAddOnPremium

    //   const checkAddOns =(val)=>{

    //     const Response =  Array.isArray(contractDetails) ? (contractDetails.flatMap((contract) => {
    //     if (Array.isArray(contract.coverage.subCoverage)) {
    //         return contract.coverage.subCoverage.reduce((acc, item) => {
    //             if (item.salesProductTemplateId === val) {
    //                 acc.push(item.grossPremium);
    //             }
    //             return acc;
    //         }, []);
    //     } 
    // }))   : 

    // console.log('Response', Response);

    // const filteredResponse = Response.filter(value => value !== undefined).join(' ');
      
    // console.log('filteredResponse', filteredResponse);

    // return filteredResponse

    // }

    const checkAddOns = (val) => {
      let Response = [];
  
      if (Array.isArray(contractDetails)) {
          // When contractDetails is an array
          Response = contractDetails.flatMap((contract) => {
              if (Array.isArray(contract.coverage.subCoverage)) {
                  return contract.coverage.subCoverage.reduce((acc, item) => {
                      if (item.salesProductTemplateId === val) {
                          acc.push(item.grossPremium);
                      }
                      return acc;
                  }, []);
              }
              return []; // In case subCoverage is not an array, return an empty array
          });
      } else if (typeof contractDetails === 'object' && contractDetails !== null) {
          // When contractDetails is an object
          if (Array.isArray(contractDetails.coverage.subCoverage)) {
              Response = contractDetails.coverage.subCoverage.reduce((acc, item) => {
                  if (item.salesProductTemplateId === val) {
                      acc.push(item.grossPremium);
                  }
                  return acc;
              }, []);
          }
      } else {
          console.log('Invalid contractDetails type:', typeof contractDetails);
      }
  
      console.log('Response', Response);
  
      const filteredResponse = Response.filter(value => value !== undefined).join(' ');
  
      console.log('filteredResponse', filteredResponse);
  
      return filteredResponse;
  }
  

       if(Array.isArray(response?.data?.contractDetails[0]?.coverage?.subCoverage)){

      dispatchQuickQuote('allowableDiscountZuno',response.data.contractDetails[0].coverage.subCoverage[0].allowableDiscount)

      
      }else if (Array.isArray(response.data.contractDetails)) {
        
      dispatchQuickQuote('allowableDiscountZuno',response.data.contractDetails[0].coverage.subCoverage.allowableDiscount) 
      }
      else {

      dispatchQuickQuote('allowableDiscountZuno',response.data.contractDetails.coverage.subCoverage.allowableDiscount)
      
      }

      store.dispatch(
        quickQuoteResult(
          policyResultArr(
            "/assets/images/logos/partners/zuno.png",
            "Zuno",
            "",
            "",
            apiRequestQQ.RegistrationNumber,
            apiRequestQQ.MakeName,
            apiRequestQQ.ModelName,
            apiRequestQQ.VariantName,
            response?.data?.premiumDetails?.totalODPremium,
            response?.data?.premiumDetails?.totalTPPremium,
            response?.data?.premiumDetails?.totalPApremium,
            response?.data?.premiumDetails?.netTotalPremium,
            response?.data?.premiumDetails?.gst,
            response?.data?.premiumDetails?.grossTotalPremium,
            // contractDetails[0].contractIo.idvValue || 0,
            parseInt(
              Array.isArray(response?.data?.contractDetails) ? 
              (response?.data?.contractDetails[0]?.insuredObject?.systemIdv)
              : (response?.data?.contractDetails.insuredObject.systemIdv)
            ) * 0.85,
            // contractDetails[0].contractIo.idvValue || 0,
            parseInt(
              Array.isArray(response?.data?.contractDetails) ? 
              (response?.data?.contractDetails[0]?.insuredObject?.systemIdv)
              : (response?.data?.contractDetails.insuredObject.systemIdv)
            ) * 1.15,

              Array.isArray(response?.data?.contractDetails) ? 
              (response?.data?.contractDetails[0]?.insuredObject?.systemIdv)
              : (response?.data?.contractDetails.insuredObject.systemIdv),

            response?.data?.contractDetails[0]?.contractPremium
              ?.premiumStartDate,
            response?.data?.contractDetails[0]?.contractPremium?.premiumEndDate,
            0,
            "ZUNO General Insurance Co Ltd",
            apiRequestQQ.NewPolicyType,

            addOns.basicRoadAssistance ? addOns.basicRoadAssistance  : "" ,
            // rsa

            addOns.engineProtect ? addOns.engineProtect : "",
            // engine Protection

            addOns.tyreProtect ? (addOns.tyreProtect == 0.00 ? false : addOns.tyreProtect )  : false,
            // tyre Protection

            false,
            // rim Protection

            addOns.consumableCover ? addOns.consumableCover  : "" ,
            // consumbales

            checkAddOns("MOSCMF02") ? checkAddOns("MOSCMF02") : '',
            // IsElectricalAccessories

            checkAddOns("MOSCMF01") ? checkAddOns("MOSCMF01") : '',
            // IsNonElectricalAccessories

            addOns.invoiceValueProtect ? (addOns.invoiceValueProtect == 0.00 ? false : addOns.invoiceValueProtect ) : false,
            // invoice
            
            "",
            // EngineGearBox

            addOns.protectionOfNCB ? addOns.protectionOfNCB : 0,
            // NCBProtection

            false,
            // VoluntaryDeductive

            checkAddOns("MOSCMF24") ? checkAddOns("MOSCMF24") : '',
            // PassengerCover
            
            addOns.lossOfPersonalBelongings  ? addOns.lossOfPersonalBelongings
            : "",
            // LossOfPersonalBelongings

            addOns.zeroDepreciation ? addOns.zeroDepreciation : "" ,
            // zd

            addOns.keyReplacement ? addOns.keyReplacement  : "",
            // KeyReplacement

            "",
            // PaCoverAmount

            "",
            // PassengerCoverAmount

            "",
            // ElectricAmount

            "",
            // nonElectricalAmount

            "",
            // VoluntaryAmount

            "",
            // NCBDiscount

            
            checkAddOns("MOSCMF03") ? checkAddOns("MOSCMF03") : '',

            // CNGValue

            checkAddOns("MOSCMF20") ? checkAddOns("MOSCMF20") : '',
            // DriverLiability

            "",
            // CNGDiscount

            // getZunoCoverValues(contractDetails, "MOSCMF13"),
            // getZunoCoverValues(contractDetails, "MOSCMF08"),
            // getZunoCoverValues(contractDetails, "MOLMMF28"),
            // false,
            // getZunoCoverValues(contractDetails, "MOSCMF15"),
            // getZunoCoverValues(contractDetails, "MOSCMF02") === false
            //   ? false
            //   : true,
            // getZunoCoverValues(contractDetails, "MOSCMF01") === false
            //   ? false
            //   : true,
            // getZunoCoverValues(contractDetails, "MOSCMF09"),
            // false,
            // getZunoCoverValues(contractDetails, "MOSCMF12"),
            // getZunoCoverValues(contractDetails, "MODEMF04"),
            // getZunoCoverValues(contractDetails, "MOSCMF24"),
            // getZunoCoverValues(contractDetails, "MODEMF06"),
            // getZunoCoverValues(contractDetails, "MOSCMF07"),
            // getZunoCoverValues(contractDetails, "MOSCMF10"),
            // getZunoCoverValues(contractDetails, "MOCVMF03"),
            // getZunoCoverValues(contractDetails, "MOSCMF24"),
            // getZunoCoverValues(contractDetails, "MOSCMF02"),
            // getZunoCoverValues(contractDetails, "MOSCMF01"),
            // 0,
            // parseFloat(contractDetails[0].contractPremium.premiumDiscount) +
            //   parseFloat(contractDetails[1].contractPremium.premiumDiscount) ||
            //   0,
            // getZunoCoverValues(contractDetails, "MOSCMF03"),
            // getZunoCoverValues(contractDetails, "MOSCMF20")
          )
        )
      );
    }
    else{
      saveApiErrorReponse(
        "Zuno",
        '',
        "/assets/images/logos/partners/zuno.png"
      );
    }
  } catch (err) {
    console.log("err--- zuno", err);
  }
};

export const ZunoFullQuote = async (apiRequestQQ) => {
  // debugger
  console.log('dsfsdf',apiRequestQQ)
  const newData ={
    ...apiRequestQQ,
     allowableDiscountZuno : apiRequestQQ.allowableDiscountZuno,
     ApiUniqueNumber : apiRequestQQ.ApiUniqueNumber

  }
  console.log('newDatadsgffdgfdhfj',newData)
  try {
    const response = await PostDataWithToken(
      "motor/zunoProposal",
      createQuotePayloadObj(newData)
    );
    return response;
  } catch (err) {
    console.log("err---", err);
  }
};

const getZunoCoverValues = (coverage, key) => {
  let i = 0;
  let result = false;
  console.log("coverage.length", coverage.length);
  console.log("coverage", coverage);

  while (i < coverage.length) {
    let item = coverage[i];
    let j = 0;
    if (key === "MOSDMFB7") {
      let subCVRG = item.coverage.coverageSurchargesOrDiscounts;
      if (Array.isArray(subCVRG)) {
        subCVRG = item.coverage.coverageSurchargesOrDiscounts;
      } else {
        subCVRG = [item.coverage.coverageSurchargesOrDiscounts];
      }
      while (j < subCVRG.length) {
        let cover = subCVRG;
        let index = cover.findIndex((ele) => ele.salesProductTemplateId == key);
        if (index > -1) {
          result = cover[index].amount;
        }
        j++;
      }
    } else {
      console.log("item.coverage", item.coveragePackage.coverage.subCoverage);
      let subCVRG = item.coveragePackage.coverage.subCoverage;
      if (Array.isArray(subCVRG)) {
        subCVRG = item.coveragePackage.coverage.subCoverage;
      } else {
        subCVRG = [item.coveragePackage.coverage.subCoverage];
      }
      while (j < subCVRG.length) {
        let cover = subCVRG;
        let index = cover.findIndex((ele) => ele.salesProductTemplateId == key);
        if (index > -1) {
          result = cover[index].totalPremium;
        }
        j++;
      }
    }

    i++;
  }
  return result;
};

// export const zunoBillDesk = async (data) => {

//   const newData = {
//     ApiUniqueNumber: data.val,
//     zunoPremium: data.premium
//   };

//   try {
//     const startTime = Date.now();
//     const response = await PostDataWithToken("motor/zunoBilldesk", newData);
//     const endTime = Date.now();

//     console.log(`API response time: ${endTime - startTime} ms`);

//     // Redirecting to the URL after the response is received
//     window.location.href = 'https://uat.billdesk.com/pgidsk/PGIMerchantPayment';

//     return response;
//   } catch (err) {
//     console.log("err---", err);
//   }
// };

  
// export const zunoBillDesk = async (data) => {
// debugger
//   try {
    
//     // console.log('Received request body:', req.body);
//     console.log('Working')

//     let CustomerID = generateUniqueId();
//     console.log('Working1')

//     let AdditionalInfo1 = generateRandom7DigitNumber();
//     console.log('Working2')

//     const txnAmountStr = data.toString();
//     console.log('Working3')


//     let TxnAmount = txnAmountStr.includes('.')
//     ? txnAmountStr.split('.')[0] + '.' + txnAmountStr.split('.')[1].slice(0, 2)
//     : txnAmountStr;
//     console.log('Working4')


//     // Creating the formatted request string
//     const formattedRequest = [
//       "EDGENBKAGR", // merchant id
//       CustomerID,
//       "NA",
//       TxnAmount,
//       "NA",
//       "NA",
//       "NA",
//       "INR",
//       "NA",
//       "R",
//       "EDGENPOLEI-NA",
//       "NA",
//       "NA",
//       "F",
//       AdditionalInfo1,
//       "POLEXPERT",
//       "NA",
//       "NA",
//       "NA",
//       "NA",
//       "NA",
//       "http://localhost:3000/success-page",
//     ].join("|");
//     console.log('Working5')

//     // Define the checksum key
//     const ChecksumKey = "XIsNFjV7L75bdi4E8KCEm6DnexbN6jnf";
//     console.log('Working6')


//     // Calculate the checksum
//     // const Checksum = HmacSHA256(formattedRequest, ChecksumKey);

//     const hashedString = CryptoJS.SHA256(ChecksumKey).toString(CryptoJS.enc.Hex);


//     // const Checksum = encryptAes256Text(formattedRequest, ChecksumKey);
//     console.log('Working7')


    

//     console.log(hashedString,'*********')

//     // Add the checksum to the formatted request
//     const finalFormattedRequest =`${formattedRequest}|${hashedString}`;
//     console.log('Working8')


//     console.log('Formatted Request:', finalFormattedRequest);
//     // return  finalFormattedRequest;

//     const tttt = 'https://uat.billdesk.com/pgidsk/PGIMerchantPayment?'+ 'msg' + formattedRequest;

//     console.log('tttt',tttt)


//     window.location.href = 'https://uat.billdesk.com/pgidsk/PGIMerchantPayment?'+ 'msg' + formattedRequest;



//   } catch (error) {
//     console.log('Working9')

//     console.error("Error in zunoBilldeskRequest:", error);
//     // res.status(500).send('Internal Server Error'); // send an error response
//   }
// }

const encryptAes256Text = (plaintext, key) => {
  // Generate a random IV (16 bytes)
  const iv = CryptoJS.lib.WordArray.random(16);

  // Hash the key using SHA-256
  const hashedKey = CryptoJS.SHA256(key);

  // Encrypt the plaintext using the hashed key and IV
  const encrypted = CryptoJS.AES.encrypt(
    plaintext,
    hashedKey,
    {
      iv: iv,
      mode: CryptoJS.mode.CTR,
      padding: CryptoJS.pad.NoPadding, // NoPadding because CTR mode doesn't require padding
    }
  );

  // Concatenate IV and ciphertext
  const combined = iv.concat(encrypted.ciphertext);

  // Encode the result to Base64
  const encryptedBase64 = CryptoJS.enc.Base64.stringify(combined);

  return encryptedBase64;
};






export  const zunoIssueApi = async (apiRequestQQ) => {
  // debugger
  const newData = {quoteNo : apiRequestQQ.quoteNo,
    quoteOptionNo:apiRequestQQ.quoteOptionNo,
    KYCReqNo:apiRequestQQ.KYCReqNo,
    KYCNo:apiRequestQQ.KYCNo,
    ApiUniqueNumber : apiRequestQQ.ApiUniqueNumber.Zuno}


  try {
    const response = await PostDataWithToken(
      "motor/zunoIssuePolicy",newData
    );
    
    return response;
  } catch (err) {
    console.log("err---", err);
  }


};

export const zunoPaymentcall = async (apiRequestQQ) => {
  try {
    const postData = {
      PolicyId: apiRequestQQ.PolicyId,
      bpId:apiRequestQQ.bpId,
      finalPremium: apiRequestQQ?.PaymentAmount,
      ApiUniqueNumber : apiRequestQQ.ApiUniqueNumber.Zuno
    };
    // if("PolicyId" !== null){dispatchQuickQuote("PolicyId",res.data.issuePolicyObject.issuepolicy.policynrTt)}
    console.log("policy id zuno", postData)
    const response = await PostDataWithToken("motor/zunoPaymentRequest", postData);

    if (response.message === "Zuno Payment Request Generated Successfull")  {
      // window.location.href = "https://uat.expertcover.in/success-page";
    } else {
      window.location.href = "https://uat.expertcover.in/error-page";
    }
    return response;
  } catch (err) {
    console.log(err);
  }
};


// Liberty APIS
export const getLibertyAPICall = async (apiRequestQQ) => {
  try {
    console.log("liberty api called");
    const response = await PostDataWithToken(
      "motor3/liberty-QQ",
      quickQuotePayloadObj(apiRequestQQ)
    );
    if (response.status === true) {
      console.log("libery ka response", response?.data);
      const DATA = response.data;
      if (DATA.ErrorText == null) {
        console.log("data aa rha ----", DATA);
        store.dispatch(
          quickQuoteResult(
            policyResultArr(
              "/assets/images/logos/partners/liberty.png",
              "Liberty",
              DATA.QuotationNumber,
              DATA.ProposalNumber,
              apiRequestQQ.RegistrationNumber,
              apiRequestQQ.MakeName,
              apiRequestQQ.ModelName,
              apiRequestQQ.VariantName,
              DATA.TotalODPremiumValue,
              DATA.TotalTPPremiumValue,
              DATA.PAToOwnerDrivervalue != 0
                ? DATA.PAToOwnerDrivervalue
                : false,
              DATA.NetPremium,
              DATA.GST,
              DATA.TotalPremium,
              DATA.MinIDV || 0,
              DATA.MaxIDV || 0,
              DATA.CurrentIDV,
              apiRequestQQ.PolicyStartDate,
              apiRequestQQ.PolicyEndDate,
              DATA.Discount,
              "Liberty General Insurance Ltd",
              apiRequestQQ.NewPolicyType,
              DATA.RoadAssistCoverValue != 0
                ? DATA.RoadAssistCoverValue
                : false,
              DATA.EngineCoverValue != 0 ? DATA.EngineCoverValue : false,
              DATA.CoverLampsTyresTubesValue != 0
                ? DATA.CoverLampsTyresTubesValue
                : false,
              false,
              DATA.ConsumableCoverValue != 0
                ? DATA.ConsumableCoverValue
                : false,
              DATA.ElectricalAccessoriesValue != 0
                ? DATA.ElectricalAccessoriesValue
                : false,
              DATA.NonElectricalAccessoriesValue != 0
                ? DATA.NonElectricalAccessoriesValue
                : false,
              false,
              false,
              false,
              DATA.VoluntaryExcessValue != 0
                ? DATA.VoluntaryExcessValue
                : false,
              DATA.PAToUnnmaedPassengerValue != 0
                ? DATA.PAToUnnmaedPassengerValue
                : false,
              false,
              DATA.NilDepValue != 0 ? DATA.NilDepValue : false,
              DATA.KeyLossCoverValue != 0 ? DATA.KeyLossCoverValue : false,
              DATA.PAToOwnerDrivervalue !== 0
                ? DATA.PAToOwnerDrivervalue
                : false,
              DATA.PAToUnnmaedPassengerValue != 0
                ? DATA.PAToUnnmaedPassengerValue
                : false,
              DATA.ElectricalAccessoriesValue != 0
                ? DATA.ElectricalAccessoriesValue
                : false,
              DATA.NonElectricalAccessoriesValue != 0
                ? DATA.NonElectricalAccessoriesValue
                : false,
              DATA.VoluntaryExcessValue != 0 ? DATA.VoluntaryExcessValue : 0,
              DATA.DisplayNCBDiscountvalue,
              DATA.FuelKitValueODpremium + DATA.FuelKitValueODpremium,
              DATA.LegalliabilityToPaidDriverValue
            )
          )
        );
      }
    }
  } catch (err) {
    console.log(err);
  }
};

export const LibertyFullQuote = async (apiRequestQQ) => {
  try {
    const response = await PostDataWithToken(
      "motor3/liberty-FQ",
      createQuotePayloadObj(apiRequestQQ)
    );
    return response;
  } catch (err) {
    console.log("err---", err);
  }
};

// magma

export const magmaAPICall = async (apiRequestQQ) => {
  const min = 10000000000;
  const max = 99999999999;

  const randomNumber = Math.floor(Math.random() * (max - min + 1) + min);
  const uniqueNumber = "MAGMA" + randomNumber;
  dispatchQuickQuote("ApiUniqueNumber.Magma", uniqueNumber);
  const newData = {
    ...apiRequestQQ,
    ApiUniqueNumber: uniqueNumber,
    PolicyIssueDate: new Date().toLocaleDateString("en-GB").toString(),
  };
  console.log("NEW DATA MAGMA", newData);
  const response = await PostDataWithToken(
    "motor/magma",
    quickQuotePayloadObj(newData)
  );
  if (
    response.status === true &&
    response.data.ServiceResult === "Success" &&
    apiRequestQQ.VehicleType === "4w"
  ) {
    store.dispatch(removeApiErrorMessage("Magma"));


    const data = response?.data;
    console.log("data MAGMA", data);

    dispatchQuickQuote('MagmaProposalNumber',data.OutputResult.ProposalNumber)

    console.log('MagmaProposalNumber....',data.OutputResult.ProposalNumber)


    const premiumBreakUp =
      data.OutputResult.PremiumBreakUp.VehicleBaseValue.AddOnCover;

    const optionAddOn = data.OutputResult.PremiumBreakUp.OptionalAddOnCovers;

    const externalCNGkitTP = optionAddOn.find(
      (cover) => cover.OptionalAddOnCoverName === "ExternalCNGkit-TP"
    );

    const externalCNGkitOD = optionAddOn.find(
      (cover) => cover.OptionalAddOnCoverName === "ExternalCNGkit-OD"
    );
    
    const inbuiltCNGkitTP = optionAddOn.find(
      (cover) => cover.OptionalAddOnCoverName === "InbuiltCNGkit-TP"
    );
    
    const inbuiltCNGkitOD = optionAddOn.find(
      (cover) => cover.OptionalAddOnCoverName === "InbuiltCNGkit-OD"
    );

    const discount = data.OutputResult.PremiumBreakUp.Discount;

    store.dispatch(
      quickQuoteResult(
        policyResultArr(
          "/assets/images/logos/partners/magma.png",

          "Magma",

          "policy Id",

          "ApiId",

          apiRequestQQ?.RegistrationNumber,

          apiRequestQQ?.MakeName,

          apiRequestQQ?.ModelName,

          apiRequestQQ?.VariantName,

          premiumBreakUp.find((cover) => cover.AddOnCoverType === "Basic OD")
            ?.AddOnCoverTypePremium
            ? premiumBreakUp.find(
                (cover) => cover.AddOnCoverType === "Basic OD"
              )?.AddOnCoverTypePremium
            : false,

          premiumBreakUp.find((cover) => cover.AddOnCoverType === "Basic TP")
            ?.AddOnCoverTypePremium
            ? premiumBreakUp.find(
                (cover) => cover.AddOnCoverType === "Basic TP"
              )?.AddOnCoverTypePremium
            : false,

          premiumBreakUp.find(
            (cover) => cover.AddOnCoverType === "PAOwnerCover"
          )?.AddOnCoverTypePremium
            ? premiumBreakUp.find(
                (cover) => cover.AddOnCoverType === "PAOwnerCover"
              )?.AddOnCoverTypePremium
            : false,

          data.OutputResult.PremiumBreakUp.NetPremium,

          data.OutputResult.PremiumBreakUp.SGST +
            data.OutputResult.PremiumBreakUp.CGST,

          data.OutputResult.PremiumBreakUp.TotalPremium,

          data.OutputResult.LowerIDV,

          data.OutputResult.HigherIDV,

          data.OutputResult.IDVofthevehicle,

          data.OutputResult.PolicyEffectiveFromDate,

          data.OutputResult.PolicyEffectiveToDate,

          discount.find(
            (cover) => cover.DiscountType === "Basic OD-Detariff Discount"
          )?.DiscountTypeAmount
            ? discount.find(
                (cover) => cover.DiscountType === "Basic OD-Detariff Discount"
              )?.DiscountTypeAmount
            : false,

          "Magma",

          apiRequestQQ.NewPolicyType,

          premiumBreakUp.find(
            (cover) => cover.AddOnCoverType === "RoadSideAssistance"
          )?.AddOnCoverTypePremium
            ? premiumBreakUp.find(
                (cover) => cover.AddOnCoverType === "RoadSideAssistance"
              )?.AddOnCoverTypePremium
            : false,

          premiumBreakUp.find(
            (cover) => cover.AddOnCoverType === "EngineProtector"
          )?.AddOnCoverTypePremium
            ? premiumBreakUp.find(
                (cover) => cover.AddOnCoverType === "EngineProtector"
              )?.AddOnCoverTypePremium
            : false,

          premiumBreakUp.find((cover) => cover.AddOnCoverType === "Tyre Guard")
            ?.AddOnCoverTypePremium
            ? premiumBreakUp.find(
                (cover) => cover.AddOnCoverType === "Tyre Guard"
              )?.AddOnCoverTypePremium
            : false,

          premiumBreakUp.find(
            (cover) => cover.AddOnCoverType === "Rim Safeguard"
          )?.AddOnCoverTypePremium
            ? premiumBreakUp.find(
                (cover) => cover.AddOnCoverType === "Rim Safeguard"
              )?.AddOnCoverTypePremium
            : false,

          premiumBreakUp.find((cover) => cover.AddOnCoverType === "Consumables")
            ?.AddOnCoverTypePremium
            ? premiumBreakUp.find(
                (cover) => cover.AddOnCoverType === "Consumables"
              )?.AddOnCoverTypePremium
            : false,

          optionAddOn.find(
            (cover) => cover.OptionalAddOnCoverName === "Electrical"
          )?.OptionalAddOnCoverPremium
            ? optionAddOn.find(
                (cover) => cover.OptionalAddOnCoverName === "Electrical"
              )?.OptionalAddOnCoverPremium
            : false,

          optionAddOn.find(
            (cover) => cover.OptionalAddOnCoverName === "NonElectrical"
          )?.OptionalAddOnCoverPremium
            ? optionAddOn.find(
                (cover) => cover.OptionalAddOnCoverName === "NonElectrical"
              )?.OptionalAddOnCoverPremium
            : false,

          premiumBreakUp.find(
            (cover) => cover.AddOnCoverType === "ReturnToInvoice"
          )?.AddOnCoverTypePremium
            ? premiumBreakUp.find(
                (cover) => cover.AddOnCoverType === "ReturnToInvoice"
              )?.AddOnCoverTypePremium
            : false,

          false,
          // "EngineGearBox",

          premiumBreakUp.find(
            (cover) => cover.AddOnCoverType === "NCB Protection"
          )?.AddOnCoverTypePremium
            ? premiumBreakUp.find(
                (cover) => cover.AddOnCoverType === "NCB Protection"
              )?.AddOnCoverTypePremium
            : false,

          discount.find(
            (cover) => cover.DiscountType === "Voluntary Excess Discount-OD"
          )?.DiscountTypeAmount
            ? discount.find(
                (cover) => cover.DiscountType === "Voluntary Excess Discount-OD"
              )?.DiscountTypeAmount
            : false,
          // "VoluntaryDeductive",

          optionAddOn.find(
            (cover) => cover?.OptionalAddOnCoverName === "UnnamedPACover"
          )?.OptionalAddOnCoverPremium
            ? optionAddOn.find(
                (cover) => cover?.OptionalAddOnCoverName === "UnnamedPACover"
              )?.OptionalAddOnCoverPremium
            : false,
          // 'PassengerCover',

          premiumBreakUp.find(
            (cover) => cover.AddOnCoverType === "LossOfPerBelongings"
          )?.AddOnCoverTypePremium
            ? premiumBreakUp.find(
                (cover) => cover.AddOnCoverType === "LossOfPerBelongings"
              )?.AddOnCoverTypePremium
            : false,

          premiumBreakUp.find(
            (cover) => cover.AddOnCoverType === "ZeroDepreciation"
          )?.AddOnCoverTypePremium
            ? premiumBreakUp.find(
                (cover) => cover.AddOnCoverType === "ZeroDepreciation"
              )?.AddOnCoverTypePremium
            : false,

          premiumBreakUp.find(
            (cover) => cover.AddOnCoverType === "KeyReplacement"
          )?.AddOnCoverTypePremium
            ? premiumBreakUp.find(
                (cover) => cover.AddOnCoverType === "KeyReplacement"
              )?.AddOnCoverTypePremium
            : false,

          "PaCoverAmount",

          "PassengerCoverAmount",

          discount.find(
            (cover) => cover.DiscountType === "Elecrical-Detariff Discount"
          )?.DiscountTypeAmount
            ? discount.find(
                (cover) => cover.DiscountType === "Elecrical-Detariff Discount"
              )?.DiscountTypeAmount
            : false,

          discount.find(
            (cover) => cover.DiscountType === "NonElecrical-Detariff Discount"
          )?.DiscountTypeAmount
            ? discount.find(
                (cover) =>
                  cover.DiscountType === "NonElecrical-Detariff Discount"
              )?.DiscountTypeAmount
            : false,

          "VoluntaryAmount",

          discount.find((cover) => cover.DiscountType === "Bonus Discount - OD")
            ?.DiscountTypeAmount
            ? discount.find(
                (cover) => cover.DiscountType === "Bonus Discount - OD"
              )?.DiscountTypeAmount
            : false,
          // 'NCBDiscount',
          

          // optionAddOn.find(
          //   (cover) => cover.OptionalAddOnCoverName === "ExternalCNGkit-TP"
          // )?.OptionalAddOnCoverPremium
          //   ? optionAddOn.find(
          //       (cover) => cover.OptionalAddOnCoverName === "ExternalCNGkit-TP"
          //     )?.OptionalAddOnCoverPremium +
          //       optionAddOn.find(
          //         (cover) =>
          //           cover.OptionalAddOnCoverName === "ExternalCNGkit-OD"
          //       )?.OptionalAddOnCoverPremium
          //   : false,

          externalCNGkitTP
          ? externalCNGkitTP.OptionalAddOnCoverPremium +
            (externalCNGkitOD?.OptionalAddOnCoverPremium || 0)
          : inbuiltCNGkitTP
          ? inbuiltCNGkitTP.OptionalAddOnCoverPremium +
            (inbuiltCNGkitOD?.OptionalAddOnCoverPremium || 0)
          : false ,

          // CNGValue

          optionAddOn.find(
            (cover) => cover.OptionalAddOnCoverName === "LLPaidDriverCleaner-TP"
          )?.OptionalAddOnCoverPremium
            ? optionAddOn.find(
                (cover) => cover.OptionalAddOnCoverName === "LLPaidDriverCleaner-TP"
              )?.OptionalAddOnCoverPremium
            : false,
          // DriverLiability
          discount.find(
            (cover) => cover.DiscountType === "ExternalCNGkit-Detariff Discount"
          )?.DiscountTypeAmount
            ? discount.find(
                (cover) =>
                  cover.DiscountType === "ExternalCNGkit-Detariff Discount"
              )?.DiscountTypeAmount
            : false
        )
      )
    );
  } else if (
    response.status === true &&
    response.data.ServiceResult === "Success" &&
    apiRequestQQ.VehicleType === "2w"
  ) {
    store.dispatch(removeApiErrorMessage("Magma"));

    const data = response?.data;

    const premiumBreakUp = data.OutputResult.PremiumBreakUp.OptionalAddOnCovers;

    const optionAddOn = data.OutputResult.PremiumBreakUp.OptionalAddOnCovers;

    const discount = data.OutputResult.PremiumBreakUp.Discount;

    store.dispatch(
      quickQuoteResult(
        policyResultArr(
          "/assets/images/logos/partners/magma.png",

          "Magma",

          "policy Id",

          "ApiId",

          apiRequestQQ?.RegistrationNumber,

          apiRequestQQ?.MakeName,

          apiRequestQQ?.ModelName,

          apiRequestQQ?.VariantName,

          premiumBreakUp.find((cover) => cover.AddOnCoverType === "Basic OD")
            ?.AddOnCoverTypePremium
            ? premiumBreakUp.find(
                (cover) => cover.AddOnCoverType === "Basic OD"
              )?.AddOnCoverTypePremium
            : false,

          premiumBreakUp.find((cover) => cover.AddOnCoverType === "Basic TP")
            ?.AddOnCoverTypePremium
            ? premiumBreakUp.find(
                (cover) => cover.AddOnCoverType === "Basic TP"
              )?.AddOnCoverTypePremium
            : false,

          premiumBreakUp.find(
            (cover) => cover.AddOnCoverType === "PAOwnerCover"
          )?.AddOnCoverTypePremium
            ? premiumBreakUp.find(
                (cover) => cover.AddOnCoverType === "PAOwnerCover"
              )?.AddOnCoverTypePremium
            : false,

          data.OutputResult.PremiumBreakUp.NetPremium,

          data.OutputResult.PremiumBreakUp.SGST +
            data.OutputResult.PremiumBreakUp.CGST,

          data.OutputResult.PremiumBreakUp.TotalPremium,

          data.OutputResult.LowerIDV,

          data.OutputResult.HigherIDV,

          data.OutputResult.IDVofthevehicle,

          data.OutputResult.PolicyEffectiveFromDate,

          data.OutputResult.PolicyEffectiveTodate,

          discount.find(
            (cover) => cover.DiscountType === "Basic OD-Detariff Discount"
          )?.DiscountTypeAmount
            ? discount.find(
                (cover) => cover.DiscountType === "Basic OD-Detariff Discount"
              )?.DiscountTypeAmount
            : false,

          "Magma",

          apiRequestQQ.NewPolicyType,

          premiumBreakUp.find(
            (cover) => cover.AddOnCoverType === "RoadSideAssistance"
          )?.AddOnCoverTypePremium
            ? premiumBreakUp.find(
                (cover) => cover.AddOnCoverType === "RoadSideAssistance"
              )?.AddOnCoverTypePremium
            : false,

          premiumBreakUp.find(
            (cover) => cover.AddOnCoverType === "EngineProtector"
          )?.AddOnCoverTypePremium
            ? premiumBreakUp.find(
                (cover) => cover.AddOnCoverType === "EngineProtector"
              )?.AddOnCoverTypePremium
            : false,

          premiumBreakUp.find((cover) => cover.AddOnCoverType === "Tyre Guard")
            ?.AddOnCoverTypePremium
            ? premiumBreakUp.find(
                (cover) => cover.AddOnCoverType === "Tyre Guard"
              )?.AddOnCoverTypePremium
            : false,

          premiumBreakUp.find(
            (cover) => cover.AddOnCoverType === "Rim Safeguard"
          )?.AddOnCoverTypePremium
            ? premiumBreakUp.find(
                (cover) => cover.AddOnCoverType === "Rim Safeguard"
              )?.AddOnCoverTypePremium
            : false,

          premiumBreakUp.find((cover) => cover.AddOnCoverType === "Consumables")
            ?.AddOnCoverTypePremium
            ? premiumBreakUp.find(
                (cover) => cover.AddOnCoverType === "Consumables"
              )?.AddOnCoverTypePremium
            : false,

          optionAddOn.find(
            (cover) => cover.OptionalAddOnCoverName === "Electrical"
          )?.OptionalAddOnCoverPremium
            ? optionAddOn.find(
                (cover) => cover.OptionalAddOnCoverName === "Electrical"
              )?.OptionalAddOnCoverPremium
            : false,

          optionAddOn.find(
            (cover) => cover.OptionalAddOnCoverName === "NonElectrical"
          )?.OptionalAddOnCoverPremium
            ? optionAddOn.find(
                (cover) => cover.OptionalAddOnCoverName === "NonElectrical"
              )?.OptionalAddOnCoverPremium
            : false,

          premiumBreakUp.find(
            (cover) => cover.AddOnCoverType === "ReturnToInvoice"
          )?.AddOnCoverTypePremium
            ? premiumBreakUp.find(
                (cover) => cover.AddOnCoverType === "ReturnToInvoice"
              )?.AddOnCoverTypePremium
            : false,

          false,
          // "EngineGearBox",

          premiumBreakUp.find(
            (cover) => cover.AddOnCoverType === "NCB Protection"
          )?.AddOnCoverTypePremium
            ? premiumBreakUp.find(
                (cover) => cover.AddOnCoverType === "NCB Protection"
              )?.AddOnCoverTypePremium
            : false,

          discount.find(
            (cover) => cover.DiscountType === "Voluntary Excess Discount-OD"
          )?.DiscountTypeAmount
            ? discount.find(
                (cover) => cover.DiscountType === "Voluntary Excess Discount-OD"
              )?.DiscountTypeAmount
            : false,
          // "VoluntaryDeductive",

          optionAddOn.find(
            (cover) => cover?.OptionalAddOnCoverName === "UnnamedPACover"
          )?.OptionalAddOnCoverPremium
            ? optionAddOn.find(
                (cover) => cover?.OptionalAddOnCoverName === "UnnamedPACover"
              )?.OptionalAddOnCoverPremium
            : false,
          // 'PassengerCover',

          premiumBreakUp.find(
            (cover) => cover.AddOnCoverType === "LossOfPerBelongings"
          )?.AddOnCoverTypePremium
            ? premiumBreakUp.find(
                (cover) => cover.AddOnCoverType === "LossOfPerBelongings"
              )?.AddOnCoverTypePremium
            : false,

          premiumBreakUp.find(
            (cover) => cover.AddOnCoverType === "ZeroDepreciation"
          )?.AddOnCoverTypePremium
            ? premiumBreakUp.find(
                (cover) => cover.AddOnCoverType === "ZeroDepreciation"
              )?.AddOnCoverTypePremium
            : false,

          premiumBreakUp.find(
            (cover) => cover.AddOnCoverType === "KeyReplacement"
          )?.AddOnCoverTypePremium
            ? premiumBreakUp.find(
                (cover) => cover.AddOnCoverType === "KeyReplacement"
              )?.AddOnCoverTypePremium
            : false,

          "PaCoverAmount",

          "PassengerCoverAmount",

          discount.find(
            (cover) => cover.DiscountType === "Elecrical-Detariff Discount"
          )?.DiscountTypeAmount
            ? discount.find(
                (cover) => cover.DiscountType === "Elecrical-Detariff Discount"
              )?.DiscountTypeAmount
            : false,

          discount.find(
            (cover) => cover.DiscountType === "NonElecrical-Detariff Discount"
          )?.DiscountTypeAmount
            ? discount.find(
                (cover) =>
                  cover.DiscountType === "NonElecrical-Detariff Discount"
              )?.DiscountTypeAmount
            : false,

          "VoluntaryAmount",

          discount.find((cover) => cover.DiscountType === "Bonus Discount - OD")
            ?.DiscountTypeAmount
            ? discount.find(
                (cover) => cover.DiscountType === "Bonus Discount - OD"
              )?.DiscountTypeAmount
            : false,
          // 'NCBDiscount',

          optionAddOn.find(
            (cover) => cover.OptionalAddOnCoverName === "ExternalCNGkit-TP"
          )?.OptionalAddOnCoverPremium
            ? optionAddOn.find(
                (cover) => cover.OptionalAddOnCoverName === "ExternalCNGkit-TP"
              )?.OptionalAddOnCoverPremium +
                optionAddOn.find(
                  (cover) =>
                    cover.OptionalAddOnCoverName === "ExternalCNGkit-OD"
                )?.OptionalAddOnCoverPremium
            : false,
          // CNGValue

          optionAddOn.find(
            (cover) => cover.OptionalAddOnCoverName === "PAPaidDriver"
          )?.OptionalAddOnCoverPremium
            ? optionAddOn.find(
                (cover) => cover.OptionalAddOnCoverName === "PAPaidDriver"
              )?.OptionalAddOnCoverPremium
            : false,
          // DriverLiability
          discount.find(
            (cover) => cover.DiscountType === "ExternalCNGkit-Detariff Discount"
          )?.DiscountTypeAmount
            ? discount.find(
                (cover) =>
                  cover.DiscountType === "ExternalCNGkit-Detariff Discount"
              )?.DiscountTypeAmount
            : false
        )
      )
    );
  } else if (
    response.status === true &&
    apiRequestQQ.VehicleType === "2w" &&
    response.data.ServiceResult === "Failure"
  ) {
    const error = response.data.ErrorText.split(":")[3];
    saveApiErrorReponse(
      "Magma",
      '',
      "/assets/images/logos/partners/magma.png"
    );
  } else {
    // debugger
    const error = response
    saveApiErrorReponse(
      "Magma",
      '',
      "/assets/images/logos/partners/magma.png"
    );
  }
};

export const magmaProposalCall = async (data) => {
  // debugger;
  console.log("magmaa data", data);
  try {
    const newData = {
      ...data,
      PolicyIssueDate: new Date().toLocaleDateString("en-GB").toString(),
    };
    const response = await PostDataWithToken(
      "motor/magmaProposal",
      createQuotePayloadObj(newData)
    );
    return response;
  } catch (err) {
    console.log(err);
  }
};

export const magmaProposalStatuscall = async (data) => {
  try {
    const newData = {
      ...data,
      PolicyIssueDate: new Date().toLocaleDateString("en-GB").toString(),
    };
    const response = await PostDataWithToken("motor/proposalStatus", newData);
    return response;
  } catch (err) {
    console.log(err);
  }
};

export const magmaPaymentcall = async (apiRequestQQ) => {
  try {
    const postData = {
      ProposalNumber: apiRequestQQ?.ProposalId,
      CustomerID: apiRequestQQ.CustomerID,
      SuccessURL: "https://uat.expertcover.in/success-page",
      FailureURL: "https://uat.expertcover.in/error-page",
      CustomerEmailID: apiRequestQQ?.Email,
      BillingAmount: apiRequestQQ?.FinalPremium,
      CustomerMobileNumber: apiRequestQQ?.MobileNumber,
      PaymentGateway: "PAYU",
      ApiUniqueNumber: apiRequestQQ?.ApiUniqueNumber?.Magma,
      VehicleType: apiRequestQQ?.VehicleType,
      PolicyIssueDate: new Date().toLocaleDateString("en-GB").toString(),
    };
    const response = await PostDataWithToken("motor/generatePayment", postData);
    return response;
  } catch (err) {
    console.log(err);
  }
};

export const magmaPDFDownload = async (selectedPlan, apiRequestQQ) => {
  // debugger;
  try {
    const postData = {
      PolicyNumber: selectedPlan?.PolicyNumber,
      CustomerID: apiRequestQQ.CustomerID,
      IntermediaryCode: "BRC0000388",
      ProposalNumber: apiRequestQQ.ProposalId,
      ApiUniqueNumber: apiRequestQQ?.ApiUniqueNumber?.Magma,
      VehicleType: apiRequestQQ?.VehicleType,
      PolicyIssueDate: new Date().toLocaleDateString("en-GB").toString(),
    };
    console.log(postData, "postData");
    const response = await PostDataWithToken("motor/magma-pdf", postData);
    console.log("response in magma pdf", response);
    if (response?.data?.OutputResult?.PolicyBase64String) {
      downloadBase64File(
        response?.data?.OutputResult?.PolicyBase64String,
        "PEIB_Magma_PDF"
      );
    } else {
      setLoading(false);
    }
    return response?.data?.OutputResult?.PolicyBase64String;
  } catch (err) {
    console.log(err);
  }
};

export const getMotorQuote = async (motorRequest) => {
  console.log("motorRequest", motorRequest);
  try {
    const response = await PostDataWithToken_V3(
      "motor/getQuotesData",
      motorQuotationObj(motorRequest)
    );
    console.log('motorQuotationObj1',motorQuotationObj)
    console.log('motorQuotationObj',motorQuotationObj(motorRequest))
    console.log("response motorRequest", response);
  // debugger
    return response?.data;
  } catch (error) {
  // debugger
    return error;
  }
};

export const submitProposal = async (newData, selectedPlan) => {
  try {
    const response = await PostDataWithToken_V3(
      "motor/submitProposal",
      submitProposalRequest(newData, selectedPlan)
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const getKycConfig = async (insurerId, customerType) => {
  // debugger
  const postData = {
    insurerId: insurerId,
    customerType: customerType,
  };
  try {
    const response = await PostDataWithToken_V3("motor/getKycConfig", postData);
    return response;
  } catch (error) {
    return error;
  }
};

export const getKyc = async (data, selectedPlan,motorRequest) => {
  // debugger
  try {
    const response = await PostDataWithToken_V3(
      "motor/getKyc",
      kycObj(data, selectedPlan,motorRequest)
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const getKycStatus = async (data, planDetail) => {
  try {
    const response = await PostDataWithToken_V3(
      "motor/getKycStatus",
      kycStatus(data, planDetail)
    );
    return response;
  } catch (error) {
    return error;
  }
};

 export const paymentTata = async (paymentUrl)=>{

  try{
    const response = await GetData_V3(
      paymentUrl
    )

    if(response){
      window.location.href = response
    }

    if(response.data.returnData.policyDocumentUrl !== null){

      try{

      GetData_V3(response.data.returnData.policyDocumentUrl)

      }

      catch (error) {
        return error;
      }
    }

    console.log('response',response)
  }
  catch (error) {
    return error;
  }
 }

//  export const getPolicyDocument = async (data) => {
//   debugger
//   try {
//     const documentResponse = await GetData_V3(`motor/getPolicyDocument/${data}`);

//     if (
//       documentResponse?.data?.caseStatus === "Policy Document Success"
//     ) {
//       let response = decryptAes256Text(
//         documentResponse?.data?.policyDocumentUrl,
//         process.env.REACT_APP_DECRYPTION_KEY
//       );

//       console.log('response',response)

//       window.open(response)
//     } else {
//       // Handle the case where the document response is not successful
//       console.log("Failed to fetch policy document: ", documentResponse);
//       toast.error(
//         documentResponse?.data?.displayMessage
//           ? `${documentResponse.data.displayMessage}`
//           : documentResponse?.data?.errors
//           ? `${documentResponse?.data?.errors[0].displayMessage}`
//           : `${documentResponse?.errors?.[0]?.displayMessage}`,
//         {
//           position: "top-right",
//           autoClose: 5000,
//           hideProgressBar: false,
//           closeOnClick: true,
//           pauseOnHover: true,
//           draggable: true,
//           progress: undefined,
//           theme: "light",
//         }
//       );
//     }

//   } catch (error) {
//     return error;
//   }
// };


export const processFlow = async () => {
  try {
    const response = await GetData("admin/flow");
    return response;
  } catch (error) {
    return error;
  }
};
