import React, { useEffect, useRef, useState } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  dispatchMotorQuote,
  dispatchQuickQuote,
  kycConfig,
  selectedPlanAction,
} from "../../../store/actions/userActions";
import { getStates, getVehiclePincode } from "../../Services/masterServices";
import { createCustomer, getKyc } from "../Homepage/TPApiCall";
import ReactSelect from "../Tags/ReactSelect";
import { MultiSelect } from "react-multi-select-component";
import moment from "moment";
import { PostDataWithToken,GetDataWithToken,GetData } from "../../../api/apiHelper";
import { sendSuccessInfo } from "../../Services/PosService";
import FutureGeneralKYCRedirect from "../../pages/Additional/FutureGeneralKYCRedirect";
import LibertyKYCRedirect from "../../pages/Additional/LibertyKYCRedirect";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
const OwnerDetails = ({
  state,
  activeTab,
  toggle,
  // pincodeData,
  handleNextClick,
  scrollY,
  setScrollY,
}) => {
  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
  } = useForm({ mode: "onBlur" });
  // const [pincodeData, setPincodeData] = React.useState([]);
  const apiRequestQQ = useSelector((state) => state.root.apiRequestQQ);
  const selectedPlan = useSelector((state) => state.root.selectedPlan);
  const dispatch = useDispatch();
  const QuickQouteResult = useSelector((store) => store.root.QuickQouteResult);
  const formRef = useRef(null);
  const [docType, setDocType] = useState("");
  const [futureKYC, setFutureKyc] = useState(false);
  const [libertyKYC, setLibertyKYC] = useState(false);
  const [futureKYCData, setFutureKYCData] = useState({});
  const [pincodeData, setPincodeData] = useState([]);
  const [libertyData, setLibertyData] = useState({});
  const [stateName, setState] = useState("");
  const [disabled, setDisabled] = useState("");
  const [kycObj, setKycObj] = useState({});
  const [magmaAadharKyc, setMagmaAadharKyc] = useState(false);
  const [KYCSpinner, setKYCSpinner] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalOpen2, setModalOpen2] = useState(false);
  const [modalOpen3, setModalOpen3] = useState(false);
  const [modalOpen4, setModalOpen4] = useState(false);
  const [modalOpen5, setModalOpen5] = useState(false);
  const [zunoResponse,setZunoResponse] = useState(false)
  const [hidebtn, setHideBtn] = useState(false);
  const [martial,setMartial] = useState('')
  const [pan, setPan] = useState({
    number: "",
    image: "",
    error: "",
  });
  const [adhar, setAdhar] = useState({
    number: "",
    image: "",
    error: "",
  });
  const [adharBack, setAdharBack] = useState({
    number: "",
    image: "",
    error: "",
  });
  const [ckyc, setCkyc] = useState({
    number: "",
    error: "",
  });
  const [mobileNumber, setMobileNumber] = useState({
    number: "",
    error: "",
  });

  const [stateData, setStateData] = useState([]);
  const motorRequest = useSelector((state) => state.root.motorRequest);
  const kycConfigVal = useSelector((state) => state.root.kycConfig);

  useEffect(() => {
    console.log("clicked");
    console.log("apiRequestQQ", apiRequestQQ);
    console.log("selectedPlan", selectedPlan);
    console.log("QuickQouteResult", QuickQouteResult);
    reset({
      Email: apiRequestQQ.Email,
      FirstName: apiRequestQQ.FirstName,
      LastName: apiRequestQQ.LastName,
      MobileNumber: apiRequestQQ.MobileNumber,
      PanNumber: apiRequestQQ.PanNumber,
      ...(apiRequestQQ.Dob && { Dob: apiRequestQQ.Dob }),
      ...(apiRequestQQ.Gender && { Gender: apiRequestQQ.Gender }),
      ...(apiRequestQQ.Street && { Street: apiRequestQQ.Street }),
      ...(apiRequestQQ.StreetNumber && {
        StreetNumber: apiRequestQQ.StreetNumber,
      }),
      ...(apiRequestQQ.Area && { Area: apiRequestQQ.Area }),
      ...(apiRequestQQ.State && { State: apiRequestQQ.State }),
      ...(apiRequestQQ.District && { District: apiRequestQQ.District }),
      MobileNumber: apiRequestQQ.MobileNumber,
    });
    if (apiRequestQQ.PanImage != "") {
      setPan({
        image: "data:image/jpeg;base64," + apiRequestQQ.PanImage,
        number: apiRequestQQ.PanNumber,
      });
    }
    if (selectedPlan.Api_name === "HDFC" && apiRequestQQ.PanNumber) {
      checkHDFCKYCStatus(apiRequestQQ.PanNumber, false);
    }

    if (selectedPlan.Api_name == "Royal") {
      if (apiRequestQQ.Dob != "" && apiRequestQQ.Street != "") {
        royalSundramKYCDetails(
          apiRequestQQ.FirstName,
          apiRequestQQ.LastName,
          apiRequestQQ.Dob,
          apiRequestQQ.PanNumber,
          false
        );
      }
    }
    if (selectedPlan.Api_name == "Liberty") {
      if (
        apiRequestQQ.KYC.Liberty != "" &&
        apiRequestQQ.KYC.LibertyRefNo != ""
      ) {
        getLibertyKYC(null, false);
      }
    }
  }, []);

  useEffect(() => {
    // debugger
    if (apiRequestQQ.ProposalId) {
      FutureGeneralUserKycStatus(apiRequestQQ.ProposalId);
    }
    // PostDataWithToken('kyc/future-general-kycStatus', {
    //   proposal_id: apiRequestQQ.ProposalId
    // }).then(response => {
    //   debugger
    //   if (response.data) {
    //     if (response.Final_Status === "3" || response.Final_Status === "1" ) {
    //       sendSuccessInfo(
    //         'Your Future KYC is successfully generated ' +
    //           response.data?.kyc_data?.CKYC?.result?.PERSONAL_DETAILS?.CKYC_NO
    //       )
    //       dispatchQuickQuote(
    //         'KYC.' + 'Future',
    //         response.data?.kyc_data?.CKYC?.result?.PERSONAL_DETAILS?.CKYC_NO
    //       )
    //       toggle(activeTab + 1)
    //       setDisabled('')
    //     } else {
    //       setDisabled('')
    //       alert('KYC is not generated please try again ')
    //     }
    //   }
    // })
    // console.log(kycObj, 'kycObj')
    // FutureGeneralUserKyc(
    //   apiRequestQQ.CustomerType === 'INDIVIDUAL' ? 'I' : 'C',
    //   apiRequestQQ.PanNumber,
    //   apiRequestQQ.Dob,
    //   apiRequestQQ?.FirstName + ' ' + apiRequestQQ?.LastName,
    //   apiRequestQQ?.Gender,
    //   selectedPlan?.PolicyId
    // )
  }, []);

  useEffect(() => {
    dispatch(
      selectedPlanAction({
        ...selectedPlan,
        KYCID: kycObj?.KYCLogID,
        KYCNumber: kycObj?.KYCNumber,
        KYCType: kycObj?.KYCType,
      })
    );
  }, [kycObj]);

  // const scrollToTop = () => {
  //   if (formRef.current) {
  //     formRef.current.scrollIntoView({ behavior: "smooth" });
  //   }
  // };

  const handleSelectState = (val) => {
    // setState(val.label)
    dispatchQuickQuote("State", val);
    let index = stateData.findIndex((item) => item.STATEDESC == val);
    if (index > -1) {
      dispatchQuickQuote("StateCode", stateData[index].Digit_Code);
    }
  };

  const handleMartialStatus =(val)=>{

    setMartial(val)

  }

  const handleSelectPincode = (val) => {
    reset({ City: val.City });
    dispatchQuickQuote("Pincode", val.value);

    dispatchQuickQuote("District", val.District);
    dispatchQuickQuote("City", val.City);

    console.log("pincode data", val);
    dispatchMotorQuote("cityId", val?.cityId);
    dispatchMotorQuote("stateId", val?.stateId);
    dispatchMotorQuote("pincode", val?.value);

    getStates(val.value)
      .then((response) => {
        if (response.status === true) {
          let stats = response.data[0];
          setStateData(response.data[0]);
          reset({ State: stats.STATEDESC });
          dispatchQuickQuote("State", stats.STATEDESC);
          dispatchQuickQuote("StateCode", stats.Digit_Code);
        }
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getVehiclePincode()
      .then((response) => {
        console.log("RESPONSE PINCODE DATA", response);
        if (response.status === true) {
          let i = 0;
          let arr = [];
          let arr1 = [];
          let j = 0;
          while (i < response.data.length) {
            arr.push({
              value: response.data[i].Pin_Code,
              label:
                response.data[i].Pin_Code + " - " + response.data[i].District,
              District: response.data[i].District,
              City: response.data[i].City,
              State: response.data[i].State,
              cityId: response.data[i].cityId,
              stateId: response.data[i].stateId,
            });

            i++;
          }
          console.log("ARRAY PINCODE DATA", arr);
          // while (j < response.data.length) {
          //   arr1.push({
          //     value: response.data[j].Pin_Code,
          //     label: response.data[j].District,
          //     District: response.data[j].District,
          //     City: response.data[j].City,
          //     State: response.data[j].State,
          //   });

          //   j++;
          // }
          setPincodeData(arr);
          console.log("ARRAY PINCODE DATA", arr);
          // setCityData(arr1);
        }
      })
      .catch((err) => console.log(err));
  }, []);

  const handleSaveUser = (data) => {
    // debugger;
    console.log("dATA FOR MAGMA", data);
    let dataForMagmaKYC = {
      DocumentName: "AADHAAR",
      DocumentType: "Image",
      Document64String: apiRequestQQ?.AdharImage,
    };
    console.log("dATA FOR MAGMA", dataForMagmaKYC);

    window.scrollTo(0, scrollY);
    console.log("LOG ", data);
   

    dispatchQuickQuote("CompanyName", data?.companyName);
    dispatchQuickQuote("GSTNo", data?.GSTNo);
    dispatchQuickQuote("IncorporationDate", data?.Dob);
    dispatchQuickQuote("MobileNumber", data?.MobileNumber);
    console.log("IncorporationDate", data?.Dob);
    dispatchQuickQuote("Father",data?.Father)
    dispatchQuickQuote("Spouse",data?.Spouse)

    for (let key in data) {
      if (key != "KycNumber") {
        console.log(key, data[key]);
        dispatchQuickQuote(key, data[key]);
      } else if (key == "KycNumber") {
        dispatchQuickQuote("KYC." + "Shriram", data[key] || "");
      }
    }
    let postData = {
      name:
        data.FirstName + data?.MiddleName
          ? data?.MiddleName
          : "" + data.LastName,
      email: data.Email,
      phone: data.MobileNumber,
      dob: data.Dob,
      companyName: (data?.companyName && data?.companyName.toUpperCase()) || "",
      IncorporationDate: data?.IncorporationDate || "",
      city: data.City,
      state: stateName,
      address_line1: data.StreetNumber,
      address_line2: data.Street,
      address_line3: data.Area,
      customerId: apiRequestQQ.customerId,
      customer_type: apiRequestQQ.CustomerType,
      pan_card: data.PanNumber,
      ckyc: data.ckyc,
      gst_no: (data.GSTNo && data.GSTNo.toUpperCase()) || "",
      pincode: apiRequestQQ.Pincode,
    };

    if (selectedPlan.Api_name === "Magma") {
      checkMagmaKycDetails(data);
      createCustomer(postData);
    }

    // createCustomer(postData);
    if (selectedPlan.Api_name === "HDFC") {
      checkHDFCKYCStatus(data.PanNumber, data.MobileNumber, data.Dob ,  true);
    } else if (selectedPlan.Api_name === "bajaj") {
      console.log(data, "Data Print");
      checkBajajKYCStatus(
        // data.PanNumber ? data.PanNumber : apiRequestQQ.PanNumber,
        // data?.PanNumber ? 'C' : 'Z',
        data?.PanNumber ? "C" : "E",
        data.PanNumber ? data.PanNumber : data.ckycNumber,
        data.Dob,
        data.Gender
      );
    } else if(selectedPlan.Api_name === "Tata AIG General Insurance Co. Ltd." || "ICICI Lombard General Insurance Co. Ltd." || "Bajaj Allianz General Insurance Co. Ltd."){

      setKYCSpinner(true);
      setHideBtn(true)

      console.log("submitOwnerDetails", data);
      // for (let key in data) {
      //   dispatchMotorQuote(`${key}`, data[key]);
      // }
      dispatchMotorQuote("salutation", data.Salutation)
      dispatchMotorQuote("firstName", data.FirstName)
      dispatchMotorQuote("middleName", data.MiddleName)
      dispatchMotorQuote("lastName", data.LastName)
      dispatchMotorQuote("addressLine1", data.StreetNumber)
      dispatchMotorQuote("addressLine2", data.Street)
      dispatchMotorQuote("addressLine3", data.Area)
      dispatchMotorQuote("mobileNumber", data.MobileNumber)
      dispatchMotorQuote("email", data.Email)
      dispatchMotorQuote("gender" , data.Gender)
      dispatchMotorQuote("dob",data.Dob)
      dispatchMotorQuote("kycMainDocType","pan")
      dispatchMotorQuote("kycMainDocValue",data.PanNumber)

      console.log('kycConfigVal',kycConfigVal)


      if (kycConfigVal?.kycConfig?.kycStep === "before_proposal") {
        // debugger
        console.log('data',data)
        console.log('selectedPlan',selectedPlan)
        console.log('motorRequest',motorRequest)
        getKyc(data, selectedPlan, motorRequest)
          .then((respon) => {
            console.log('response kyc', respon);
      
            if (respon.data.kycStatus === 'success') {
              dispatchMotorQuote('kycStatus', 'success');
              toast.success('Kyc Successful');
              setKYCSpinner(false);
              setHideBtn(false)
              toggle(activeTab + 1);
            } else if (respon.data.kycStatus === 'failure'||respon.data.kycStatus !== 'success' ) {
              dispatchMotorQuote('kycStatus', 'failure');
              toast.error('Kyc Fail');
              setKYCSpinner(false);
              setHideBtn(false)
            }
          })
          .catch((error) => {
            console.error('Error during KYC process:', error);
            toast.error('An error occurred during KYC process');
          });
      }
      else{
        setKYCSpinner(false);
        setHideBtn(false)
        toggle(activeTab + 1)
      }               
      

  }
    else if (selectedPlan.Api_name == "Future") {
      FutureGeneralUserKyc(
        data.PanNumber ? data.PanNumber : apiRequestQQ.PanNumber,
        data.Dob,
        postData.name,
        data.Gender,
        apiRequestQQ.PolicyId
      );
    } else if (selectedPlan.Api_name == "Reliance") {
      checkRelianceKYCDetails(
        data.PanNumber ? data.PanNumber : apiRequestQQ.PanNumber,
        data.Dob
      );
    } else if (selectedPlan.Api_name == "Kotak") {
      checkKotakKycDetails(
        data.PanNumber ? data.PanNumber : apiRequestQQ.PanNumber,
        data
      );
    } else if (selectedPlan.Api_name == "Royal") {
      royalSundramKYCDetails(
        data.FirstName,
        data.LastName,
        data.PanNumber ? data.PanNumber : apiRequestQQ.PanNumber,
        data.Dob,
        true
      );
    } else if (selectedPlan.Api_name == "Zuno") {
      getZunoKyc(data, apiRequestQQ.Pincode);
    } else if (selectedPlan.Api_name == "Liberty") {
      getLibertyKYC(data, true);
    } 

    else if(selectedPlan.Api_name === "digit"){

      apiRequestQQ.CustomerType == "INDIVIDUAL"
          ? toggle(activeTab + 1)
          : toggle(activeTab + 2);

    }

    else {
      // setTimeout(() => {
      //   apiRequestQQ.CustomerType == "INDIVIDUAL"
      //     ? toggle(activeTab + 1)
      //     : toggle(activeTab + 2);
      // }, 1000);
    }
    // setTimeout(() => {
    //   toggle(activeTab + 1);
    // }, 400);
  };

  const checkHDFCKYCStatus = (pannumber, mobileNumber,Dob ,  redirect) => {
    // debugger  
    setKYCSpinner(true);
    if(apiRequestQQ.CustomerType === "INDIVIDUAL"){
      // debugger
    PostDataWithToken("kyc/hdfc-kyc", {
      PanNumber: pannumber,
      ApiUniqueNumber: apiRequestQQ?.ApiUniqueNumber?.HDFC,
      MobileNumber: mobileNumber,
      PolicyIssueDate: new Date().toLocaleDateString("en-GB").toString(),
    }).then((response) => {
      if (response.status === true) {
        setKYCSpinner(false);
        console.log("hdfc kyc response", response.data.kyc_id);
        if (response.data.iskycVerified == 1) {
          dispatchQuickQuote("KYC." + "HDFC", response.data.kyc_id);
          dispatchQuickQuote("FirstName", response.data?.name.split(" ")[0]);
          // dispatchQuickQuote("LastName", response.data?.name.split(" ")[1]);
          dispatchQuickQuote("PanNumber", response.data?.pan);
          dispatchQuickQuote(
            "Dob",
            moment(response.data?.dob, "DD/MM/YYYY").format("YYYY-MM-DD")
          );
          // alert('KYC Successfull')
          // setModalOpen(true)
          toast.success("KYC Successfull");
          // dispatchQuickQuote('MobileNumber',response.data?.mobile)
          reset({
            FirstName: response.data?.name.split(" ")[0],
            lastName: response.data?.name.split(" ")[1],
            Dob: moment(response.data?.dob, "DD/MM/YYYY").format("YYYY-MM-DD"),
            PanNumber: response.data.pan,
            // MobileNumber : response.data.mobile
          });
          if (redirect === true) {
            setTimeout(() => {
              toggle(activeTab + 1);
            }, 500);
          }
        } else {
          // alert("You have to complete your HDFC E-kyc first");
          // setModalOpen2(true)
          toast.error("You have to complete your HDFC E-kyc first");
          //   setTimeout(() => {
          //     window.open(response.data.redirect_link, "_blank");
          // }, 0);
        }
      } else {
        // alert("You have to complete your HDFC E-kyc first");
        toast.error("You have to complete your HDFC E-kyc first");
        // setModal2(true)
        window.open(response.data.redirect_link, "_blank");
      }
    })}
    else {

    console.log('dobbbb1',Dob)


    const DOB = new Date(Dob).toLocaleDateString("en-GB").replace(/\//g, '-');
    

    const min = 10000000000;
    const max = 99999999999;

    // debugger

    const TxnNumber = Math.floor(Math.random() * (max - min + 1) + min);

    console.log('TxnNumber1',TxnNumber)

    dispatchQuickQuote("TransactionIDHDFC", TxnNumber);

    console.log('TxnNumber2',TxnNumber)


    console.log('dobbbb',DOB)

    const data = {
      PanNumber: pannumber,
      ApiUniqueNumber: apiRequestQQ?.ApiUniqueNumber?.HDFC,
      MobileNumber: mobileNumber,
      PolicyIssueDate: new Date().toLocaleDateString("en-GB").toString(),
      CustomerType: apiRequestQQ?.CustomerType || "",
      DOB: DOB,
      IncorporationDate: DOB || "",
      tnxId: apiRequestQQ.TransactionIDHDFC,
      redirectURL : "https://www.example.com"
    };


    const serialize = (obj) => {
      const str = [];
      for (const p in obj) {
        if (obj.hasOwnProperty(p)) {
          str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
        }
      }
      return str.join("&");
    };

    const url = `kyc/hdfc-corpotrate-kyc?${serialize(data)}`;

    console.log('Constructed URL:', url);


      // debugger

      GetData(url).then((response) => {
        if (response.status === true) {
          setKYCSpinner(false);
          console.log("hdfc kyc response", response.data.kyc_id);
          if (response.data.iskycVerified == 1) {
            dispatchQuickQuote("KYC." + "HDFC", response.data.kyc_id);
            dispatchQuickQuote("FirstName", response.data?.name.split(" ")[0]);
            // dispatchQuickQuote("LastName", response.data?.name.split(" ")[1]);
            dispatchQuickQuote("PanNumber", response.data?.pan);
            dispatchQuickQuote(
              "Dob",
              moment(response.data?.dob, "DD/MM/YYYY").format("YYYY-MM-DD")
            );
            // alert('KYC Successfull')
            // setModalOpen(true)
            toast.success("KYC Successfull");
            toggle(activeTab + 2)
            // dispatchQuickQuote('MobileNumber',response.data?.mobile)
            reset({
              FirstName: response.data?.name.split(" ")[0],
              lastName: response.data?.name.split(" ")[1],
              Dob: moment(response.data?.dob, "DD/MM/YYYY").format("YYYY-MM-DD"),
              PanNumber: response.data.pan,
              // MobileNumber : response.data.mobile
            });
            if (redirect === true) {
              setTimeout(() => {
                toggle(activeTab + 1);
              }, 500);
            }
          } else {
            // alert("You have to complete your HDFC E-kyc first");
            // setModalOpen2(true)
            toast.error("You have to complete your HDFC E-kyc first");
            //   setTimeout(() => {
            //     window.open(response.data.redirect_link, "_blank");
            // }, 0);
          }
        } else {
          // alert("You have to complete your HDFC E-kyc first");
          toast.error("You have to complete your HDFC E-kyc first");
          // setModal2(true)
          window.open("https://www.example.com");
        }
      })

    }
  };
  const checkBajajKYCStatus = (docTypeCode, docNumber, dob, gender) => {
    let postData = {
      docTypeCode: docTypeCode,
      docNumber: docNumber,
      fieldValue: selectedPlan.ApiId,
      dob: dob,
      gender: gender == "Male" ? "M" : "F",
      customerType: apiRequestQQ.CustomerType === "INDIVIDUAL" ? "I" : "C",
      vehicleType: apiRequestQQ.VehicleType,
      IsVehicleNew: apiRequestQQ.IsVehicleNew,
      policyType: apiRequestQQ.NewPolicyType,
      name: apiRequestQQ?.FirstName + " " + apiRequestQQ?.LastName,
      ApiUniqueNumber: apiRequestQQ?.ApiUniqueNumber?.Bajaj,
    };
    PostDataWithToken("kyc/bajaj-kyc", postData).then((response) => {
      if (response.status === true) {
        const { ckycNumber, ckycStatus, errCode } = response.data;
        console.log("cyck", ckycNumber && errCode == "0");
        if (ckycNumber == null && ckycStatus == "NA") {
          let panImage = apiRequestQQ.PanImage;
          let kycData = {
            fieldValue: selectedPlan.ApiId,
            PanNumber: pannumber,
            PanImage: panImage,
          };
          PostDataWithToken("kyc/bajaj-kyc-document", kycData).then(
            (result) => {
              if (result.data.errorCode == "0") {
                toggle(activeTab + 1);
              } else {
                toast.error("Please recheck your information and try again");
              }
            }
          );
        } else if (ckycNumber && errCode == "0") {
          toggle(activeTab + 1);
        } else {
          toast.error("Please recheck your information and try again");
        }
      }
    });
  };

  const handleUploadPanFile = async (file) => {
    const base64Img = await toBase64(file[0]);
    setPan({ ...pan, image: base64Img, error: "" });
    dispatchQuickQuote("PanImage", base64Img.split(",")[1]);
  };

  const handleUploadAdharFile = async (file) => {
    // debugger;
    const base64Img = await toBase64(file[0]);
    setAdhar({ ...adhar, image: base64Img, error: "" });
    dispatchQuickQuote("AdharImage", base64Img.split(",")[1]);
  };

  const handleUploadAdharFileBack = async (file) => {
    // debugger;
    const base64Img = await toBase64(file[0]);
    setAdharBack({ ...adharBack, image: base64Img, error: "" });
    dispatchQuickQuote("AdharImageBack", base64Img.split(",")[1]);
  };

  useEffect(() => {
    console.log("AADHAR", adhar);
  }, [adhar]);

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      console.log("file", file);
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const handleChangePanValue = (value) => {
    setPan({ ...pan, number: value, error: "" });
  };

  const handleChangeMobilevalue = (value) => {
    setMobileNumber({ ...mobileNumber, number: value, error: "" });
  };

  const handleChangeAdharValue = (value) => {
    setPan({ ...adhar, number: value, error: "" });
  };

  const handleChangeCkycValue = (value) => {
    setCkyc({ ...ckyc, number: value, error: "" });
  };

  const FutureGeneralUserKycStatus = (proposalId) => {
    PostDataWithToken("kyc/future-general-kycStatus", {
      proposal_id: proposalId,
    }).then((response) => {
      // debugger;
      if (response.data) {
        console.log("responsedata in future", response.data);
        console.log(
          "responsedata final status in future",
          response.data.Final_Status
        );
        if (
          response.data.Final_Status === "3" ||
          response.data.Final_Status === "1"
        ) {
          sendSuccessInfo("Your Future KYC is successfully generated ");
          dispatchQuickQuote(
            "KYC." + "Future",
            response.data?.kyc_data?.CKYC?.result?.PERSONAL_DETAILS?.CKYC_NO
          );
          toggle(activeTab + 1);
          setDisabled("");
        } else {
          setDisabled("");
          alert("KYC is not generated please try again ");
        }
      }
    });
  };

  const FutureGeneralUserKyc = (
    pannumber,
    dob,
    full_name,
    gender,
    policyId
  ) => {
    setDisabled("disabled");
    let postData = {
      customer_type: apiRequestQQ.CustomerType === "INDIVIDUAL" ? "I" : "C",
      PanNumber: pannumber,
      dob: dob,
      full_name: full_name,
      gender: gender == "Male" ? "M" : "F",
      PolicyId: policyId,
      ApiUniqueNumber: apiRequestQQ.ApiUniqueNumber?.Future,
    };
    PostDataWithToken("kyc/future-general-kyc", postData).then((response) => {
      if (response.status === true) {
        let { result, proposal_id, url, req_id } = response.data;
        dispatchQuickQuote("ProposalId", proposal_id);
        // FutureGeneralUserKycStatus(proposal_id)
        if (result?.ckyc_number) {
          setDisabled("");
          sendSuccessInfo(
            "Your Future KYC is successfully generated " + result?.ckyc_number
          );
          dispatchQuickQuote("KYC." + "Future", result?.ckyc_number);
          toggle(activeTab + 1);
        } else if (proposal_id) {
          PostDataWithToken("kyc/future-general-kycStatus", {
            proposal_id: proposal_id,
          }).then((response) => {
            console.log("Response From Status API", response);
            if (response.data?.Final_Status !== "0") {
              console.log(
                "response.data?.Final_Status",
                response.data?.Final_Status
              );
              if (response.data?.Final_Status === "1" || "3") {
                sendSuccessInfo("Your Future KYC is successfully generated ");
                dispatchQuickQuote(
                  "KYC." + "Future",
                  response.data?.kyc_data?.CKYC?.result?.PERSONAL_DETAILS
                    ?.CKYC_NO
                );
                toggle(activeTab + 1);
                setDisabled("");
              } else {
                setDisabled("");
                alert("KYC is not generated please try again ");
              }
            } else if (isValidHttpUrl(url) === true) {
              setDisabled("");
              setFutureKyc(true);
              setFutureKYCData({
                kycno: req_id,
                proposalNo: proposal_id,
                url: url,
              });

              // window.open(url, "_blank");
            }
          });
        }
      }
    });
  };

  const checkRelianceKYCDetails = (pan, dob) => {
    setDisabled("disabled");
    let postData = {
      PanNumber: pan,
      DOB: moment(dob, "YYYY-MM-DD").format("DD-MM-YYYY"),
      ReturnURL: "https://expertcover.in/proposal",
    };
    PostDataWithToken("kyc/reliance-kyc", postData)
      .then((response) => {
        if (response.status === true) {
          setDisabled("");
          if (
            response.data.success == true &&
            response.data.KYC_Verified == "true"
          ) {
            let kycNumber =
              response.data?.kyc_data?.CKYC?.result?.PERSONAL_DETAILS?.CKYC_NO;
            dispatchQuickQuote("KYC." + "Reliance", kycNumber);
            apiRequestQQ.CustomerType == "INDIVIDUAL"
              ? toggle(activeTab + 1)
              : toggle(activeTab + 2);
          } else if (response.data.Endpoint_2_URL) {
            alert("You have to complete your Reliance E-kyc first");
            window.location.href = response.data.Endpoint_2_URL;
          } else {
            alert(response.data.message);
          }
        } else {
          setDisabled("");
        }
      })
      .catch((err) => setDisabled(""));
  };

  const checkKotakKycDetails = (pannumber, formData) => {
    setDisabled("disabled");
    let postData = {
      ApiUniqueNumber: apiRequestQQ?.ApiUniqueNumber?.Kotak,
      PanNumber: pannumber,
      DOB: formData.Dob,
      FirstName: formData.FirstName,
      LastName: formData.LastName,
      CINNumber: formData.CINNumber,
      ProposalNumber: "",
      requestId: apiRequestQQ.ApiId,
      ClientCallBackURL: "https://expertcover.in/proposal",
      Gender: formData.Gender === "Male" ? "M" : "F",
      CustomerType: apiRequestQQ.CustomerType === "COMPANY" ? "C" : "I",
    };
    PostDataWithToken("kyc/kotak-kyc", postData)
      .then((response) => {
        if (response.status === true) {
          if (
            response.data.KYCNumber &&
            response.data.KYCStatus == "CKYCSuccess"
          ) {
            setDisabled("");
            dispatchQuickQuote("KYC." + "Kotak", response.data.KYCNumber);
            const {
              KYCCorAdd1,
              KYCCorAdd2,
              KYCFirstName,
              KYCLastName,
              KYCMiddleName,
            } = response.data;
            KYCFirstName && dispatchQuickQuote("FirstName", KYCFirstName);
            KYCLastName && dispatchQuickQuote("LastName", KYCLastName);
            KYCLastName && dispatchQuickQuote("MiddleName", KYCMiddleName);
            // KYCCorAdd1 && dispatchQuickQuote("StreetNumber", KYCCorAdd1);
            // KYCCorAdd2 && dispatchQuickQuote("Street", KYCCorAdd2);
            verifyKotakApi(apiRequestQQ.ApiId, response.data.TokenId);
            apiRequestQQ.CustomerType == "INDIVIDUAL"
              ? toggle(activeTab + 1)
              : toggle(activeTab + 2);
          } else if (response.data.RequestURL) {
            alert("You have to complete your Kotak E-kyc first");
            window.location.href = response.data.RequestURL;
            setDisabled("");
          } else {
            setDisabled("");
            alert(
              response.data?.ExceptionErrorMsg
                ? response.data?.ExceptionErrorMsg
                : "Please try again"
            );
          }
        }
      })
      .catch((err) => setDisabled(""));
  };

  const verifyKotakApi = (requestid, tokenId) => {
    let postData = {
      requestid: requestid,
      tokenId: tokenId,
      ApiUniqueNumber: apiRequestQQ?.ApiUniqueNumber?.Kotak,
    };
    PostDataWithToken("kyc/verify-kotak-kyc", postData).then((response) => {
      if (response.status === true) {
        console.log(response.data);
      }
    });
  };

  //magma kyc

  const checkMagmaKycDetails = (data) => {
    // debugger;
    console.log("DATA FOR MAGMA KYC", data);
    setKYCSpinner(true);
    setHideBtn(true);
    const DOB = new Date(data?.Dob).toLocaleDateString("en-GB");
    setDisabled("disabled");
    let postData = {
      cKYCType: data?.docType,
      cKYCNumber: data?.PanNumber || data.ckycNumber,
      CustomerType: apiRequestQQ?.CustomerType || "",
      DOB: DOB,
      GSTNo: (data?.GSTNo && data?.GSTNo.toUpperCase()) || "",
      companyName: (data?.CompanyName && data?.CompanyName.toUpperCase()) || "",
      IncorporationDate: DOB || "",
      Gender:
        apiRequestQQ?.CustomerType === "INDIVIDUAL"
          ? data?.Gender.toUpperCase()
          : "",
      FullName:
        apiRequestQQ?.CustomerType === "INDIVIDUAL"
          ? data?.MiddleName
            ? data?.FirstName + " " + data?.MiddleName + " " + data?.LastName
            : data?.FirstName + " " + data?.LastName
          : "",
      BusinessSourceType: "A_AGENT",
      ApiUniqueNumber: apiRequestQQ?.ApiUniqueNumber?.Magma,
      PolicyIssueDate: new Date().toLocaleDateString("en-GB").toString(),
    };
    let aadharPostData = {
      DocumentName: "AADHAAR",
      DocumentType: "Image",
      DocumentBase64String: apiRequestQQ?.AdharImage,
      ApiUniqueNumber: apiRequestQQ?.ApiUniqueNumber?.Magma,
    };
    {
      data?.docType === "AADHAAR"
        ? PostDataWithToken("kyc/magma-aadhar-kyc", aadharPostData).then(
            (response) => {
              // debugger
              console.log("RESPONSE", response);
              if (
                response?.status === true &&
                response?.data?.ServiceResult === "Failure"
              ) {
                console.log("response in magma kyc", response.data);
                // setKycObj(response?.data?.OutputResult);
                // toggle(activeTab + 1);
                // alert("KYC Failed! You have to proceed with Aadhar based KYC");
                // setModalOpen4(true)
                toast.error("KYC Failed");
                setMagmaAadharKyc(true);
                setKYCSpinner(false);
                setHideBtn(false)
              } else if (
                response?.status === true &&
                response?.data?.ServiceResult === "Success"
              ) {
                // setModalOpen(true)
                toast.success("KYC Successfull");
                setKYCSpinner(false);
                setHideBtn(false);
                setKycObj(response?.data?.OutputResult);
                toggle(activeTab + 1);
                // alert("KYC Failed! You have to proceed with Aadhar based KYC");
                setMagmaAadharKyc(true);
              } else if (
                response?.status === true &&
                response?.data?.ServiceResult === null
              ) {
                setKycObj(response?.data?.OutputResult);
                // alert("KYC Failed! Please Upload Valid AAdhar");
                // setModalOpen5(true)
                toast.error("KYC Failed ! Please Upload Valid Aadhar");
                setKYCSpinner(false);
                setHideBtn(false);
                dispatchQuickQuote("docType", "AADHAAR");
              }
            }
          )
        : PostDataWithToken("kyc/magma-kyc", postData).then((response) => {
            // debugger
            if (
              response?.status === true &&
              response?.data?.ServiceResult === "Success"
            ) {
              // setModalOpen(true)
              toast.success("KYC Successfull");
              console.log("response in magma kyc", response.data);
              setKycObj(response?.data?.OutputResult);
              // debugger
              // alert("KYC Successful");
              setMagmaAadharKyc(true);

              if (
                apiRequestQQ.AddOns.PersonalAccident === true &&
                apiRequestQQ?.CustomerType === "INDIVIDUAL"
              ) {
                toggle(activeTab + 1);
                setKYCSpinner(false);
                setHideBtn(false)
              } else if (apiRequestQQ?.CustomerType === "COMPANY") {
                toggle(activeTab + 2);
                setKYCSpinner(false);
                setHideBtn(false)
              } else {
                toggle(activeTab + 2);
                setKYCSpinner(false);
                setHideBtn(false)
              }

              // toggle(activeTab + 1);
              // setKYCSpinner(false)
            } else if (
              response?.status === true &&
              response?.data?.ServiceResult === "Failure"
            ) {
              // setModalOpen3(true)
              toast.error("KYC Failed");
              toast.error("Proceed With Aadhar based KYC");
              setKYCSpinner(false);
              setHideBtn(false)
              setMagmaAadharKyc(true);
              // dispatchQuickQuote("docType","AADHAAR")
            }
          });
    }
  };

  // Royal Sundram API
  const royalSundramKYCDetails = (fname, lname, pan, dob, redirect) => {
    setDisabled("disabled");
    let postData = {
      FirstName: fname,
      LastName: lname,
      PanNumber: pan,
      Dob: dob,
      QuoteId: apiRequestQQ.ApiId,
      CustomerType: apiRequestQQ.CustomerType === "INDIVIDUAL" ? "I" : "C",
      ReturnURL: "https://expertcover.in/proposal",
    };
    PostDataWithToken("kyc/royal-kyc-status", {
      QuoteId: apiRequestQQ.ApiId,
    }).then((response) => {
      if (response.data.applicationStatus != "Success") {
        PostDataWithToken("kyc/royal-sundram-kyc", postData)
          .then((response) => {
            if (response.status === true) {
              if (response.data.url != null) {
                setDisabled("");
                alert("You have to complete your Royal Sundram E-kyc first");
                window.location.href = response.data.url;
              } else if (response.data.kycStatus === true) {
                const { address1, address2, city, state, pinCode } =
                  response.data.kycDetails;
                let gender =
                  response.data?.kycDetails.gender == "M" ? "Male" : "Female";
                console.log(
                  "response.data?.kycDetails.dob",
                  response.data?.kycDetails.dob
                );
                sendSuccessInfo(
                  "Your Royal Sundram KYC is successfully generated " +
                    response.data?.kycDetails.ckycNo
                );
                dispatchQuickQuote(
                  "KYC." + "Royal",
                  response.data?.kycDetails.ckycNo
                );
                dispatchQuickQuote(
                  "KYC." + "RoyalRefNo",
                  response.data?.kycRefNo
                );
                dispatchQuickQuote(
                  "FirstName",
                  response.data?.kycDetails.firstName
                );
                dispatchQuickQuote(
                  "LastName",
                  response.data?.kycDetails.lastName
                );
                dispatchQuickQuote(
                  "MiddleName",
                  response.data?.kycDetails.middleName
                );
                dispatchQuickQuote("Gender", gender);
                dispatchQuickQuote(
                  "Dob",
                  moment(
                    response.data?.kycDetails.dateOfBirth,
                    "DD-MM-YYYY"
                  ).format("YYYY-MM-DD")
                );

                reset({
                  FirstName: response.data?.kycDetails.firstName,
                  lastName: response.data?.kycDetails.lastName,
                  Dob: moment(
                    response.data?.kycDetails.dob,
                    "DD-MM-YYYY"
                  ).format("YYYY-MM-DD"),
                  Gender: gender,
                });

                dispatchQuickQuote(
                  "PermanentAddress",
                  JSON.stringify({
                    address1: address1,
                    address2: address2,
                    city: city,
                    state: state,
                    pinCode: pinCode,
                  })
                );

                redirect === true && apiRequestQQ.CustomerType == "INDIVIDUAL"
                  ? toggle(activeTab + 1)
                  : redirect === true && toggle(activeTab + 2);
                setDisabled("");
              } else {
                setDisabled("");
                alert("KYC not done please retry again");
              }
            }
          })
          .catch((err) => {
            console.log("err", err);
            setDisabled("");
          });
      } else if (response.data.applicationStatus == "Success") {
        const { address1, address2, city, state, pinCode } = response.data;
        let gender = response.data?.gender == "M" ? "Male" : "Female";
        dispatchQuickQuote("KYC." + "Royal", response.data.ckycNo);
        dispatchQuickQuote("KYC." + "RoyalRefNo", response.data?.kycRefNo);
        dispatchQuickQuote("FirstName", response.data.firstName);
        dispatchQuickQuote("LastName", response.data.lastName);
        dispatchQuickQuote("MiddleName", response.data.middleName);
        dispatchQuickQuote(
          "Dob",
          moment(response.data?.dob, "DD-MM-YYYY").format("YYYY-MM-DD")
        );
        dispatchQuickQuote("Gender", gender);
        dispatchQuickQuote(
          "PermanentAddress",
          JSON.stringify({
            address1: address1,
            address2: address2,
            city: city,
            state: state,
            pinCode: pinCode,
          })
        );
        reset({
          FirstName: response.data?.firstName,
          lastName: response.data?.lastName,
          Dob: moment(response.data?.dob, "DD-MM-YYYY").format("YYYY-MM-DD"),
          Gender: gender,
        });
        redirect === true && apiRequestQQ.CustomerType == "INDIVIDUAL"
          ? toggle(activeTab + 1)
          : redirect === true && toggle(activeTab + 2);
        setDisabled("");
      } else {
        setDisabled("");
      }
    });
  };

  // zuno api request
  const getZunoKyc = (data, Pincode) => {

    setKYCSpinner(true)

    let postData = {
      CustomerType: apiRequestQQ.CustomerType === "INDIVIDUAL" ? "I" : "C",
      ReturnURL: "https://expertcover.in/proposal",
      FirstName: data.FirstName,
      LastName: data.LastName,
      Email: data.Email,
      PanNumber: data.PanNumber,
      PinCode: Pincode,
      MobileNo: data.MobileNumber,
      Gender: data.Gender,
      Dob: data.Dob,
      ApiUniqueNumber : apiRequestQQ.ApiUniqueNumber.Zuno

    };

    let aadharPostData = {
      DocumentBase64String: apiRequestQQ?.AdharImage,
      DocumentBase64StringBack: apiRequestQQ.AdharImageBack,
      LeadID : apiRequestQQ.LeadID,
      PanNumber: data.PanNumber,
      ApiUniqueNumber : apiRequestQQ.ApiUniqueNumber.Zuno

    };

    {
      data?.docType === "AADHAAR"

        ? PostDataWithToken("kyc/zuno-Kyc-Ovd", aadharPostData).then(
            (response) => {

              setKYCSpinner(true)

              if (response.status === true) {
                dispatchQuickQuote("KYCReqNo",response.data.VISoF_KYC_Req_No)
                dispatchQuickQuote("KYCNo",response.data.IC_KYC_No)
                if (response.data.data.IC_KYC_REG_URL) {
                  // window.location.href = response.data.data.IC_KYC_REG_URL;
                 
                } else {
                  dispatchQuickQuote("KYC." + "Zuno", response.data.data.IC_KYC_No);
                  dispatchQuickQuote(
                    "KYC." + "ZunoRef",
                    response.data.data.VISoF_KYC_Req_No
                  );
                  apiRequestQQ.CustomerType == "INDIVIDUAL"
                    ? toggle(activeTab + 1)
                    : toggle(activeTab + 2);
                }
                toast.success('KYC Successful')
              }

              else{setKYCSpinner(false)}
              toast.error('KYC Failed')

            }
            
          )
        : PostDataWithToken("kyc/zuno-kyc", postData).then((response) => {
          setZunoResponse(true)
          setKYCSpinner(true)
          if (response.status === true && response.data.data.KYC_Status === "1") {
          //  debugger
                  dispatchQuickQuote("KYCReqNo",response.data.data.VISoF_KYC_Req_No)
                  dispatchQuickQuote("KYCNo",response.data.data.IC_KYC_No)

                  console.log("KYCReqNodfgerger",response.data.data.VISoF_KYC_Req_No)
                  apiRequestQQ.CustomerType == "INDIVIDUAL"
                ? toggle(activeTab + 1)
                : toggle(activeTab + 2);

                setKYCSpinner(false)

                  // console.log("KYCNo",response.data.IC_KYC_No)
            if (response.data.data.IC_KYC_REG_URL) {
              
              // window.location.href = response.data.data.IC_KYC_REG_URL;
            } else {
             
              dispatchQuickQuote("KYC." + "Zuno", response.data.data.IC_KYC_No);
              dispatchQuickQuote(
                "KYC." + "ZunoRef",
                response.data.data.VISoF_KYC_Req_No
              );
              
            }

            setKYCSpinner(false)

            toast.success('KYC Successful')

          }
        else {
          setKYCSpinner(false)
          toast.error('KYC Failed')


          dispatchQuickQuote("LeadID",response.data.data.VISoF_KYC_Req_No)
          
        }});
    } 
  };

  const getLibertyKYC = (data, redirect) => {
    let reqData = {
      Liberty: apiRequestQQ.KYC.Liberty,
      LibertyRefNo: apiRequestQQ.KYC.LibertyRefNo,
    };
    PostDataWithToken("kyc/liberty-kyc-search", reqData).then((response) => {
      if (response.status === true) {
        if (response.data.KYC_Status != 0) {
          const {
            Aggregator_KYC_Req_No,
            DOB,
            FirstName,
            Gender,
            MiddleName,
            LastName,
            IC_KYC_No,
            KYC_Status,
            ProposerPAN,
          } = response.data;
          if (KYC_Status == 1) {
            dispatchQuickQuote("KYC." + "Liberty", IC_KYC_No);
            dispatchQuickQuote("KYC." + "LibertyRefNo", Aggregator_KYC_Req_No);
            dispatchQuickQuote("FirstName", FirstName);
            dispatchQuickQuote("LastName", LastName);
            dispatchQuickQuote("MiddleName", MiddleName);
            dispatchQuickQuote("Gender", Gender == "M" ? "Male" : "Female");
            dispatchQuickQuote(
              "Dob",
              moment(DOB, "MM/DD/YYYY").format("YYYY-MM-DD")
            );
            dispatchQuickQuote("PanNumber", ProposerPAN);
            reset({
              FirstName: FirstName,
              LastName: LastName,
              Dob: moment(DOB, "MM/DD/YYYY").format("YYYY-MM-DD"),
              Gender: Gender == "M" ? "Male" : "Female",
              PanNumber: ProposerPAN,
            });
            redirect === true && apiRequestQQ.CustomerType == "INDIVIDUAL"
              ? toggle(activeTab + 1)
              : redirect === true && toggle(activeTab + 2);
          }
        } else if (redirect == true) {
          let postData = {
            CustomerType: apiRequestQQ.CustomerType,
            Dob: data?.Dob,
            FirstName: data?.FirstName,
            LastName: data?.LastName,
            MobileNo: data?.MobileNumber,
            Email: data?.Email,
            PanNumber: data?.PanNumber,
            Gender: data?.Gender,
            ReturnURL: "https://expertcover.in/proposal",
          };
          PostDataWithToken("kyc/liberty-kyc", postData).then((response) => {
            if (response.status === true) {
              const {
                Aggregator_KYC_Req_No,
                DOB,
                FirstName,
                Gender,
                MiddleName,
                LastName,
                IC_KYC_No,
                ProposerPAN,
              } = response.data;
              if (
                response.data.IC_KYC_REG_URL &&
                response.data.KYC_Status != "1"
              ) {
                setLibertyKYC(true);
                dispatchQuickQuote("KYC." + "Liberty", IC_KYC_No);
                dispatchQuickQuote(
                  "KYC." + "LibertyRefNo",
                  Aggregator_KYC_Req_No
                );
                setLibertyData({
                  kycno: response.data.IC_KYC_No,
                  proposalNo: response.data.Aggregator_KYC_Req_No,
                  url: response.data.IC_KYC_REG_URL,
                });
              } else if (response.data.KYC_Status != 0) {
                dispatchQuickQuote("KYC." + "Liberty", IC_KYC_No);
                dispatchQuickQuote(
                  "KYC." + "LibertyRefNo",
                  Aggregator_KYC_Req_No
                );
                dispatchQuickQuote("FirstName", FirstName);
                dispatchQuickQuote("LastName", LastName);
                dispatchQuickQuote("MiddleName", MiddleName);
                dispatchQuickQuote("Gender", Gender == "M" ? "Male" : "Female");
                dispatchQuickQuote(
                  "Dob",
                  moment(DOB, "MM/DD/YYYY").format("YYYY-MM-DD")
                );
                dispatchQuickQuote("PanNumber", ProposerPAN);
                reset({
                  FirstName: FirstName,
                  lastName: LastName,
                  Dob: moment(DOB, "MM/DD/YYYY").format("YYYY-MM-DD"),
                  Gender: Gender == "M" ? "Male" : "Female",
                  PanNumber: ProposerPAN,
                });
                redirect === true && apiRequestQQ.CustomerType == "INDIVIDUAL"
                  ? toggle(activeTab + 1)
                  : redirect === true && toggle(activeTab + 2);
              }
            }
          });
        }
      }
    });
  };

  useEffect(() => {
    if (window?.location?.search) {
      // const verifyKotakApi = (requestid, tokenId) => {
      let postData = {
        requestid: apiRequestQQ.ApiId,
        ApiUniqueNumber: apiRequestQQ?.ApiUniqueNumber?.Kotak,
        tokenId: window.location.search,
      };
      PostDataWithToken("kyc/verify-kotak-kyc", postData).then((response) => {
        if (response.status === true) {
          console.log(response.data);
          apiRequestQQ.CustomerType == "INDIVIDUAL"
            ? toggle(activeTab + 1)
            : toggle(activeTab + 2);
        }
      });
      // };
    }
  }, []);

  return (
    <div className="">
      {futureKYC ? (
        <FutureGeneralKYCRedirect
          kycno={futureKYCData?.proposalNo}
          proposalNo={futureKYCData?.proposalNo}
          url={futureKYCData?.url}
        />
      ) : libertyKYC == true ? (
        <LibertyKYCRedirect
          kycno={libertyData?.kycno}
          proposalNo={libertyData?.proposalNo}
          url={libertyData?.url}
        />
      ) : (
        <form ref={formRef} onSubmit={handleSubmit(handleSaveUser)}>
          {/* <p className='mb-0 fs-1 fw-bold'>Personal Details</p> */}
          <div className="posformbox  mx-auto">
            <ul className="twoinsuranceform row m-0 ">
              {apiRequestQQ.CustomerType === "INDIVIDUAL" && (
                <div className="col-lg-6 col-md-6 mt-0 ps-lg-0 pb-4">
                  <li className="position-relative mb-1">
                    <div className="form-floating">
                      <select
                        {...register("Salutation", {
                          required: "Salutation is required",
                        })}
                        className="form-select fs-3"
                        id="gen"
                      >
                        {apiRequestQQ.CustomerType === "INDIVIDUAL" ? (
                          <>
                            <option value="Mr" selected>
                              MR.
                            </option>
                            <option value="Miss">MISS.</option>
                            <option value="Mrs">MRS.</option>
                          </>
                        ) : (
                          <option value="M/S" selected>
                            M/S
                          </option>
                        )}
                      </select>
                      <label htmlFor="gen">
                        Salutation
                        <span className="text-danger">*</span>
                      </label>
                    </div>
                    <p className="m-0 f-error fs-4 mx-2 fs-4">
                      {errors?.Salutation?.message}
                    </p>
                  </li>
                </div>
              )}

              {apiRequestQQ.CustomerType === "INDIVIDUAL" && (
                <div className="col-lg-6 col-md-6 mt-0 ps-lg-0 pb-4">
                  <li className="position-relative mb-1">
                    <div className="form-floating">
                      <input
                        type="text"
                        className="form-control fs-3"
                        maxLength={30}
                        {...register("FirstName", {
                          required: "First Name is required",
                          pattern: {
                            value: /^[A-Za-z ]+$/, // This pattern ensures only alphabetic characters are allowed.
                            message: "Name should contain only alphabets",
                          },
                          maxLength: {
                            value: 29,
                            message: "Maximum character limit is 30",
                          },
                        })}
                        readOnly={
                          apiRequestQQ.KYC.RoyalRefNo != "" &&
                          selectedPlan.Api_name == "Royal"
                            ? true
                            : false
                        }
                        id="fsa1"
                        placeholder="Owner Nam as m"
                      />
                      <label htmlFor="fsa1">
                        First Name
                        <span className="text-danger">*</span>
                      </label>
                    </div>
                    <p
                      className="m-0 f-error fs-4 mx-2"
                      style={{ top: "6.2rem" }}
                    >
                      {errors?.FirstName?.message}
                    </p>
                  </li>
                </div>
              )}

              {apiRequestQQ.CustomerType === "INDIVIDUAL" && (
                <div className="col-lg-6 col-md-6 mt-0 ps-lg-0 pb-4">
                  <li className="position-relative mb-1">
                    <div className="form-floating">
                      <input
                        type="text"
                        className="form-control fs-3"
                        {...register("MiddleName", {
                          // required: "First Name is required",
                        })}
                        readOnly={
                          apiRequestQQ.KYC.RoyalRefNo != "" &&
                          selectedPlan.Api_name == "Royal"
                            ? true
                            : false
                        }
                        id="fsa1"
                        placeholder="Owner Nam as m"
                      />
                      <label htmlFor="fsa1">Middle Name</label>
                    </div>
                    {/* <p
                    className="m-0 f-error fs-4 mx-2"
                    style={{ top: "6.2rem" }}
                  >
                    {errors?.FirstName?.message}
                  </p> */}
                  </li>
                </div>
              )}

              {apiRequestQQ.CustomerType === "INDIVIDUAL" && (
                <div className="col-lg-6 col-md-6 mt-0 ps-lg-0 pb-4">
                  <li className="position-relative mb-1">
                    <div className="form-floating ">
                      <input
                        type="text"
                        className="form-control fs-3"
                        {...register("LastName", {
                          // required: "Last Name is required",
                        })}
                        readOnly={
                          apiRequestQQ.KYC.RoyalRefNo != "" &&
                          selectedPlan.Api_name == "Royal"
                            ? true
                            : false
                        }
                        id="fsa1s"
                        placeholder="Owner Nam as m"
                      />
                      <label htmlFor="fsa1s">
                        Last Name
                        <span className="text-danger">*</span>
                      </label>
                    </div>
                    <p className="m-0 f-error fs-4 mx-2">
                      {errors?.LastName?.message}
                    </p>
                  </li>
                </div>
              )}

              {apiRequestQQ.CustomerType === "COMPANY" && (
                <div className="col-lg-6 col-md-6 mt-0 ps-lg-0 pb-4">
                  <li className="position-relative mb-1">
                    <div className="form-floating">
                      <input
                        type="text"
                        className="form-control fs-3"
                        {...register("CompanyName", {
                          required: "Company Name is required",
                        })}
                        id="fsa1"
                        placeholder="Owner Nam as m"
                      />
                      <label htmlFor="fsa1">
                        Enter Company Name
                        <span className="text-danger">*</span>
                      </label>
                    </div>
                    <p
                      className="m-0 f-error fs-4 mx-2"
                      style={{ top: "6.2rem" }}
                    >
                      {errors?.CompanyName?.message}
                    </p>
                  </li>
                </div>
              )}

              {/* {apiRequestQQ.CustomerType === "COMPANY" && <div className="col-lg-6 col-md-6 mt-0 ps-lg-0 pb-4">
                <li className="position-relative mb-1">
                  <div className="form-floating">
                    <input
                      type="text"
                      className="form-control fs-3"
                      {...register("GSTNo", {
                        required: "GST Number is required",
                      })}
                      id="fsa1"
                      placeholder="Owner Nam as m"
                    />
                    <label htmlFor="fsa1">
                      Enter GST Number
                      <span className="text-danger">*</span>
                    </label>
                  </div>
                  <p
                    className="m-0 f-error fs-4 mx-2"
                    style={{ top: "6.2rem" }}
                  >
                    {errors?.GSTNo?.message}
                  </p>
                </li>
              </div>} */}

              {apiRequestQQ.CustomerType === "COMPANY" && (
                <div className="col-lg-6 col-md-6 mt-0 ps-lg-0 pb-4">
                  <li className="position-relative mb-1">
                    <div className="form-floating ">
                      <input
                        type="text"
                        className="form-control fs-3"
                        {...register("GSTNo", {
                          required: "GST Number is required",
                        })}
                        id="fsa"
                        placeholder="Company GST Number"
                      />
                      <label htmlFor="fsa">
                        Company GST No.
                        <span className="text-danger">*</span>
                      </label>
                    </div>
                    <p
                      className="m-0 f-error fs-4 mx-2"
                      style={{ top: "6.2rem" }}
                    >
                      {errors?.GSTNo?.message}
                    </p>
                  </li>
                </div>
              )}

              {apiRequestQQ.CustomerType !== "COMPANY" && (
                <div className="col-lg-6 col-md-6 mt-0 ps-lg-0 pb-4">
                  <li className="position-relative mb-1">
                    <div className="form-floating">
                      <select
                        {...register("Gender", {
                          required: "Gender is required",
                        })}
                        readOnly={
                          apiRequestQQ.KYC.RoyalRefNo != "" &&
                          selectedPlan.Api_name == "Royal"
                            ? true
                            : false
                        }
                        className="form-select fs-3"
                        id="gen"
                      >
                        <option className="d-none" selected value="">
                          Select Gender
                        </option>
                        <option value="Male">Male</option>
                        <option value="Female">Female</option>
                      </select>
                      <label htmlFor="gen">
                        Gender
                        <span className="text-danger">*</span>
                      </label>
                    </div>
                    <p className="m-0 f-error fs-4 mx-2">
                      {errors?.Gender?.message}
                    </p>
                  </li>
                </div>
              )}

              <div className="col-lg-6 col-md-6 mt-0 ps-lg-0 pb-4">
                <li className="position-relative mb-1">
                  <div className="form-floating ">
                    <input
                      type="date"
                      className="datepicker form-control fs-3"
                      readOnly={
                        apiRequestQQ.KYC.RoyalRefNo != "" &&
                        selectedPlan.Api_name == "Royal"
                          ? true
                          : false
                      }
                      {...register("Dob", {
                        required:
                          selectedPlan.Api_name == "Kotak" &&
                          apiRequestQQ.CustomerType === "COMPANY"
                            ? "Incorporation Year is required"
                            : "Field is required",
                      })}
                      max={
                        apiRequestQQ.CustomerType === "COMPANY"
                          ? moment().format("YYYY-MM-DD")
                          : moment().subtract("18", "year").format("YYYY-MM-DD")
                      }
                      id="dob"
                      placeholder="Date of birth"
                    />
                    <label htmlFor="dob">
                      {apiRequestQQ.CustomerType === "COMPANY"
                        ? "Incorporation Year"
                        : "Date of Birth"}
                      <span className="text-danger">*</span>
                    </label>
                  </div>
                  <p
                    className="m-0 f-error fs-4 mx-2"
                    style={{ top: "6.2rem" }}
                  >
                    {errors?.Dob?.message}
                  </p>
                </li>
              </div>

              <div className="col-lg-6 col-md-6 mt-0 ps-lg-0 pb-4">
                <li className="position-relative mb-1">
                  <div className="form-floating ">
                    <input
                      type="text"
                      {...register("MobileNumber", {
                        required: "Mobile Number is required",
                        pattern: {
                          value:
                            /((\+*)((0[ -]*)*|((91 )*))((\d{12})+|(\d{10})+))|\d{5}([- ]*)\d{6}/,
                          message: "Invalid Mobile Number",
                        },
                      })}
                      onChange={(e) => handleChangeMobilevalue(e.target.value)}
                      className="form-control fs-3"
                      id="fsaqw"
                      placeholder="Mobile Number"
                      maxLength="10"
                    />
                    <label htmlFor="fsaqw">
                      Mobile Number
                      <span className="text-danger">*</span>
                    </label>
                  </div>
                  <p
                    className="m-0 f-error fs-4 mx-2"
                    style={{ top: "6.2rem" }}
                  >
                    {errors?.MobileNumber?.message}
                  </p>
                </li>
              </div>

              <div className="col-lg-6 col-md-6 mt-0 ps-lg-0 pb-4">
                <li className="position-relative mb-1">
                  <div className="form-floating ">
                    <input
                      type="email"
                      className="form-control fs-3"
                      {...register("Email", {
                        required: "Email is required",
                        pattern: {
                          value:
                            /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/,
                          message: "Invalid Email",
                        },
                      })}
                      id="fsaemail"
                      placeholder="Enter email"
                    />
                    <label htmlFor="fsaemail">
                      Email Address
                      <span className="text-danger">*</span>
                    </label>
                  </div>
                  <p
                    className="m-0 f-error fs-4 mx-2"
                    style={{ position: "relative", bottom: "1.7rem" }}
                  >
                    {errors?.Email?.message}
                  </p>
                </li>
              </div>

              {apiRequestQQ.CustomerType !== "COMPANY" && (
                <div className="col-lg-6 col-md-6 mt-0 ps-lg-0 pb-4">
                  <li className="position-relative mb-1">
                    <div className="form-floating ">
                      <input
                        type="text"
                        className="form-control fs-3"
                        {...register("StreetNumber", {
                          required: "Street/House Number is required",
                        })}
                        id="fsa1m"
                        placeholder="Owner Nam as m"
                      />
                      <label htmlFor="fsa1m">
                        House/Street Number
                        <span className="text-danger">*</span>
                      </label>
                    </div>
                    <p
                      className="f-error fs-4 mx-2"
                      style={{ position: "relative", bottom: "2rem" }}
                    >
                      {errors?.StreetNumber?.message}
                    </p>
                  </li>
                </div>
              )}

              {apiRequestQQ.CustomerType === "COMPANY" && (
                <div className="col-lg-6 col-md-6 mt-0 ps-lg-0 pb-4">
                  <li className="position-relative mb-1">
                    <div className="form-floating ">
                      <input
                        type="text"
                        className="form-control fs-3"
                        {...register("StreetNumber", {
                          required: "Address1 is required",
                        })}
                        id="fsa1m"
                        placeholder="Owner Nam as m"
                      />
                      <label htmlFor="fsa1m">
                        Address1
                        <span className="text-danger">*</span>
                      </label>
                    </div>
                    <p
                      className="f-error fs-4 mx-2"
                      style={{ position: "relative", bottom: "2rem" }}
                    >
                      {errors?.StreetNumber?.message}
                    </p>
                  </li>
                </div>
              )}

              {apiRequestQQ.CustomerType === "COMPANY" && (
                <div className="col-lg-6 col-md-6 mt-0 ps-lg-0 pb-4">
                  <li className="position-relative mb-1">
                    <div className="form-floating ">
                      <input
                        type="text"
                        className="form-control fs-3"
                        {...register("Street", {
                          required: "Address 2 is required",
                        })}
                        id="fsa1m"
                        placeholder="Owner Nam as m"
                      />
                      <label htmlFor="fsa1m">
                        Address2
                        <span className="text-danger">*</span>
                      </label>
                    </div>
                    <p
                      className="f-error fs-4 mx-2"
                      style={{ position: "relative", bottom: "2rem" }}
                    >
                      {errors?.Street?.message}
                    </p>
                  </li>
                </div>
              )}

              {apiRequestQQ.CustomerType !== "COMPANY" && (
                <div className="col-lg-6 col-md-6 mt-0 ps-lg-0 pb-4">
                  <li className="position-relative mb-1">
                    <div className="form-floating ">
                      <input
                        type="text"
                        className="form-control fs-3"
                        {...register("Street", {
                          required: "Street Name is required",
                        })}
                        id="fsa1ss"
                        placeholder="Owner Nam as m"
                      />
                      <label htmlFor="fsa1ss">
                        Street
                        <span className="text-danger">*</span>
                      </label>
                    </div>
                    <p
                      className="f-error fs-4 mx-2"
                      style={{ position: "relative", bottom: "2rem" }}
                    >
                      {errors?.Street?.message}
                    </p>
                  </li>
                </div>
              )}

              {apiRequestQQ.CustomerType !== "COMPANY" && (
                <div className="col-lg-6 col-md-6 mt-0 ps-lg-0 pb-4">
                  <li className="position-relative mb-1">
                    <div className="form-floating ">
                      <input
                        type="text"
                        className="form-control fs-3"
                        {...register("Area", {
                          required: "Area Name is required",
                        })}
                        id="fsa1ar"
                        placeholder="Owner Nam as m"
                      />
                      <label htmlFor="fsa1ar">
                        Area
                        <span className="text-danger">*</span>
                      </label>
                    </div>
                    <p
                      className="f-error fs-4 mx-2"
                      style={{ position: "relative", bottom: "2rem" }}
                    >
                      {errors?.Area?.message}
                    </p>
                  </li>
                </div>
              )}

              <div className="col-lg-6 col-md-6 mt-0 ps-lg-0 pb-4">
                <li className="position-relative mb-1">
                  <div className="">
                    <ReactSelect
                      options={pincodeData}
                      onChange={(val) => handleSelectPincode(val)}
                      placeholder="Select area pincode"
                      defaultValue={apiRequestQQ.Pincode}
                    />
                  </div>
                </li>
              </div>

              <div className="col-lg-6 col-md-6 mt-0 ps-lg-0 pb-4">
                <li className="position-relative mb-1">
                  <div className="form-floating ">
                    <input
                      list="browsers"
                      {...register("State", {
                        required: "State is required",
                      })}
                      onChange={(e) => handleSelectState(e.target.value)}
                      className="form-control fs-3"
                      name="myBrowser"
                    />
                    <label htmlFor="fsa">
                      State
                      <span className="text-danger">*</span>
                    </label>
                    <datalist id="browsers">
                      {stateData &&
                        stateData.length &&
                        stateData.map((item, index) => {
                          return (
                            <option
                              onClick={() => handleSelectState(item)}
                              key={index}
                            >
                              {item?.PC_CODE}
                            </option>
                          );
                        })}
                    </datalist>
                  </div>
                  <p
                    className="f-error fs-4 mx-2"
                    style={{ position: "relative", bottom: "2rem" }}
                  >
                    {errors?.State?.message}
                  </p>
                </li>
              </div>

              <div className="col-lg-6 col-md-6 mt-0 ps-lg-0 pb-4">
                <li className="position-relative mb-1">
                  <div className="form-floating ">
                    <input
                      type="text"
                      className="form-control fs-3"
                      {...register("City", {
                        required: "City Name is required",
                      })}
                      id="fsa"
                      placeholder="communication address"
                    />
                    <label htmlFor="fsa">
                      City
                      <span className="text-danger">*</span>
                    </label>
                  </div>
                  <p
                    className="f-error fs-4 mx-2"
                    style={{ position: "relative", bottom: "2rem" }}
                  >
                    {errors?.City?.message}
                  </p>
                </li>
              </div>

              {martial === "Married" && (<div className="col-lg-6 col-md-6 mt-0 ps-lg-0 pb-4">
                  <li className="position-relative mb-1">
                    <div className="form-floating">
                      <input
                        type="text"
                        className="form-control fs-3"
                        maxLength={30}
                        {...register("Spouse", {
                          required: "Spouse Name is required",
                          pattern: {
                            value: /^[A-Za-z ]+$/, // This pattern ensures only alphabetic characters are allowed.
                            message: "Name should contain only alphabets",
                          },
                          maxLength: {
                            value: 29,
                            message: "Maximum character limit is 30",
                          },
                        })}
                        readOnly={
                          apiRequestQQ.KYC.RoyalRefNo != "" &&
                          selectedPlan.Api_name == "Royal"
                            ? true
                            : false
                        }
                        id="fsa1"
                        placeholder="Owner Nam as m"
                      />
                      <label htmlFor="fsa1">
                        Spouse Name
                        <span className="text-danger">*</span>
                      </label>
                    </div>
                    <p
                      className="m-0 f-error fs-4 mx-2"
                      style={{ top: "6.2rem" }}
                    >
                      {errors?.Spouse?.message}
                    </p>
                  </li>
                </div>) }

                {martial === "Single" && (<div className="col-lg-6 col-md-6 mt-0 ps-lg-0 pb-4">
                  <li className="position-relative mb-1">
                    <div className="form-floating">
                      <input
                        type="text"
                        className="form-control fs-3"
                        maxLength={30}
                        {...register("Father", {
                          required: "Father Name is required",
                          pattern: {
                            value: /^[A-Za-z ]+$/, // This pattern ensures only alphabetic characters are allowed.
                            message: "Name should contain only alphabets",
                          },
                          maxLength: {
                            value: 29,
                            message: "Maximum character limit is 30",
                          },
                        })}
                        readOnly={
                          apiRequestQQ.KYC.RoyalRefNo != "" &&
                          selectedPlan.Api_name == "Royal"
                            ? true
                            : false
                        }
                        id="fsa1"
                        placeholder="Owner Nam as m"
                      />
                      <label htmlFor="fsa1">
                        Father Name
                        <span className="text-danger">*</span>
                      </label>
                    </div>
                    <p
                      className="m-0 f-error fs-4 mx-2"
                      style={{ top: "6.2rem" }}
                    >
                      {errors?.Father?.message}
                    </p>
                  </li>
                </div>)}

              <>
                {selectedPlan.Api_name == "Shriram" && (
                  <div className="col-lg-6 col-md-6 mt-0 ps-lg-0 pb-4">
                    <li className="position-relative mb-1">
                      <div className="form-floating">
                        <input
                          type="text"
                          className="form-control fs-3"
                          id="fsa1"
                          {...register("KycNumber")}
                          placeholder="KYC Number"
                        />
                        <label htmlFor="fsa1">
                          KYC Number
                          <span className="text-danger">*</span>
                        </label>
                      </div>
                      <p className="m-0 f-error fs-4 mx-2">
                        {errors?.KycNumber?.message}
                      </p>
                    </li>
                  </div>
                )}
                {selectedPlan.Api_name == "Kotak" &&
                  apiRequestQQ.CustomerType === "COMPANY" && (
                    <div className="col-lg-6 col-md-6 mt-0 ps-lg-0 pb-4">
                      <li className="position-relative mb-1">
                        <div className="form-floating ">
                          <input
                            type="text"
                            {...register("CINNumber", {
                              required: "CIN Number is required",
                            })}
                            className="form-control fs-3"
                            id="fsaqw13"
                            placeholder="CIN Number"
                          />
                          <label htmlFor="fsaqw13">
                            CIN Number
                            <span className="text-danger">*</span>
                          </label>
                        </div>
                        <p className="m-0 f-error fs-4 mx-2">
                          {errors?.CINNumber?.message}
                        </p>
                      </li>
                    </div>
                  )}

                {/* Doc Type */}

                {((selectedPlan.Api_name === "Zuno" && zunoResponse === false) || (selectedPlan.Api_name === "Tata AIG General Insurance Co. Ltd." || selectedPlan.Api_name === "ICICI Lombard General Insurance Co. Ltd." || selectedPlan.Api_name === "Bajaj Allianz General Insurance Co. Ltd."))  && <div className="d-flex justify-content-between px-0">
                    <div className="col-lg-6 col-md-6 mt-0 ps-lg-0 pb-4">
                      <li className="position-relative mb-1">
                        <div className="form-floating">
                          <input
                            type="text"
                            className="form-control fs-3 text-uppercase"
                            id="fsa1"
                            maxLength={10}
                            {...register("PanNumber", {
                              pattern: {
                                value: /^[A-Za-z]{5}[0-9]{4}[A-Za-z]{1}/,
                                message: "Invalid Pan Number",
                              },
                              required: "Pan Number is required",
                            })}
                            readOnly={
                              apiRequestQQ.KYC.RoyalRefNo != "" &&
                              selectedPlan.Api_name == "Royal"
                                ? true
                                : false
                            }
                            onChange={(e) =>
                              handleChangePanValue(e.target.value)
                            }
                            placeholder="Pan Car Number"
                          />
                          <label htmlFor="fsa1">Pan Card Number</label>
                        </div>
                        <p className="m-0 f-error fs-4 mx-2">
                          {errors?.PanNumber?.message}
                        </p>
                      </li>
                    </div>
                  </div>}

                {(zunoResponse === true || selectedPlan.Api_name === 'Magma' || selectedPlan.Api_name === 'bajaj' || selectedPlan.Api_name === 'digit' || selectedPlan.Api_name === 'HDFC' || selectedPlan.Api_name === 'Kotak' || selectedPlan.Api_name === 'Future' || selectedPlan.Api_name === 'Reliance' ) && <div className="col-lg-6 col-md-6 mt-0 ps-lg-0 pb-4">
                  <li className="position-relative mb-1">
                    <div className="form-floating">
                      <select
                        className="form-select fs-3"
                        id="gen"
                        {...register("docType", {
                          required: "Please Select Document Type to Verify",
                          onChange: (e) => setDocType(e?.target?.value),
                        })}
                      >
                        {/* {magmaAadharKyc ? (
                          <>
                          <option value="AADHAAR">Adhar Card</option>
                          <option value="ckyc">CKYC Number</option>
                          <option value="pan">Pan Card</option>
                          </>
                        ) : */}
                         (
                          <>
                            <option value="">Select Document Type</option>
                            {(selectedPlan.Api_name === "HDFC" && apiRequestQQ.CustomerType === "COMPANY") || selectedPlan.Api_name === "Zuno" ? null : <option value="ckyc">CKYC Number</option>}
                            
                            <option value="pan">Pan Card</option>
                            {(selectedPlan?.Api_name === "Magma" || selectedPlan.Api_name === "Zuno") && (
                              <>
                                <option value="AADHAAR">Adhar Card</option>
                                {/* <option value="doc">Upload Document</option> */}
                              </>
                            )}
                          </>
                        )
                        {/* } */}
                      </select>
                      <label htmlFor="gen">
                        Verify By...
                        <span className="text-danger">*</span>
                      </label>
                    </div>
                    <p className="m-0 f-error fs-4 mx-2">
                      {errors?.docType?.message}
                    </p>
                  </li>
                </div>}
                {docType === "ckyc" ? (
                  <div className="col-lg-6 col-md-6 mt-0 ps-lg-0 pb-4">
                    <li className="position-relative mb-1">
                      <div className="form-floating">
                        <input
                          type="text"
                          className="form-control fs-3"
                          id="fsa1"
                          {...register("ckycNumber", {
                            pattern: {
                              value: /[0-9]$/,
                              message: "Invalid CKYC Number",
                            },
                          })}
                          // readOnly={
                          //   apiRequestQQ.KYC.RoyalRefNo != '' &&
                          //   selectedPlan.Api_name == 'Royal'
                          //     ? true
                          //     : false
                          // }
                          onChange={(e) =>
                            handleChangeCkycValue(e.target.value)
                          }
                          placeholder="Pan Car Number"
                        />
                        <label htmlFor="fsa1">CKYC Number</label>
                      </div>
                      {/* <p className='m-0 f-error fs-4 mx-2'>{errors?.PanNumber?.message}</p> */}
                    </li>
                  </div>
                ) : docType === "pan" ? (
                  <div className="d-flex justify-content-between px-0">
                    <div className="col-lg-6 col-md-6 mt-0 ps-lg-0 pb-4">
                      <li className="position-relative mb-1">
                        <div className="form-floating">
                          <input
                            type="text"
                            className="form-control fs-3 text-uppercase"
                            id="fsa1"
                            maxLength={10}
                            {...register("PanNumber", {
                              pattern: {
                                value: /^[A-Za-z]{5}[0-9]{4}[A-Za-z]{1}/,
                                message: "Invalid Pan Number",
                              },
                              required: "Pan Number is required",
                            })}
                            readOnly={
                              apiRequestQQ.KYC.RoyalRefNo != "" &&
                              selectedPlan.Api_name == "Royal"
                                ? true
                                : false
                            }
                            onChange={(e) =>
                              handleChangePanValue(e.target.value)
                            }
                            placeholder="Pan Car Number"
                          />
                          <label htmlFor="fsa1">Pan Card Number</label>
                        </div>
                        <p className="m-0 f-error fs-4 mx-2">
                          {errors?.PanNumber?.message}
                        </p>
                      </li>
                    </div>

                    {selectedPlan.Api_name !== "Zuno" && <div className="col-sm-4  col-6 mb-1">
                      <input
                        type="file"
                        className="d-none"
                        style={{ cursor: "pointer" }}
                        id={"panImage"}
                        name={"panImage"}
                        {...register("PanImage", { required: true })}
                        onChange={(e) => handleUploadPanFile(e.target.files)}
                        accept={"image/*"}
                      />

                      <label htmlFor={"panImage"} className="">
                        <p className="fs-2 text-decoration-underline fw-bold mx-5">
                          Pan Card
                        </p>
                        <img
                          src={
                            pan.image
                              ? pan.image
                              : "/assetss/images/pancardicon.png"
                          }
                          className=" d-block"
                          style={{
                            objectFit: "contain",
                            height: "150px",
                            width: "150px",
                            cursor: "pointer",
                          }}
                        />
                      </label>
                      {errors.PanImage && (
                        <p
                          className="f-error fs-4 m-2"
                          style={{ position: "relative" }}
                        >
                          Please upload Pan Card image.
                        </p>
                      )}
                      {/* <p
                        className="f-error fs-4 m-2 "
                        style={{ position: "relative" }}
                      >
                        {pan.error}
                      </p> */}
                    </div>}
                  </div>
                ):docType === "AADHAAR" ? (

                  <div className="d-flex justify-content-between px-0">

                {selectedPlan.Api_name === "Magma" && <div className="col-lg-6 col-md-6 mt-0 ps-lg-0 pb-4">
                  <li className="position-relative mb-1">
                    <div className="form-floating">
                      <select
                        {...register("MartialStatus", {
                          required: "Martial Status is required",
                        })}
                        onChange={(e) => handleMartialStatus(e.target.value)}
                        readOnly={
                          apiRequestQQ.KYC.RoyalRefNo != "" &&
                          selectedPlan.Api_name == "Royal"
                            ? true
                            : false
                        }
                        className="form-select fs-3"
                        id="gen"
                      >
                        <option className="d-none" selected value="">
                          Select Martial Status
                        </option>
                        <option value="Married">Married</option>
                        <option value="Single">Single</option>
                      </select>
                      <label htmlFor="gen">
                        Martial Status
                        <span className="text-danger">*</span>
                      </label>
                    </div>
                    <p className="m-0 f-error fs-4 mx-2">
                      {errors?.MartialStatus?.message}
                    </p>
                  </li>
                </div>}

                    <div className="col-sm-4  col-6 mb-1">
                      <input
                        type="file"
                        className="d-none"
                        style={{ cursor: "pointer" }}
                        id={"adharImage"}
                        name={"adharImage"}
                        {...register("adharImage", { required: true })}
                        onChange={(e) => handleUploadAdharFile(e.target.files)}
                        accept={"image/*"}
                      />

                      <label htmlFor={"adharImage"} className="">
                        <p className="fs-2 text-decoration-underline fw-bold mx-5">
                        Adhar Card Front
                        </p>
                        <img
                          src={
                            adhar.image
                              ? adhar.image
                              : "/assetss/images/pancardicon.png"
                          }
                          className=" d-block"
                          style={{
                            objectFit: "contain",
                            height: "150px",
                            width: "150px",
                            cursor: "pointer",
                          }}
                        />
                      </label>
                      {errors.adharImage && (
                        <p
                          className="f-error fs-4 m-2"
                          style={{ position: "relative" }}
                        >
                          Please upload AAdhar Card image.
                        </p>
                      )}

                      {/* <p
                        className='f-error fs-4 m-2 '
                        style={{ position: 'relative' }}
                      >
                        {adhar.error}
                      </p> */}
                    </div>
                    {selectedPlan.Api_name === "Zuno" && <div className="col-sm-4  col-6 mb-1">
                      <input
                        type="file"
                        className="d-none"
                        style={{ cursor: "pointer" }}
                        id={"adharImageBack"}
                        name={"adharImageBack"}
                        {...register("adharImageBack", { required: true })}
                        onChange={(e) => handleUploadAdharFileBack(e.target.files)}
                        accept={"image/*"}
                      />

                      <label htmlFor={"adharImageBack"} className="">
                        <p className="fs-2 text-decoration-underline fw-bold mx-5">
                        Adhar Card Back
                        </p>
                        <img
                          src={
                            adharBack.image
                              ? adharBack.image
                              : "/assetss/images/pancardicon.png"
                          }
                          className=" d-block"
                          style={{
                            objectFit: "contain",
                            height: "150px",
                            width: "150px",
                            cursor: "pointer",
                          }}
                        />
                      </label>
                      {errors.adharImageBack && <p className="f-error fs-4 m-2" style={{ position: "relative" }}>Please upload AAdhar Card Back image.</p>}

                      {/* <p
                        className='f-error fs-4 m-2 '
                        style={{ position: 'relative' }}
                      >
                        {adhar.error}
                      </p> */}
                    </div>}
                  </div>)
                 : docType === "doc" ? (
                  <></>
                ) : (
                  ""
                )}
              </>

              <div className="d-flex ms-5">
                {hidebtn ? (<div className="col-lg-5">
                    <button disabled style={{ filter: hidebtn ? "blur(2px)" : "none" }} className="btn btn-primary mb-5 mt-5 py-3 w-100 fs-1 fw-bold">
                      Previous 
                    </button>
                </div>) : (<div className="col-lg-5">
                  <Link to={`/quotelist/${motorRequest?.customerLeadId}`}>
                    <button className="btn btn-primary mb-5 mt-5 py-3 w-100 fs-1 fw-bold">
                      Previous
                    </button>
                  </Link>
                </div>)}

                <div className="col-lg-5 ms-5">
                  <div className="">
                    <button
                      // disabled={disabled}
                      // onClick={handleNextClick}
                      disabled={hidebtn}
                      style={{ filter: hidebtn ? "blur(2px)" : "none" }}
                      type="submit"
                      className="btn btn-primary mb-5 mt-5 py-3 w-100 fs-1 fw-bold"
                    >
                      {KYCSpinner ? (
                        <div class="spinner-border text-light" role="status">
                          <span class="sr-only"></span>
                        </div>
                      ) : (
                        "Next"
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </ul>
          </div>
        </form>
      )}

      <Modal
        isOpen={modalOpen}
        toggle={() => setModalOpen(!modalOpen)}
        size="md"
      >
        <ModalHeader
          toggle={() => setModalOpen(!modalOpen)}
          className="px-lg-4"
        >
          <div className="fs-2">KYC Successfull</div>
        </ModalHeader>
        <ModalBody>
          <label
            className="form-check-label fs-2"
            style={{ background: "white" }}
            htmlFor="aacche12"
          >
            Your KYC is Successfull
          </label>
        </ModalBody>
        <ModalFooter>
          <Button
            className="btn btn-primary fs-4 px-2 py-3 mt-3"
            onClick={() => setModalOpen(!modalOpen)}
          >
            OK
          </Button>
        </ModalFooter>
      </Modal>

      <Modal
        isOpen={modalOpen2}
        toggle={() => setModalOpen2(!modalOpen2)}
        size="md"
      >
        <ModalHeader
          toggle={() => setModalOpen2(!modalOpen2)}
          className="px-lg-4"
        >
          <div className="fs-2">KYC Unsuccessfull</div>
        </ModalHeader>
        <ModalBody>
          <label
            className="form-check-label fs-2"
            style={{ background: "white" }}
            htmlFor="aacche12"
          >
            You have to complete your E-kyc first
          </label>
        </ModalBody>
        <ModalFooter>
          <Button
            className="btn btn-primary fs-4 px-2 py-3 mt-3"
            onClick={() => setModalOpen2(!modalOpen2)}
          >
            OK
          </Button>
        </ModalFooter>
      </Modal>

      <Modal
        isOpen={modalOpen3}
        toggle={() => setModalOpen3(!modalOpen3)}
        size="md"
      >
        <ModalHeader
          toggle={() => setModalOpen3(!modalOpen3)}
          className="px-lg-4"
        >
          <div className="fs-2">KYC Failed!</div>
        </ModalHeader>
        <ModalBody>
          <label
            className="form-check-label fs-2"
            style={{ background: "white" }}
            htmlFor="aacche12"
          >
            You have to proceed with Aadhar based KYC
          </label>
        </ModalBody>
        <ModalFooter>
          <Button
            className="btn btn-primary fs-4 px-2 py-3 mt-3"
            onClick={() => setModalOpen3(!modalOpen3)}
          >
            OK
          </Button>
        </ModalFooter>
      </Modal>

      <Modal
        isOpen={modalOpen4}
        toggle={() => setModalOpen4(!modalOpen4)}
        size="md"
      >
        <ModalHeader
          toggle={() => setModalOpen4(!modalOpen4)}
          className="px-lg-4"
        >
          <div className="fs-2">KYC Failed!</div>
        </ModalHeader>
        <ModalBody>
          <label
            className="form-check-label fs-2"
            style={{ background: "white" }}
            htmlFor="aacche12"
          >
            KYC Failed
          </label>
        </ModalBody>
        <ModalFooter>
          <Button
            className="btn btn-primary fs-4 px-2 py-3 mt-3"
            onClick={() => setModalOpen4(!modalOpen4)}
          >
            OK
          </Button>
        </ModalFooter>
      </Modal>

      <Modal
        isOpen={modalOpen5}
        toggle={() => setModalOpen5(!modalOpen5)}
        size="md"
      >
        <ModalHeader
          toggle={() => setModalOpen5(!modalOpen5)}
          className="px-lg-4"
        >
          <div className="fs-2">KYC Failed! Please Upload Valid AAdhar</div>
        </ModalHeader>
        {/* <ModalBody>
        <label
            className='form-check-label fs-2'
            style={{ background: 'white' }}
            htmlFor='aacche12'
          >
           KYC Failed
          </label>
        </ModalBody> */}
        <ModalFooter>
          <Button
            className="btn btn-primary fs-4 px-2 py-3 mt-3"
            onClick={() => setModalOpen5(!modalOpen5)}
          >
            OK
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default OwnerDetails;
function isValidHttpUrl(string) {
  let url;
  try {
    url = new URL(string);
  } catch (_) {
    return false;
  }
  return url.protocol === "http:" || url.protocol === "https:";
}
