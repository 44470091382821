import axios from "axios";
import React, { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import Layout from "../../common/Layout";
import moment from "moment";
import { generateHDFCChecksum } from "../../Services/masterServices";
import { dispatchQuickQuote } from "../../../store/actions/userActions";
import { sendErrorMessage } from "../../Services/PosService";
import { toast } from 'react-toastify'

const HDFCPayment = ({images}) => {
  const apiRequestQQ = useSelector((state) => state.root.apiRequestQQ);
  const [checkSumData, setCheckSumData] = useState(null);
  let today = moment().format("YYYY-MM-DD");
  function randomIntFromInterval(min, max) {
    // min and max included
    return Math.floor(Math.random() * (max - min + 1) + min);
  }

  // console.log("randint", rndInt);

  let valArr = [];
  useEffect(() => {
    generateNumber();
    let checksumdata = {
      TransactionNo: `1085${today.replaceAll("-", "")}${valArr.sort().join("")}`,
      TotalAmount: apiRequestQQ.PaymentAmount,
      AppID: "10243",
      SubscriptionID: "S000000290",
      SuccessUrl: "https://uat.expertcover.in/success-page/",
      FailureUrl: "https://uat.expertcover.in/error-page/",
      Source: "POST",
    };
   

    console.log("checksumdata", checksumdata);
    generateHDFCChecksum(checksumdata).then((response) => {
      if (response.status === true) {
        checksumdata.Chksum = response.data;
        console.log(checksumdata);
        setCheckSumData(checksumdata);
        dispatchQuickQuote("PaymentId", checksumdata.TransactionNo);
      } else {
        sendErrorMessage(response);
      }
    });

    // soap();
  }, []);

  const generateNumber = () => {
    const rndInt = randomIntFromInterval(0, 9);
    if (valArr.length < 5) {
      if (valArr.includes(rndInt) === false) {
        valArr.push(rndInt);
      }
      generateNumber();
    }
  };

  if(apiRequestQQ?.PolicyStatus === 'continue' && apiRequestQQ.IsVehicleNew !== true){
    if (!images.some(image => image.column === 'rc_front') && !images.some(image => image.column === 'insurance_01')) {
      return(
        <>
      <button type="submit" className="btn btn-primary fs-3 px-4 py-2">Please upload RC Front and Insurance 01</button>
        </>
      )
      // toast.error('Please upload RC Front and Insurance 01');
  } else if(!images.some(image => image.column === 'insurance_01')){
    return(
      <>
    <button type="submit" className="btn btn-primary fs-3 px-4 py-2">Insuracne 01 is required</button>
      </>
    )
      // toast.error('Insuracne 01 is required');
    } else if(!images.some(image => image.column === 'rc_front')){

      return(
        <>
      <button type="submit" className="btn btn-primary fs-3 px-4 py-2">RC Front is required</button>
        </>
      )
      
      // toast.error('RC Front is required');

    } else {
  
  return (

    <>
    <form method="post" action="https://heapp21.hdfcergo.com/UAT/OnlineProducts/CCPGISUBSCRIBER/MakePayment.aspx?wsdl">
      <input type="text" value={checkSumData?.TransactionNo} name="Trnsno" hidden />
      <input type="text" value={checkSumData?.TotalAmount} name="Amt" hidden />
      <input type="text" value={checkSumData?.AppID} name="Appid" hidden />
      <input type="text" value={checkSumData?.SubscriptionID} name="Subid" hidden />
      <input type="text" value={checkSumData?.SuccessUrl} name="Surl" hidden />
      <input type="text" value={checkSumData?.FailureUrl} name="Furl" hidden />
      <input type="text" value={checkSumData?.Source} name="Src" hidden />
      <input type="text" value={checkSumData?.Chksum} name="Chksum" hidden />
      <button type="submit" className="btn btn-primary fs-3 px-4 py-2">
        Buy <span> {checkSumData?.TotalAmount}</span>
      </button>
    </form>
  </>

  )};
}

else if((apiRequestQQ?.PolicyStatus === "expired within 90 day"  || apiRequestQQ?.PolicyStatus ===  "expired above 90 day") && apiRequestQQ.IsVehicleNew !== true){

  if(!images.some(image => image.column === 'rc_front')){
    toast.error('Please upload RC Front')
  }
  else {
  
    return (
  
      <>
      <form method="post" action="https://heapp21.hdfcergo.com/UAT/OnlineProducts/CCPGISUBSCRIBER/MakePayment.aspx?wsdl">
        <input type="text" value={checkSumData?.TransactionNo} name="Trnsno" hidden />
        <input type="text" value={checkSumData?.TotalAmount} name="Amt" hidden />
        <input type="text" value={checkSumData?.AppID} name="Appid" hidden />
        <input type="text" value={checkSumData?.SubscriptionID} name="Subid" hidden />
        <input type="text" value={checkSumData?.SuccessUrl} name="Surl" hidden />
        <input type="text" value={checkSumData?.FailureUrl} name="Furl" hidden />
        <input type="text" value={checkSumData?.Source} name="Src" hidden />
        <input type="text" value={checkSumData?.Chksum} name="Chksum" hidden />
        <button type="submit" className="btn btn-primary fs-3 px-4 py-2">
          Buy <span> {checkSumData?.TotalAmount}</span>
        </button>
      </form>
    </>
  
    )}

}

else if(apiRequestQQ.IsVehicleNew === true){

  if(!images.some(image => image.column === 'invoice_cover')){

    toast.error('Please upload Invoice Cover') 
  }
  else {
  
    return (
  
      <>
      <form method="post" action="https://heapp21.hdfcergo.com/UAT/OnlineProducts/CCPGISUBSCRIBER/MakePayment.aspx?wsdl">
        <input type="text" value={checkSumData?.TransactionNo} name="Trnsno" hidden />
        <input type="text" value={checkSumData?.TotalAmount} name="Amt" hidden />
        <input type="text" value={checkSumData?.AppID} name="Appid" hidden />
        <input type="text" value={checkSumData?.SubscriptionID} name="Subid" hidden />
        <input type="text" value={checkSumData?.SuccessUrl} name="Surl" hidden />
        <input type="text" value={checkSumData?.FailureUrl} name="Furl" hidden />
        <input type="text" value={checkSumData?.Source} name="Src" hidden />
        <input type="text" value={checkSumData?.Chksum} name="Chksum" hidden />
        <button type="submit" className="btn btn-primary fs-3 px-4 py-2">
          Buy <span> {checkSumData?.TotalAmount}</span>
        </button>
      </form>
    </>
  
    )}
}
};

export default HDFCPayment;
