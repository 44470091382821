import axios from "axios";
import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useSearchParams } from "react-router-dom";
import { GetDataWithToken, PostDataWithToken } from "../../../api/apiHelper";
import { GetData_V3 } from "../../../api/apiHelper_V3";
import { decryptAes256Text } from "../../utility/constants";
import {
  dispatchQuickQuote,
  selectedPlanAction,
} from "../../../store/actions/userActions";
import { createQuotePayloadObj } from "../../../store/reducers/userReducers";
import {
  downloadBajajPdf,
  downloadBase64File,
  downloadDigitPdf,
  changePolicyStatus,
  magmaPDFDownload,
  zunoIssueApi,
  zunoPaymentcall,
  getPolicyDocument,
} from "../Homepage/TPApiCall";
import Layout from "../Layout";
var FileSaver = require("file-saver");

const SuccessMessage = () => {
  const selectedPlan = useSelector((state) => state.root.selectedPlan);
  const apiRequestQQ = useSelector((state) => state.root.apiRequestQQ);
  const [loading, setLoading] = useState(true);
  const [searchParams, setSearchParams] = useSearchParams();
  const bajajId = searchParams.get("p_policy_ref");
  const kotakId = searchParams.get("vPolicyNumber");
  const libertyId = searchParams.get("policyno");
  const relianceId = searchParams.get("Output");
  const [RoyalData, setRoyalData] = useState(null);
  const [HDFC, setHDFC] = useState(null);
  const [futureData, setFutureData] = useState(null);
  const [magmaData, setMagmaData] = useState("");
  const [hideBtn, setHideBtn] = useState(false)
  const [count, setCount] = useState(0)
  const [click, setClick] = useState(false)
  const [shriramData, setShriramData] = useState({
    policyId: "",
    pdfLink: "",
  });
  const dispatch = useDispatch();
  const [pdfSpinner, setPdfSpinner] = useState(false);
  const [spinner, setSpinner] = useState(false);
  const [caseId, setCaseId] = useState(null);


  useEffect(() => {
    if (selectedPlan.Api_name == "digit") {
      digitKYCStatusApi();
    }
    if (selectedPlan.Api_name == "Shriram") {
      let postData = {
        ProposalNo: apiRequestQQ.policyId,
        QuoteID: apiRequestQQ.ApiId,
      };
      PostDataWithToken("motor/shriram-payment-status", postData).then(
        (response) => {
          if (response.status == true) {
            console.log("response status", response.data);
            if (response?.data?.MessageResult?.Result == "Success") {
              const policyData = response.data.Response[0];
              setShriramData({
                policyId: policyData.PolicyNo,
                pdfLink: policyData.PolicyURL,
              });
            } else {
              alert(response.data.MessageResult.ErrorMessage);
            }
          }
        }
      );
    }
    if (selectedPlan.Api_name === "HDFC" && loading === true) {
      PostDataWithToken(
        "motor/hdfcsubmitPayment",
        createQuotePayloadObj(apiRequestQQ)
      )
        .then((response) => {
          if (response.status === true) {
            setLoading(false);
            setHDFC({
              Policy_Number: response.data.Policy_Details.PolicyNumber,
              TransactionID: response.data.TransactionID,
            });
          } else {
            setLoading(true);
            alert("Policy not issue try again");
          }
        })
        .catch((err) => console.log(err));
    } else if (selectedPlan.Api_name === "Future" && loading === true) {
      let data = {
        WS_P_ID: searchParams.get("WS_P_ID"),
        TID: searchParams.get("TID"),
        PGID: searchParams.get("PGID"),
        Premium: searchParams.get("Premium"),
        Response: searchParams.get("Response"),
      };
      setFutureData(data);
      dispatchQuickQuote("ApiId", data.WS_P_ID);
      dispatchQuickQuote("PaymentId", data.PGID);
      dispatchQuickQuote("PaymentAmount", data.Premium);
      let newData = { ...apiRequestQQ };
      newData.ApiId = data.WS_P_ID;
      newData.PaymentId = data.PGID;
      newData.PaymentAmount = data.Premium;
      console.log("api resuqes00----", newData);
      if (data.Response == "Success") {
        setTimeout(() => {
          PostDataWithToken(
            "motor/futureGenralCreatePolicy",
            createQuotePayloadObj(newData)
          )
            .then((response) => {
              if (response.status === true) {
                if (response.data.Root.Policy.Status === "Successful") {
                  setFutureData({
                    ...futureData,
                    PolicyNumber: response.data.Root.Policy.PolicyNo,
                  });
                  setLoading(false);
                } else {
                  alert(response.data.ErrorMessage);
                }
              } else {
                sendErrorMessage(response);
              }
            })
            .catch((err) => console.log(err));
        }, 500);
      } else {
        alert("something went wrong");
      }
    } else if (selectedPlan.Api_name === "Royal" && loading === true) {
      let postData = {
        vehicleType: apiRequestQQ.VehicleType,
        quoteId: selectedPlan?.ApiId,
      };
      PostDataWithToken("motor/transection-check", postData).then(
        (response) => {
          console.log(
            "response of personal",
            response.data.POLICY_NUMBER,
            postData.vehicleType
          );
          if (response?.data?.data?.policyNumber) {
            downloadRoyalSundramPdf(response.data.data);
            setRoyalData(response.data.data);
            setLoading(false);
          }
          // else if (response.data.POLICY_NUMBER && postData.vehicleType === "4w") {
          //   setRoyalData(response.data);
          //   downloadRoyalSundramPdf(response.data);
          //   setLoading(false);
          // }
          else {
            alert("Policy not purchased!pleasae try again");
          }
        }
      );
    } else if (selectedPlan.Api_name === "Magma") {
      const postData = {
        ProposalNumber: apiRequestQQ?.ProposalId,
        ApiUniqueNumber: apiRequestQQ?.ApiUniqueNumber?.Magma,
        VehicleType: apiRequestQQ?.VehicleType,
      };
      PostDataWithToken("motor/proposalStatus", postData).then((response) => {
        // debugger;
        console.log("status of magma number", response.data);
        console.log(
          "response?.data?.OutputResult?.PolicyNumber",
          response?.data?.OutputResult?.PolicyNumber
        );

        if (response?.data?.OutputResult?.PolicyNumber) {
          setLoading(false);
          setMagmaData(response?.data?.OutputResult?.PolicyNumber);
          const PolicyNumber = response?.data?.OutputResult?.PolicyNumber;
          const newData = { ...selectedPlan, PolicyNumber: PolicyNumber };
          dispatch(selectedPlanAction(newData));
          const postData = {
            PolicyNumber: response?.data?.OutputResult?.PolicyNumber,
            CustomerID: apiRequestQQ.CustomerID,
            IntermediaryCode: "BRC0000388",
            ProposalNumber: apiRequestQQ.ProposalId,
          };
          console.log("postdata in pdf", postData);
          PostDataWithToken("motor/magma-pdf", postData).then((response) => {
            console.log(
              "response in magma pdf",
              response?.data?.OutputResult?.PolicyBase64String
            );
            if (response?.data?.OutputResult?.PolicyBase64String) {
              downloadBase64File(
                response?.data?.OutputResult?.PolicyBase64String,
                "PEIB_Magma_PDF"
              );
            } else {
              setLoading(false);
            }
          });
        }
      });

      // const data = {
      //   PolicyNumber: "P0024000100/4101/100600",
      //   CustomerID: "20003870540",
      //   IntermediaryCode: "BRC0000388",
      //   ProposalNumber: "202401080000490",
      // }
    } else if (selectedPlan.Api_name == "Liberty") {
      let postData = {
        strCustomerGcId: selectedPlan.CustomerID,
        strPolicyNumber: libertyId,
        strProductType: getLibertyProductCode(
          apiRequestQQ.VehicleType,
          apiRequestQQ.NewPolicyType
        ),
      };
      PostDataWithToken("motor3/liberty-pdf-download", postData).then(
        (response) => {
          if (response.status === true) {
            downloadBase64File(response.data, "PEIB_" + libertyId);
          }
        }
      );
    } else {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const extractedCaseId = urlParams.get('caseId');
    setCaseId(extractedCaseId);
  }, []);

  useEffect(() => {
    if (selectedPlan.Api_name === "HDFC" && HDFC != null) {
      changePolicyStatus("policy-generated", apiRequestQQ.leadId);
      downloadHDFCPolicy();
    }
    if (selectedPlan.Api_name === "Kotak" && kotakId) {
      changePolicyStatus("policy-generated", apiRequestQQ.leadId);
      downloadKotakPdf();
    }

    if (selectedPlan.Api_name === "bajaj" && bajajId) {
      changePolicyStatus("policy-generated", apiRequestQQ.leadId);
      downloadBajajPdf();
    }
    if (selectedPlan.Api_name && relianceId) {
      changePolicyStatus("policy-generated", apiRequestQQ.leadId);
      downloadReliancePolicy();
    }
  }, [HDFC, kotakId, bajajId, relianceId]);

  const downloadHDFCPolicy = () => {
    setPdfSpinner(true);
    let postData = {
      VehicleType: apiRequestQQ.VehicleType,
      NewPolicyType: apiRequestQQ.NewPolicyType,
      TransactionID: HDFC?.TransactionID,
      Policy_Number: HDFC?.Policy_Number,
      ApiUniqueNumber: apiRequestQQ?.ApiUniqueNumber.HDFC,
    };
    PostDataWithToken("motor/hdfc-pdf-download", postData).then((res) => {
      if (res.status === true) {
        setPdfSpinner(false);
        downloadBase64File(
          res.data.Resp_Policy_Document.PDF_BYTES,
          "PEIB_" + HDFC?.Policy_Number
        );
        // window.location.href = res.data.schedulePath;
      }
    });
  };

  const getPolicyDocument = async (data) => {
    setSpinner(true)
    // debugger
    try {
      const documentResponse = await GetData_V3(`motor/getPolicyDocument/${data}`);
  
      if (
        documentResponse?.data?.caseStatus === "Policy Document Success"
      ) {
        let response = decryptAes256Text(
          documentResponse?.data?.policyDocumentUrl,
          process.env.REACT_APP_DECRYPTION_KEY
        );
  
        console.log('response',response)
  
        window.open(response)
        setSpinner(false)
      } else {
        // Handle the case where the document response is not successful
        console.log("Failed to fetch policy document: ", documentResponse);
        toast.error(
          documentResponse?.data?.displayMessage
            ? `${documentResponse.data.displayMessage}`
            : documentResponse?.data?.errors
            ? `${documentResponse?.data?.errors[0].displayMessage}`
            : `${documentResponse?.errors?.[0]?.displayMessage}`,
          {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          }
        );
        setSpinner(false)
      }
  
    } catch (error) {
      return error;
    }
  };

  const downloadKotakPdf = () => {
    let postData = {
      vProposalNumber: selectedPlan.vProposalNumber,
      vPolicyNumber: kotakId,
      vProductCode: selectedPlan?.vProductCode,
      ApiUniqueNumber: apiRequestQQ?.ApiUniqueNumber?.Kotak,
    };
    PostDataWithToken("motor/kotak-pdf-download", postData).then((res) => {
      if (res.status === true) {
        console.log("response data", res.data);
        downloadBase64File(res.data, "PEIB_" + kotakId);
        // window.location.href = res.data.schedulePath;
      }
    });
  };

  const downloadReliancePolicy = () => {
    let postData = {
      PolicyNo: relianceId.split("|")[1],
      ProductCode: getRelianceProductType(
        apiRequestQQ.VehicleType,
        apiRequestQQ.NewPolicyType,
        apiRequestQQ.IsVehicleNew
      ),
      quoteID: apiRequestQQ.ApiId,
    };
    let url = `https://rgipartners.reliancegeneral.co.in/API/Service/GeneratePolicyschedule?PolicyNo=${postData.PolicyNo}&ProductCode=${postData.ProductCode}`;
    postData.url = url;
    PostDataWithToken("motor/reliance-pdf-download", postData)
      .then((res) => console.log(res))
      .catch((err) => console.log(err));
    window.open(url, "_blank");
  };

  const getRelianceProductType = (vehicleType, policyType, isNew) => {
    if (isNew == true && vehicleType == "4w") {
      return 2374;
    } else if (
      isNew == false &&
      vehicleType === "4w" &&
      policyType === "Comprehensive"
    ) {
      return 2311;
    } else if (
      isNew == false &&
      vehicleType === "4w" &&
      policyType === "ThirdParty"
    ) {
      return 2347;
    } else if (
      isNew == false &&
      vehicleType === "4w" &&
      policyType === "ODOnly"
    ) {
      return 2309;
    } else if (isNew === true && vehicleType == "2w") {
      return 2375;
    } else if (
      isNew == false &&
      vehicleType === "2w" &&
      policyType === "Comprehensive"
    ) {
      return 2312;
    } else if (
      isNew == false &&
      vehicleType === "2w" &&
      policyType === "ThirdParty"
    ) {
      return 2348;
    } else if (
      isNew == false &&
      vehicleType === "2w" &&
      policyType === "ODOnly"
    ) {
      return 2308;
    } else if (
      isNew === false &&
      vehicleType == "2w" &&
      policyType === "Bundled"
    ) {
      return 2375;
    } else if (
      isNew == false &&
      vehicleType == "4w" &&
      policyType === "Bundled"
    ) {
      return 2374;
    }
    // else if (
    //   (vehicleType === "pcv" && PolicyType === "Comprehensive") ||
    //   (vehicleType === "gcv" && PolicyType === "Comprehensive")
    // ) {
    //   return 20301;
    // } else if (
    //   (vehicleType === "pcv" && PolicyType === "ThirdParty") ||
    //   (vehicleType === "gcv" && PolicyType === "ThirdParty")
    // ) {
    //   return 20302;
    // }
  };

  const downloadFutureGeneralPDF = () => {
    let postData = {
      PolicyNumber: futureData.PolicyNumber,
      ApiUniqueNumber: apiRequestQQ?.ApiUniqueNumber.Future,
    };
    PostDataWithToken("motor/future-pdf-download", postData).then((res) => {
      if (res.status === true) {
        let pdfData =
          res.data.GetPDFResponse.GetPDFResult.DocumentElement.PDFBytes;
        downloadBase64File(pdfData, "R_E_T_R_O_" + postData.PolicyNumber);
        // window.location.href = res.data.schedulePath;
      }
    });
  };
  const downloadRoyalSundramPdf = (args) => {
    let royalData = args ? args : RoyalData;
    let postData;
    // if (apiRequestQQ.VehicleType == "2w") {
    postData = {
      policyNumber: royalData.policyNumber,
      expiryDate: royalData.expiryDate,
      IsVehicleNew: apiRequestQQ.IsVehicleNew,
      vehicleType: apiRequestQQ.VehicleType,
    };
    // } else if (apiRequestQQ.VehicleType == "4w") {
    // postData = {
    //   policyNumber: royalData.POLICY_NUMBER,
    //   expiryDate: royalData?.response?.PREMIUMDETAILS?.DATA.POLICY_EXPIRY_DATE,
    //   IsVehicleNew: apiRequestQQ.IsVehicleNew,
    //   vehicleType: apiRequestQQ.VehicleType,
    // };
    // }
    console.log("roual pdf sownoad", postData);
    PostDataWithToken("motor/royal-pdf-download", postData).then((res) => {
      if (res.status === true) {
        if (res?.data?.data?.base64File) {
          if (args) {
            sendPolicyPdfToEmail(
              postData.policyNumber,
              res.data.data.base64File
            );
          } else {
            downloadBase64File(
              res.data.data.base64File,
              "PEIB_" + postData.policyNumber
            );
          }
        }
        // window.location.href = res.data.schedulePath;
      }
    });
  };

  const magmaPDFDownload = async (selectedPlan, apiRequestQQ) => {
    // debugger;
    setSpinner(true);
    setHideBtn(true)
    try {
      const postData = {
        PolicyNumber: selectedPlan?.PolicyNumber,
        CustomerID: apiRequestQQ.CustomerID,
        IntermediaryCode: "BRC0000388",
        ProposalNumber: apiRequestQQ.ProposalId,
        ApiUniqueNumber: apiRequestQQ?.ApiUniqueNumber?.Magma,
        VehicleType: apiRequestQQ?.VehicleType,
        PolicyIssueDate: new Date().toLocaleDateString("en-GB").toString(),
      };
      console.log(postData, "postData");
      const response = await PostDataWithToken("motor/magma-pdf", postData);
      console.log("response in magma pdf", response);
      if (response?.data?.OutputResult?.PolicyBase64String) {
        downloadBase64File(
          response?.data?.OutputResult?.PolicyBase64String,
          "PEIB_Magma_PDF"
        );
      } else {
        setLoading(false);
        setHideBtn(false)
      }
      setSpinner(false);
      setHideBtn(false)
      return (
        response?.data?.OutputResult?.PolicyBase64String, setSpinner(false)
      );
    } catch (err) {
      console.log(err);
    }
  };

  // debugger

  useEffect(() => {
    if (count > 0) {
      zunoPDFDownload(apiRequestQQ, count, setSpinner, setLoading);
    }
  }, [count, click]);

  // const handleClick = () => {
  //   debugger
  //   console.log(count)
  //   setCount((prevCount) => prevCount + 1);
  //   console.log(count)

  // };


  const zunoPDFDownload = async () => {
    console.log('***************',apiRequestQQ)
    setSpinner(true)
   
      const res = await zunoIssueApi(apiRequestQQ)
      if (res.status === true) {
         dispatchQuickQuote("PolicyId", res.data.issuePolicyObject.issuepolicy.policynrTt) 
        const respon = await zunoPaymentcall(apiRequestQQ)
        if (respon.status === true) {
          setClick(true)
          try {
            const postData = {
              PolicyId: res.data.issuePolicyObject.issuepolicy.policynrTt,
              ApiUniqueNumber: apiRequestQQ.ApiUniqueNumber.Zuno
            };
            const response = await PostDataWithToken("motor/zunoPDF", postData);
            if (response?.data) {
              downloadBase64File(
                response?.data,
                "PEIB_Zuno_PDF"
              );
            } else {
              setLoading(false);
            }
            setSpinner(false)
            setClick(true)
            return response?.data;
          } catch (err) {
            console.log(err);
          }
        }
      }
      else {
        setSpinner(false)
        setClick(true)
      }
    setSpinner(false)
  };

  const zunoSecondClick = async () => {
    // debugger
    setSpinner(true)
    if (click === true) {

      try {
        const postData = {
          PolicyId: apiRequestQQ.PolicyId,
          ApiUniqueNumber: apiRequestQQ.ApiUniqueNumber.Zuno
        };
        const response = await PostDataWithToken("motor/zunoPDF", postData);
        if (response?.data) {
          downloadBase64File(
            response?.data,
            "PEIB_Zuno_PDF"
          );
          setSpinner(false)

        } else {
          setLoading(false);
          setSpinner(false)
        }
        // setSpinner(false)
        return response?.data;
      } catch (err) {
        console.log(err);
        setSpinner(false)

      }
    }
    else {
      setSpinner(false)
    }
    setSpinner(false)
  }

  const sendPolicyPdfToEmail = (policyNumber, base64) => {
    let postData = {
      name: apiRequestQQ.FirstName,
      amount: selectedPlan.FinalPremium,
      quoteId: policyNumber,
      email: apiRequestQQ.Email,
      base64: base64,
    };
    PostDataWithToken("motor/send-policy", postData).then((response) => {
      console.log("email sent");
    });
  };

  const digitKYCStatusApi = () => {
    let postData = {
      applicationId: apiRequestQQ.applicationId,
      policyNumber: apiRequestQQ.ApiId,
      ApiUniqueNumber: apiRequestQQ.ApiUniqueNumber.Digit,
    };
    PostDataWithToken("kyc/digit-kyc", postData).then((response) => {
      if (response.status === true) {
        if (response.data.kycVerificationStatus != "DONE") {
          console.log("respo", response.data.link);
          alert("You have to complete your digit kyc first");
          window.open(response.data.link, "_blank");
        } else if (response.data.kycVerificationStatus == "DONE") {
          downloadDigitPdf(
            apiRequestQQ.applicationId,
            apiRequestQQ?.ApiUniqueNumber?.Digit
          );
        }
      }
    });
  };

  const getLibertyProductCode = (vehicleType, policyType) => {
    if (vehicleType === "4w" && policyType === "Comprehensive") {
      return 3151;
    } else if (vehicleType === "2w" && policyType === "Comprehensive") {
      return 3152;
    } else if (vehicleType === "4w" && policyType === "ThirdParty") {
      return 3155;
    } else if (vehicleType === "4w" && policyType === "ThirdParty") {
      return 3158;
    } else if (vehicleType === "4w" && policyType === "ODOnly") {
      return 3140;
    } else if (vehicleType === "2w" && policyType === "ODOnly") {
      return 3141;
    }
  };
  return (
    <Layout>
      <div className="card text-center" style={{ width: "100%" }}>
        {loading === true ? (
          <div className="card-body m-5">
            <h1 className="card-title">Loading.............</h1>
            <h6 className="card-subtitle mb-2 text-muted">Please Wait.....</h6>
          </div>
        ) : (
          <>
            <div className="success_card">
              <div className="container">
                <div className="success_card_inner">
                  <div className="left_card_img">
                    <img src="/assets/images/policy page form.png"></img>
                  </div>
                  <div className="right_card_content">
                    <div className="login_det">
                      <h2 className="form_heading">Congratulations</h2>
                      <form className="login_form">
                        {selectedPlan.Api_name === "HDFC" && (
                          <>
                            <label class="custom-field two">
                              {/* <input type="text" placeholder="" name="uname" /> */}
                              <span class="placeholder">Policy Number</span>{" "}
                              <br />
                              <span
                                class=""
                                style={{
                                  position: "absolute",
                                  left: "12px",
                                  top: "12px",
                                }}
                              >
                                {HDFC?.Policy_Number}
                              </span>
                            </label>
                            <label class="custom-field two">
                              {/* <input type="text" placeholder="" name="uname" /> */}
                              <span class="placeholder">
                                Transaction Number
                              </span>{" "}
                              <br />
                              <span
                                class=""
                                style={{
                                  position: "absolute",
                                  left: "12px",
                                  top: "12px",
                                }}
                              >
                                {HDFC?.TransactionID}
                              </span>
                            </label>
                          </>
                        )}
                        {selectedPlan.Api_name === "digit" && (
                          <label class="custom-field two">
                            {/* <input type="text" placeholder="" name="uname" /> */}
                            <span class="placeholder">Policy Number</span>{" "}
                            <br />
                            <span
                              class=""
                              style={{
                                position: "absolute",
                                left: "12px",
                                top: "12px",
                              }}
                            >
                              {apiRequestQQ.applicationId}
                            </span>
                          </label>
                        )}
                        {selectedPlan.Api_name == "bajaj" && (
                          <label class="custom-field two">
                            {/* <input type="text" placeholder="" name="uname" /> */}
                            <span class="placeholder">Policy Number</span>{" "}
                            <br />
                            <span
                              class=""
                              style={{
                                position: "absolute",
                                left: "12px",
                                top: "12px",
                              }}
                            >
                              {bajajId}
                            </span>
                          </label>
                        )}
                        {selectedPlan.Api_name == "Kotak" && (
                          <>
                            <label class="custom-field two">
                              {/* <input type="text" placeholder="" name="uname" /> */}
                              <span class="placeholder">Proposal Number</span>{" "}
                              <br />
                              <span
                                class=""
                                style={{
                                  position: "absolute",
                                  left: "12px",
                                  top: "12px",
                                }}
                              >
                                {selectedPlan.vProposalNumber}
                              </span>
                            </label>
                            <label class="custom-field two">
                              {/* <input type="text" placeholder="" name="uname" /> */}
                              <span class="placeholder">Policy Number</span>{" "}
                              <br />
                              <span
                                class=""
                                style={{
                                  position: "absolute",
                                  left: "12px",
                                  top: "12px",
                                }}
                              >
                                {kotakId}
                              </span>
                            </label>
                          </>
                        )}
                        {selectedPlan.Api_name === "Future" && (
                          <label class="custom-field two">
                            {/* <input type="text" placeholder="" name="uname" /> */}
                            <span class="placeholder">Policy Number</span>{" "}
                            <br />
                            <span
                              class=""
                              style={{
                                position: "absolute",
                                left: "12px",
                                top: "12px",
                              }}
                            >
                              {futureData.PolicyNumber}
                            </span>
                          </label>
                        )}
                        {selectedPlan.Api_name === "Magma" && (
                          <>
                            <label class="custom-field two">
                              {/* <input type="text" placeholder="" name="uname" /> */}
                              <span class="placeholder">Proposal Number</span>{" "}
                              <br />
                              <span
                                class=""
                                style={{
                                  position: "absolute",
                                  left: "12px",
                                  top: "12px",
                                }}
                              >
                                {apiRequestQQ.ProposalId}
                              </span>
                            </label>
                            <label class="custom-field two">
                              {/* <input type="text" placeholder="" name="uname" /> */}
                              <span class="placeholder">Policy Number</span>{" "}
                              <br />
                              <span
                                class=""
                                style={{
                                  position: "absolute",
                                  left: "12px",
                                  top: "12px",
                                }}
                              >
                                {magmaData}
                              </span>
                            </label>
                            {/* Policy Number{magmaData} */}
                          </>
                        )}
                        {selectedPlan.Api_name === "Reliance" && (
                          <label class="custom-field two">
                            {/* <input type="text" placeholder="" name="uname" /> */}
                            <span class="placeholder">Proposal Number</span>{" "}
                            <br />
                            <span
                              class=""
                              style={{
                                position: "absolute",
                                left: "12px",
                                top: "12px",
                              }}
                            >
                              {relianceId.split("|")[1]}
                            </span>
                          </label>
                        )}
                        {selectedPlan.Api_name === "Royal" && (
                          <label class="custom-field two">
                            {/* <input type="text" placeholder="" name="uname" /> */}
                            <span class="placeholder">Policy Number</span>{" "}
                            <br />
                            <span
                              class=""
                              style={{
                                position: "absolute",
                                left: "12px",
                                top: "12px",
                              }}
                            >
                              {apiRequestQQ.VehicleType === "2w"
                                ? RoyalData?.policyNumber
                                : apiRequestQQ.VehicleType === "4w" &&
                                RoyalData?.policyNumber}
                            </span>
                          </label>
                        )}
                        {selectedPlan.Api_name === "Shriram" && (
                          <label class="custom-field two">
                            {/* <input type="text" placeholder="" name="uname" /> */}
                            <span class="placeholder">Policy Number</span>{" "}
                            <br />
                            <span
                              class=""
                              style={{
                                position: "absolute",
                                left: "12px",
                                top: "12px",
                              }}
                            >
                              {shriramData.policyId}
                            </span>
                          </label>
                        )}

                        <p className="card-text">
                          Your Policy purchased successfully !
                        </p>

                        <div className="login-btn">
                          {selectedPlan.Api_name === "digit" ? (
                            <button
                              type="button"
                              className="submitButtonLogin"
                              onClick={() => {
                                digitKYCStatusApi();
                              }}
                            >
                              Download PDF
                            </button>
                          ) : selectedPlan.Api_name == "bajaj" ? (
                            <button
                              type="button"
                              className="submitButtonLogin"
                              onClick={() =>
                                downloadBajajPdf(
                                  bajajId,
                                  apiRequestQQ?.ApiUniqueNumber?.Bajaj
                                )
                              }
                            >
                              Download PDF
                            </button>
                          )  : selectedPlan.Api_name === "Tata AIG General Insurance Co. Ltd." || selectedPlan.Api_name === "ICICI Lombard General Insurance Co. Ltd."
                          ? (
                            <button
                              disabled={hideBtn} 
                              style={{ filter: hideBtn ? 'blur(2px)' : 'none' }}
                              type="button"
                              className="submitButtonLogin"
                              onClick={() =>
                                getPolicyDocument(caseId)
                              }
                            >
                              {spinner ? (
                                <div
                                  class="spinner-border text-light"
                                  role="status"
                                >
                                  <span class="sr-only"></span>
                                </div>
                              ) : (
                                "Download PDF"
                              )}
                            </button>
                          )
                          : selectedPlan.Api_name == "Magma" ? (
                            <button
                              disabled={hideBtn}
                              style={{ filter: hideBtn ? 'blur(2px)' : 'none' }}
                              type="button"
                              className="submitButtonLogin"
                              onClick={() =>
                                magmaPDFDownload(selectedPlan, apiRequestQQ)
                              }
                            >
                              {spinner ? (
                                <div
                                  class="spinner-border text-light"
                                  role="status"
                                >
                                  <span class="sr-only"></span>
                                </div>
                              ) : (
                                "Download PDF"
                              )}
                            </button>
                          ) : selectedPlan.Api_name == "Zuno" ? (
                            <>{click === false ? <button
                              type="button"
                              className="submitButtonLogin"
                              onClick={zunoPDFDownload}
                            >
                              {spinner ? (<div class="spinner-border text-light" role="status">
                                <span class="sr-only"></span>
                              </div>) : 'Download PDF'}
                            </button> : <button
                              type="button"
                              className="submitButtonLogin"
                              onClick={zunoSecondClick}
                            >
                              {spinner ? (<div class="spinner-border text-light" role="status">
                                <span class="sr-only"></span>
                              </div>) : 'Download PDF'}
                            </button>}</>
                          ) : selectedPlan.Api_name == "Kotak" ? (
                            <button
                              type="button"
                              className="submitButtonLogin"
                              onClick={() => downloadKotakPdf()}
                            >
                              Download PDF
                            </button>
                          ) : selectedPlan.Api_name === "HDFC" ? (
                            <button
                              type="button"
                              className="submitButtonLogin"
                              onClick={() => downloadHDFCPolicy()}
                            >
                              {pdfSpinner ? (
                                <div
                                  class="spinner-border text-light"
                                  role="status"
                                >
                                  <span class="sr-only">Loading...</span>
                                </div>
                              ) : (
                                <div>Download PDF</div>
                              )}
                            </button>
                          ) : selectedPlan.Api_name === "Future" ? (
                            <button
                              type="button"
                              className="submitButtonLogin"
                              onClick={() => downloadFutureGeneralPDF()}
                            >
                              Download PDF
                            </button>
                          ) : selectedPlan.Api_name === "Royal" ? (
                            <button
                              type="button"
                              className="submitButtonLogin"
                              onClick={() => downloadRoyalSundramPdf()}
                            >
                              Download PDF
                            </button>
                          ) : selectedPlan.Api_name == "Shriram" ? (
                            <button
                              type="button"
                              className="submitButtonLogin"
                              onClick={() =>
                                window.open(shriramData.pdfLink, "_blank")
                              }
                            >
                              Download PDF
                            </button>
                          ) : (
                            selectedPlan.Api_name === "Reliance" && (
                              <button
                                type="button"
                                className="submitButtonLogin"
                                onClick={() => downloadReliancePolicy()}
                              >
                                Download PDF
                              </button>
                            )
                          )}
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </Layout>
  );
};

export default SuccessMessage;

// import axios from 'axios'
// import React, { useState } from 'react'
// import { useEffect } from 'react'
// import { useDispatch, useSelector } from 'react-redux'
// import { Link, useLocation, useSearchParams } from 'react-router-dom'
// import { GetDataWithToken, PostDataWithToken } from '../../../api/apiHelper'
// import { dispatchQuickQuote, selectedPlanAction } from '../../../store/actions/userActions'
// import { createQuotePayloadObj } from '../../../store/reducers/userReducers'
// import {
//   downloadBajajPdf,
//   downloadBase64File,
//   downloadDigitPdf,
//   changePolicyStatus,
//   magmaPDFDownload
// } from '../Homepage/TPApiCall'
// import HeaderNew from '../HeaderNew'
// // import Header from '../common/Header'
// // import Layout from "../Layout";
// // var FileSaver = require("file-saver");

// const SuccessMessage = () => {
//   const selectedPlan = useSelector(state => state.root.selectedPlan)
//   const apiRequestQQ = useSelector(state => state.root.apiRequestQQ)
//   const [loading, setLoading] = useState(true)
//   const [searchParams, setSearchParams] = useSearchParams()
//   const bajajId = searchParams.get('p_policy_ref')
//   const kotakId = searchParams.get('vPolicyNumber')
//   const libertyId = searchParams.get('policyno')
//   const relianceId = searchParams.get('Output')
//   const [RoyalData, setRoyalData] = useState(null)
//   const [HDFC, setHDFC] = useState(null)
//   const [futureData, setFutureData] = useState(null)
//   const [shriramData, setShriramData] = useState({
//     policyId: '',
//     pdfLink: ''
//   })
//   const [magmaData, setMagmaData] = useState('')
//   const dispatch = useDispatch()
//   useEffect(() => {
//     if (selectedPlan.Api_name == 'digit') {
//       digitKYCStatusApi()
//     }
//     if (selectedPlan.Api_name == 'Shriram') {
//       let postData = {
//         ProposalNo: apiRequestQQ.policyId,
//         QuoteID: apiRequestQQ.ApiId
//       }
//       PostDataWithToken('motor/shriram-payment-status', postData).then(
//         response => {
//           if (response.status == true) {
//             console.log('response status', response.data)
//             if (response?.data?.MessageResult?.Result == 'Success') {
//               const policyData = response.data.Response[0]
//               setShriramData({
//                 policyId: policyData.PolicyNo,
//                 pdfLink: policyData.PolicyURL
//               })
//             } else {
//               alert(response.data.MessageResult.ErrorMessage)
//             }
//           }
//         }
//       )
//     }
//     if (selectedPlan.Api_name === 'HDFC' && loading === true) {
//       PostDataWithToken(
//         'motor/hdfcGeneratePolicy',
//         createQuotePayloadObj(apiRequestQQ)
//       )
//         .then(response => {
//           if (response.status === true) {
//             setLoading(false)
//             setHDFC({
//               Policy_Number: response.data.Policy_Details.PolicyNumber,
//               TransactionID: response.data.TransactionID
//             })
//           } else {
//             setLoading(true)
//             alert('Policy not issue try again')
//           }
//         })
//         .catch(err => console.log(err))
//     } else if (selectedPlan.Api_name === 'Future' && loading === true) {
//       let data = {
//         WS_P_ID: searchParams.get('WS_P_ID'),
//         TID: searchParams.get('TID'),
//         PGID: searchParams.get('PGID'),
//         Premium: searchParams.get('Premium'),
//         Response: searchParams.get('Response')
//       }
//       setFutureData(data)
//       dispatchQuickQuote('ApiId', data.WS_P_ID)
//       dispatchQuickQuote('PaymentId', data.PGID)
//       dispatchQuickQuote('PaymentAmount', data.Premium)
//       let newData = { ...apiRequestQQ }
//       newData.ApiId = data.WS_P_ID
//       newData.PaymentId = data.PGID
//       newData.PaymentAmount = data.Premium
//       console.log('api resuqes00----', newData)
//       if (data.Response == 'Success') {
//         setTimeout(() => {
//           PostDataWithToken(
//             'motor/futureGenralCreatePolicy',
//             createQuotePayloadObj(newData)
//           )
//             .then(response => {
//               if (response.status === true) {
//                 if (response.data.Root.Policy.Status === 'Successful') {
//                   setFutureData({
//                     ...futureData,
//                     PolicyNumber: response.data.Root.Policy.PolicyNo
//                   })
//                   setLoading(false)
//                 } else {
//                   alert(response.data.ErrorMessage)
//                 }
//               } else {
//                 sendErrorMessage(response)
//               }
//             })
//             .catch(err => console.log(err))
//         }, 500)
//       } else {
//         alert('something went wrong')
//       }
//     } else if (selectedPlan.Api_name === 'Royal' && loading === true) {
//       let postData = {
//         vehicleType: apiRequestQQ.VehicleType,
//         quoteId: selectedPlan?.ApiId
//       }
//       PostDataWithToken('motor/transection-check', postData).then(response => {
//         console.log(
//           'response of personal',
//           response.data.POLICY_NUMBER,
//           postData.vehicleType
//         )
//         if (response?.data?.data?.policyNumber) {
//           downloadRoyalSundramPdf(response.data.data)
//           setRoyalData(response.data.data)
//           setLoading(false)
//         }
//         // else if (response.data.POLICY_NUMBER && postData.vehicleType === "4w") {
//         //   setRoyalData(response.data);
//         //   downloadRoyalSundramPdf(response.data);
//         //   setLoading(false);
//         // }
//         else {
//           alert('Policy not purchased!pleasae try again')
//         }
//       })
//     } else if (selectedPlan.Api_name == 'Liberty') {
//       let postData = {
//         strCustomerGcId: selectedPlan.CustomerID,
//         strPolicyNumber: libertyId,
//         strProductType: getLibertyProductCode(
//           apiRequestQQ.VehicleType,
//           apiRequestQQ.NewPolicyType
//         )
//       }
//       PostDataWithToken('motor3/liberty-pdf-download', postData).then(
//         response => {
//           if (response.status === true) {

//             downloadBase64File(response.data, 'PEIB_' + libertyId)
//           }
//         }
//       )
//     } else if (selectedPlan.Api_name === "Magma"){

//       const postData = {
//         ProposalNumber : apiRequestQQ?.ProposalId,
//         ApiUniqueNumber: apiRequestQQ?.ApiUniqueNumber
//       }
//        PostDataWithToken("motor/proposalStatus", postData).then((response)=> {
//         console.log("status of magma number", response.data)
//         console.log("response?.data?.OutputResult?.PolicyNumber", response?.data?.OutputResult?.PolicyNumber)

//         if (response?.data?.OutputResult?.PolicyNumber) {
//           setLoading(false)
//           setMagmaData(response?.data?.OutputResult?.PolicyNumber)
//           const PolicyNumber = response?.data?.OutputResult?.PolicyNumber
//           const newData = {...selectedPlan, PolicyNumber:PolicyNumber}
//           dispatch(selectedPlanAction(newData))
//           // const postData = {
//           //   PolicyNumber : response?.data?.OutputResult?.PolicyNumber,
//           //   CustomerID : apiRequestQQ.CustomerID,
//           //   IntermediaryCode : "BRC0000388",
//           //   ProposalNumber : apiRequestQQ.ProposalId,
//           // }
//           // console.log("postdata in pdf", postData)
//           // PostDataWithToken("motor/magma-pdf", postData)
//           // .then((response) => {
//           //    console.log("response in magma pdf", response?.data?.OutputResult?.PolicyBase64String)
//           //    if(response?.data?.OutputResult?.PolicyBase64String){
//           //     downloadBase64File(response?.data?.OutputResult?.PolicyBase64String, "PEIB_Magma_PDF");
//           //    } else {
//           //     setLoading(false);
//           //    }
//           // })
//         }
//        })

//             // const data = {
//             //   PolicyNumber: "P0024000100/4101/100600",
//             //   CustomerID: "20003870540",
//             //   IntermediaryCode: "BRC0000388",
//             //   ProposalNumber: "202401080000490",
//             // }

//     } else {
//       setLoading(false)
//     }
//   }, [])

//   useEffect(() => {
//     if (selectedPlan.Api_name === 'HDFC' && HDFC != null) {
//       changePolicyStatus('policy-generated', apiRequestQQ.leadId)
//       downloadHDFCPolicy()
//     }
//     if (selectedPlan.Api_name === 'Kotak' && kotakId) {
//       changePolicyStatus('policy-generated', apiRequestQQ.leadId)
//       downloadKotakPdf()
//     }

//     if (selectedPlan.Api_name === 'bajaj' && bajajId) {
//       changePolicyStatus('policy-generated', apiRequestQQ.leadId)
//       downloadBajajPdf()
//     }
//     if (selectedPlan.Api_name && relianceId) {
//       changePolicyStatus('policy-generated', apiRequestQQ.leadId)
//       downloadReliancePolicy()
//     }
//   }, [HDFC, kotakId, bajajId, relianceId])

//   const downloadHDFCPolicy = () => {
//     let postData = {
//       VehicleType: apiRequestQQ.VehicleType,
//       NewPolicyType: apiRequestQQ.NewPolicyType,
//       TransactionID: HDFC?.TransactionID,
//       Policy_Number: HDFC?.Policy_Number
//     }
//     PostDataWithToken('motor/hdfc-pdf-download', postData).then(res => {
//       if (res.status === true) {
//         downloadBase64File(
//           res.data.Resp_Policy_Document.PDF_BYTES,
//           'PEIB_' + HDFC?.Policy_Number
//         )
//         // window.location.href = res.data.schedulePath;
//       }
//     })
//   }

//   const downloadKotakPdf = () => {
//     let postData = {
//       vProposalNumber: selectedPlan.vProposalNumber,
//       vPolicyNumber: kotakId,
//       vProductCode: selectedPlan?.vProductCode
//     }
//     PostDataWithToken('motor/kotak-pdf-download', postData).then(res => {
//       if (res.status === true) {
//         console.log('response data', res.data)
//         downloadBase64File(res.data, 'PEIB_' + kotakId)
//         // window.location.href = res.data.schedulePath;
//       }
//     })
//   }

//   const downloadReliancePolicy = () => {
//     let postData = {
//       PolicyNo: relianceId.split('|')[1],
//       ProductCode: getRelianceProductType(
//         apiRequestQQ.VehicleType,
//         apiRequestQQ.NewPolicyType,
//         apiRequestQQ.IsVehicleNew
//       ),
//       quoteID: apiRequestQQ.ApiId
//     }
//     let url = `https://rgipartners.reliancegeneral.co.in/API/Service/GeneratePolicyschedule?PolicyNo=${postData.PolicyNo}&ProductCode=${postData.ProductCode}`
//     postData.url = url
//     PostDataWithToken('motor/reliance-pdf-download', postData)
//       .then(res => console.log(res))
//       .catch(err => console.log(err))
//     window.open(url, '_blank')
//   }

//   const getRelianceProductType = (vehicleType, policyType, isNew) => {
//     if (isNew == true && vehicleType == '4w') {
//       return 2374
//     } else if (
//       isNew == false &&
//       vehicleType === '4w' &&
//       policyType === 'Comprehensive'
//     ) {
//       return 2311
//     } else if (
//       isNew == false &&
//       vehicleType === '4w' &&
//       policyType === 'ThirdParty'
//     ) {
//       return 2347
//     } else if (
//       isNew == false &&
//       vehicleType === '4w' &&
//       policyType === 'ODOnly'
//     ) {
//       return 2309
//     } else if (isNew === true && vehicleType == '2w') {
//       return 2375
//     } else if (
//       isNew == false &&
//       vehicleType === '2w' &&
//       policyType === 'Comprehensive'
//     ) {
//       return 2312
//     } else if (
//       isNew == false &&
//       vehicleType === '2w' &&
//       policyType === 'ThirdParty'
//     ) {
//       return 2348
//     } else if (
//       isNew == false &&
//       vehicleType === '2w' &&
//       policyType === 'ODOnly'
//     ) {
//       return 2308
//     } else if (
//       isNew === false &&
//       vehicleType == '2w' &&
//       policyType === 'Bundled'
//     ) {
//       return 2375
//     } else if (
//       isNew == false &&
//       vehicleType == '4w' &&
//       policyType === 'Bundled'
//     ) {
//       return 2374
//     }
//     // else if (
//     //   (vehicleType === "pcv" && PolicyType === "Comprehensive") ||
//     //   (vehicleType === "gcv" && PolicyType === "Comprehensive")
//     // ) {
//     //   return 20301;
//     // } else if (
//     //   (vehicleType === "pcv" && PolicyType === "ThirdParty") ||
//     //   (vehicleType === "gcv" && PolicyType === "ThirdParty")
//     // ) {
//     //   return 20302;
//     // }
//   }

//   const downloadFutureGeneralPDF = () => {
//     let postData = {
//       PolicyNumber: futureData.PolicyNumber
//     }
//     PostDataWithToken('motor/future-pdf-download', postData).then(res => {
//       if (res.status === true) {
//         let pdfData =
//           res.data.GetPDFResponse.GetPDFResult.DocumentElement.PDFBytes
//         downloadBase64File(pdfData, 'PEIB_' + postData.PolicyNumber)
//         // window.location.href = res.data.schedulePath;
//       }
//     })
//   }
//   const downloadRoyalSundramPdf = args => {
//     let royalData = args ? args : RoyalData
//     let postData
//     // if (apiRequestQQ.VehicleType == "2w") {
//     postData = {
//       policyNumber: royalData.policyNumber,
//       expiryDate: royalData.expiryDate,
//       IsVehicleNew: apiRequestQQ.IsVehicleNew,
//       vehicleType: apiRequestQQ.VehicleType
//     }
//     // } else if (apiRequestQQ.VehicleType == "4w") {
//     // postData = {
//     //   policyNumber: royalData.POLICY_NUMBER,
//     //   expiryDate: royalData?.response?.PREMIUMDETAILS?.DATA.POLICY_EXPIRY_DATE,
//     //   IsVehicleNew: apiRequestQQ.IsVehicleNew,
//     //   vehicleType: apiRequestQQ.VehicleType,
//     // };
//     // }
//     console.log('roual pdf sownoad', postData)
//     PostDataWithToken('motor/royal-pdf-download', postData).then(res => {
//       if (res.status === true) {
//         if (res?.data?.data?.base64File) {
//           if (args) {
//             sendPolicyPdfToEmail(
//               postData.policyNumber,
//               res.data.data.base64File
//             )
//           } else {
//             downloadBase64File(
//               res.data.data.base64File,
//               'PEIB_' + postData.policyNumber
//             )
//           }
//         }
//         // window.location.href = res.data.schedulePath;
//       }
//     })
//   }

//   const sendPolicyPdfToEmail = (policyNumber, base64) => {
//     let postData = {
//       name: apiRequestQQ.FirstName,
//       amount: selectedPlan.FinalPremium,
//       quoteId: policyNumber,
//       email: apiRequestQQ.Email,
//       base64: base64
//     }
//     PostDataWithToken('motor/send-policy', postData).then(response => {
//       console.log('email sent')
//     })
//   }

//   const digitKYCStatusApi = () => {
//     debugger
//     let postData = {
//       applicationId: apiRequestQQ.applicationId,
//       policyNumber: apiRequestQQ.ApiId
//     }
//     PostDataWithToken('kyc/digit-kyc', postData).then(response => {
//       console.log('Digit KYC RES', response)
//       if (response.status === true) {
//         if (response.data.kycVerificationStatus != 'DONE') {
//           console.log('respo', response.data.link)
//           alert('You have to complete your digit kyc first')
//           window.open(response.data.link, '_blank')
//         } else if (response.data.kycVerificationStatus == 'DONE') {
//           downloadDigitPdf(apiRequestQQ.applicationId)
//         }
//       }
//     })
//   }

//   const getLibertyProductCode = (vehicleType, policyType) => {
//     if (vehicleType === '4w' && policyType === 'Comprehensive') {
//       return 3151
//     } else if (vehicleType === '2w' && policyType === 'Comprehensive') {
//       return 3152
//     } else if (vehicleType === '4w' && policyType === 'ThirdParty') {
//       return 3155
//     } else if (vehicleType === '4w' && policyType === 'ThirdParty') {
//       return 3158
//     } else if (vehicleType === '4w' && policyType === 'ODOnly') {
//       return 3140
//     } else if (vehicleType === '2w' && policyType === 'ODOnly') {
//       return 3141
//     }
//   }
//   return (
//     <>
//       {/* <HeaderNew /> */}
//       {loading === true ? (
//         <div className='card-body m-5'>
//           <h1 className='card-title'>Loading.............</h1>
//           <h6 className='card-subtitle mb-2 text-muted'>Please Wait.....</h6>
//         </div>
//       ) : (
//         <section className='mylogin mt-5 py-5'>
//           <div className='container'>
//             <div className='row justify-content-between align-items-center'>
//               <div className='col-lg-6 col-12 d-lg-block d-none'>
//                 <img
//                   src='/assets/images/LoginPageImg.png'
//                   className='w-100'
//                   alt=''
//                 />
//               </div>
//               <div
//                 className='col-lg-5 ms-auto'
//                 style={{
//                   padding: '40px',
//                   background: '#fff',
//                   boxShadow: '0px 0px 15px 3px rgb(157 150 150)'
//                 }}
//               >
//                 <nav
//                   className='detilsNav'
//                   style={{
//                     background: '#00b0cb',
//                     borderRadius: '5px',
//                     fontWeight: '700',
//                     padding: 0,
//                     color: 'white'
//                   }}
//                 >
//                   <p className='pay fs-3 text-center p-1'>Payment Successful</p>
//                 </nav>
//                 <h1 className='fs-3 p-1 mt-2'>Congratulations</h1>
//                 <h2 className='fs-5 p-1'>Your Policy purchased Successfully</h2>

//                 {/* HDFC */}
//                 {selectedPlan.Api_name === 'HDFC' && (
//                   <>
//                     <h2 className='fs-5 p-1'>
//                       Policy Number -{' '}
//                       <span className='fw-bold'>{HDFC?.Policy_Number}</span>{' '}
//                     </h2>
//                     <h2 className='fs-5 p-1 mb-4'>
//                       Transaction Number -{' '}
//                       <span className='fw-bold'>{HDFC?.TransactionID}</span>
//                     </h2>
//                   </>
//                 )}

//                 {/* DIGIT */}
//                 {selectedPlan.Api_name === 'digit' && (
//                   <h2 className='fs-5 p-1'>
//                     Policy ID -{' '}
//                     <span className='fw-bold'>
//                       {apiRequestQQ.applicationId}
//                     </span>{' '}
//                   </h2>
//                 )}

//                 {/* BAJAJ */}
//                 {selectedPlan?.Api_name === 'bajaj' && (
//                   <h2 className='fs-5 p-1'>
//                     Policy Number - <span className='fw-bold'>{bajajId}</span>{' '}
//                   </h2>
//                 )}

//                 {/* KOTAK */}
//                 {selectedPlan.Api_name == 'Kotak' && (
//                   <>
//                     <h2 className='fs-5 p-1'>
//                       Proposal Number -{' '}
//                       <span className='fw-bold'>
//                         {selectedPlan.vProposalNumber}
//                       </span>{' '}
//                     </h2>
//                     <h2 className='fs-5 p-1 mb-4'>
//                       Policy Number - <span className='fw-bold'>{kotakId}</span>
//                     </h2>
//                   </>
//                 )}

//                 {/* FUTURE */}
//                 {selectedPlan.Api_name === 'Future' && (
//                   <>
//                     <h2 className='fs-5 p-1'>
//                       Policy Number -{' '}
//                       <span className='fw-bold'>{futureData.PolicyNumber}</span>{' '}
//                     </h2>
//                   </>
//                 )}

//               {selectedPlan.Api_name === "Magma" && <>Policy Number {magmaData}</>}

//                 {/* RELIANCE */}
//                 {selectedPlan.Api_name === 'Reliance' && (
//                   <h2 className='fs-5 p-1'>
//                     Policy Number -{' '}
//                     <span className='fw-bold'>{relianceId.split('|')[1]}</span>{' '}
//                   </h2>
//                 )}

//                 {/* ROYAL */}
//                 {selectedPlan.Api_name === 'Royal' && (
//                   <>
//                     <h2 className='fs-5 p-1'>
//                       Policy Number -{' '}
//                       <span className='fw-bold'>
//                         {apiRequestQQ.VehicleType === '2w'
//                           ? RoyalData?.policyNumber
//                           : apiRequestQQ.VehicleType === '4w' &&
//                             RoyalData?.policyNumber}
//                       </span>{' '}
//                     </h2>
//                   </>
//                 )}

//                 {/* SHRIRAM */}
//                 {selectedPlan.Api_name === 'Shriram' && (
//                   <h2 className='fs-5 p-1'>
//                     Policy Number -{' '}
//                     <span className='fw-bold'>{shriramData.policyId}</span>{' '}
//                   </h2>
//                 )}

//                 {/* DownLoad Button */}
//                 {selectedPlan.Api_name === 'digit' ? (
//                   <button
//                     className='fs-3'
//                     style={{
//                       color: 'white',
//                       width: '100%',
//                       background: '#00b0cb',
//                       textAlign: 'center',
//                       fontWeight: '700',
//                       border: 'none',
//                       padding: '2px',
//                       borderRadius: '5px'
//                     }}
//                     onClick={() => {
//                       digitKYCStatusApi()
//                     }}
//                   >
//                     Download PDF
//                   </button>
//                 ) : selectedPlan.Api_name == 'bajaj' ? (
//                   <button
//                     className='fs-3'
//                     style={{
//                       color: 'white',
//                       width: '100%',
//                       background: '#00b0cb',
//                       textAlign: 'center',
//                       fontWeight: '700',
//                       border: 'none',
//                       padding: '2px',
//                       borderRadius: '5px'
//                     }}
//                     onClick={() => downloadBajajPdf(bajajId)}
//                   >
//                     Download PDF
//                   </button>
//                 ) : selectedPlan.Api_name == 'Kotak' ? (
//                   <button
//                     className='fs-3'
//                     style={{
//                       color: 'white',
//                       width: '100%',
//                       background: '#00b0cb',
//                       textAlign: 'center',
//                       fontWeight: '700',
//                       border: 'none',
//                       padding: '2px',
//                       borderRadius: '5px'
//                     }}
//                     onClick={() => downloadKotakPdf()}
//                   >
//                     Download PDF
//                   </button>
//                 ): selectedPlan.Api_name == "Magma" ? (
//                   <button className="btn btn-primary fs-3 px-3 py-2 mt-1" onClick={() => magmaPDFDownload(selectedPlan, apiRequestQQ)}>
//                     Download PDF
//                   </button>
//                 ) : selectedPlan.Api_name === 'HDFC' ? (
//                   <button
//                     className='fs-3'
//                     style={{
//                       color: 'white',
//                       width: '100%',
//                       background: '#00b0cb',
//                       textAlign: 'center',
//                       fontWeight: '700',
//                       border: 'none',
//                       padding: '2px',
//                       borderRadius: '5px'
//                     }}
//                     onClick={() => downloadHDFCPolicy()}
//                   >
//                     Download PDF
//                   </button>
//                 ) : selectedPlan.Api_name === 'Future' ? (
//                   <button
//                     className='fs-3'
//                     style={{
//                       color: 'white',
//                       width: '100%',
//                       background: '#00b0cb',
//                       textAlign: 'center',
//                       fontWeight: '700',
//                       border: 'none',
//                       padding: '2px',
//                       borderRadius: '5px'
//                     }}
//                     onClick={() => downloadFutureGeneralPDF()}
//                   >
//                     Download PDF
//                   </button>
//                 ) : selectedPlan.Api_name === 'Royal' ? (
//                   <button
//                     className='fs-3'
//                     style={{
//                       color: 'white',
//                       width: '100%',
//                       background: '#00b0cb',
//                       textAlign: 'center',
//                       fontWeight: '700',
//                       border: 'none',
//                       padding: '2px',
//                       borderRadius: '5px'
//                     }}
//                     onClick={() => downloadRoyalSundramPdf()}
//                   >
//                     Download PDF
//                   </button>
//                 ) : selectedPlan.Api_name == 'Shriram' ? (
//                   <button
//                     className='fs-3'
//                     style={{
//                       color: 'white',
//                       width: '100%',
//                       background: '#00b0cb',
//                       textAlign: 'center',
//                       fontWeight: '700',
//                       border: 'none',
//                       padding: '2px',
//                       borderRadius: '5px'
//                     }}
//                     onClick={() => window.open(shriramData.pdfLink, '_blank')}
//                   >
//                     Download PDF
//                   </button>
//                 ) : (
//                   selectedPlan.Api_name === 'Reliance' && (
//                     <button
//                       className='fs-3'
//                       style={{
//                         color: 'white',
//                         width: '100%',
//                         background: '#00b0cb',
//                         textAlign: 'center',
//                         fontWeight: '700',
//                         border: 'none',
//                         padding: '2px',
//                         borderRadius: '5px'
//                       }}
//                       onClick={() => downloadReliancePolicy()}
//                     >
//                       Download PDF
//                     </button>
//                   )
//                 )}

//                 {/* <button
//                   className='fs-3'
//                   style={{
//                     color: 'white',
//                     width: '100%',
//                     background: '#00b0cb',
//                     textAlign: 'center',
//                     fontWeight: '700',
//                     border: 'none',
//                     padding: '2px',
//                     borderRadius: '5px'
//                   }}
//                 >
//                   Download PDF
//                 </button> */}
//               </div>
//             </div>
//           </div>
//
//         </section>
//       )}
//     </>
//   )
// }

// export default SuccessMessage
