import React from "react";
import "./Shimmer.css";

const Shimmer = () => {

  return (
    <div className="shimmer-outer">
      {[1].map((index) => (
        <div key={index} className="shimmer">
          <div className="shimmer-inner">Loading ...</div>
        </div>
      ))}
    </div>
  );

// return (
//     <div className="shimmer-outer">
//       {(() => {
//         const shimmers = [];
//         for (let i = QuickQouteResult.lenght+5; i > 0; i--) {
//           shimmers.push(
//             <div key={i} className="shimmer">
//               <div className="shimmer-inner">Loading ...</div>
//             </div>
//           );
//         }
//         return shimmers;
//       })()}
//     </div>
//   );
};

export default Shimmer;
