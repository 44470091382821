import React from "react";
import { useSelector } from "react-redux";
import { APPURL } from "../../../api/apiHelper";

const LibertyPayment = () => {
  const selectedPlan = useSelector((state) => state.root.selectedPlan);
  const apiRequestQQ = useSelector((state) => state.root.apiRequestQQ);
  return (
    <form action="https://api-uat.libertyinsurance.in/TPPayment/Home/CapturePayment" method="POST">
      <input name="txnid" type="hidden" value={selectedPlan.ApiId} />

      <input name="quotationNumber" type="hidden" defaultValue={selectedPlan.ApiId} />

      <input name="amount" type="hidden" defaultValue={selectedPlan.FinalPremium} />

      <input name="productinfo" type="hidden" defaultValue="Payment for Liberty GI" />

      <input name="SURL" type="hidden" defaultValue={`${APPURL()}api/v1/motor3/liberty-payment-success`} />

      <input name="FURL" type="hidden" defaultValue={`${APPURL()}api/v1/motor3/liberty-payment-error`} />

      <input name="FURL" type="hidden" defaultValue="TPService" />

      <input name="FirstName" type="hidden" defaultValue={apiRequestQQ.FirstName} />

      <input name="Email" type="hidden" defaultValue={apiRequestQQ.Email} />

      <input name="Phone" type="hidden" defaultValue={apiRequestQQ.MobileNumber} />

      <input name="customerID" type="hidden" defaultValue={selectedPlan.CustomerID} />

      <button type="submit" className="btn btn-primary fs-3 px-4 py-2">
        Buy <span> {selectedPlan.FinalPremium}</span>
      </button>
    </form>
  );
};

export default LibertyPayment;
