import React from "react";

const BikeInsuranceDetails = () => {
  return (
    <div>
      {" "}
      <div className="abouthealthins">
        <div className="healthinsimages">
          <img
            src={"/assetss/images/bikeinsuimg01.png"}
            alt="img"
            className="img-fluid"
          />
        </div>
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-lg-7">
              <h2>
                Know More about <span>Bike Insurance</span>
              </h2>
              <ul>
                <li className="checkmark">
                  <div>
                    <img
                      src="/assetss/images/bluecheck.png"
                      alt="blue-checkmark"
                    />
                  </div>
                  Contrary to popular belief, Lorem Ipsum is not simply random
                  text. It has roots in a piece of classNameical Latin
                  literature from 45 BC, making it over 2000 years old. Richard
                  McClintock
                </li>

                <li className="checkmark">
                  <div>
                    <img
                      src="/assetss/images/bluecheck.png"
                      alt="blue-checkmark"
                    />
                  </div>
                  There are many variations of passages of Lorem Ipsum
                  available, but the majority have suffered alteration in some
                  form, by injected humour, or randomised words which don't look
                  even slightly believable. If you are going to use a passage of
                  Lorem Ipsum, you need to be sure there isn't anything
                  embarrassing hidden in the middle of text. All the Lorem Ipsum
                  generators on the Internet tend to repeat predefined chunks as
                  necessary,
                </li>
                <li className="checkmark">
                  <div>
                    <img
                      src="/assetss/images/bluecheck.png"
                      alt="blue-checkmark"
                    />
                  </div>
                  The standard chunk of Lorem Ipsum used since the 1500s is
                  reproduced below for those interested. Sections 1.10.32 and
                  1.10.33 from "de Finibus Bonorum et Malorum
                </li>
                <li className="checkmark">
                  <div>
                    <img
                      src="/assetss/images/bluecheck.png"
                      alt="blue-checkmark"
                    />
                  </div>
                  If you use this site regularly and would like to help keep the
                  site on the Internet, please consider donating a small sum to
                  help pay for the hosting and bandwidth bill. There is no
                  minimum donation, any sum is appreciated
                </li>
              </ul>
              <div className="healthinsimgsm">
                <img
                  src={"/assetss/images/twowheelerinsimg01.png"}
                  alt="img"
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="abouthealthins boxrevers">
        <div className="healthinsimages">
          <img
            src={"/assetss/images/benefitinsuranceimg01.png"}
            alt="img"
            className="img-fluid"
          />
        </div>
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-lg-5"></div>
            <div className="col-md-12 col-lg-7">
              <h2>
                Benefits of <span>Bike Insurance</span>
              </h2>
              <ul>
                <li className="checkmark">
                  <div>
                    <img
                      src="/assetss/images/bluecheck.png"
                      alt="blue-checkmark"
                    />
                  </div>
                  Contrary to popular belief, Lorem Ipsum is not simply random
                  text. It has roots in a piece of classNameical Latin
                  literature from 45 BC, making it over 2000 years old. Richard
                  McClintock
                </li>
                <li className="checkmark">
                  <div>
                    <img
                      src="/assetss/images/bluecheck.png"
                      alt="blue-checkmark"
                    />
                  </div>
                  There are many variations of passages of Lorem Ipsum
                  available, but the majority have suffered alteration in some
                  form, by injected humour, or randomised words which don't look
                  even slightly believable. If you are going to use a passage of
                  Lorem Ipsum, you need to be sure there isn't anything
                  embarrassing hidden in the middle of text. All the Lorem Ipsum
                  generators on the Internet tend to repeat predefined chunks as
                  necessary,
                </li>
                <li className="checkmark">
                  <div>
                    <img
                      src="/assetss/images/bluecheck.png"
                      alt="blue-checkmark"
                    />
                  </div>
                  The standard chunk of Lorem Ipsum used since the 1500s is
                  reproduced below for those interested. Sections 1.10.32 and
                  1.10.33 from "de Finibus Bonorum et Malorum
                </li>
                <li className="checkmark">
                  <div>
                    <img
                      src="/assetss/images/bluecheck.png"
                      alt="blue-checkmark"
                    />
                  </div>
                  If you use this site regularly and would like to help keep the
                  site on the Internet, please consider donating a small sum to
                  help pay for the hosting and bandwidth bill. There is no
                  minimum donation, any sum is appreciated
                </li>
              </ul>
              <div className="healthinsimgsm">
                <img
                  src={"/assetss/images/twowheelerinsimg02.png"}
                  alt="img"
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BikeInsuranceDetails;
