import React from "react";
import "./footerpages.css";
import Header from "../Header";
import Footer from "../Footer";

function InvestmentPlan() {
  return (
    <>
      <div>
        <Header />
        <div className="investment-cantainer">
          <h1>Benefits of Life Insurance</h1>
        </div>
        <div className="cantainer">
          <div className="box-info">
            <div className="box-1">
              <h2>
                "Insuring Your Future: Exploring the Benefits of
                Insurance-Linked Investment Plans"
              </h2>
              <ul>
                <li>
                  <ol>
                    {" "}
                    <strong>Insurance Protection:</strong> It offers life
                    insurance coverage to protect you and your loved ones
                    against unforeseen events, providing financial security and
                    peace of mind.
                  </ol>
                </li>
                <br />
                <li>
                  <ol>
                    {" "}
                    <strong>Premium Payment:</strong> You pay regular premiums,
                    a portion of which goes towards insurance coverage and the
                    remaining is invested in various funds or portfolios based
                    on your risk tolerance and preferences.
                  </ol>
                </li>
                <br />
                <li>
                  <ol>
                    <strong>Investment Options:</strong> These plans offer a
                    range of investment funds to choose from, such as equity,
                    debt, or balanced funds, allowing you to tailor your
                    investments to your financial goals.
                  </ol>
                </li>
                <br />
                <li>
                  <ol>
                    <strong>Flexibility: </strong>You have the flexibility to
                    switch between investment funds according to market
                    conditions or your changing financial objectives.
                  </ol>
                </li>
                <br />
                <li>
                  <ol>
                    {" "}
                    <strong>Potential Returns: </strong>The value of your
                    investment varies based on the performance of the chosen
                    funds. Over time, it has the potential to grow, helping you
                    accumulate wealth.
                  </ol>
                </li>
                <br />
                <li>
                  <ol>
                    <strong>Lock-in Period and Surrender Charges:</strong> There
                    is typically a lock-in period during which surrendering the
                    policy may incur charges. It's essential to understand the
                    terms and conditions related to surrendering the policy.
                  </ol>
                </li>
              </ul>
            </div>
            <div className="box-2 investmentPlan">
              <img src="/assetss/images/investmentPlan.png" alt="Erroe" />
            </div>
          </div>
        </div>
        <div className="vision-info">
          <div className="vision-box">
            <div className="img-box">
              <img src="/assetss/images/vision.png" alt="Error" />
            </div>
            <div className="vision-box1">
              <h2>Our vision</h2>
              <p>
                There are many variations of passages of Lorem Ipsum available,
                but the majority have suffered alteration in some form, by
                injected humour, or randomised words which don't look even
                slightly believable. If you are going to use a passage of Lorem
                Ipsum, you need to be sure there isn't anything embarrassing
                hidden in the middle of text. All the Lorem Ipsum generators on
                the Internet tend to repeat predefined chunks as necessary,
              </p>
            </div>
          </div>
        </div>
        <div className="btn-cantainer">
          <div className="btn-1">
            <h1>Solving your Insurance Needs on Time</h1>
          </div>
          <div className="btn-1">
            <h2>
              Get world-class insurance coverage that not only protects you but
              also helps you build wealth. Let us be your insurance partner.
            </h2>
            <div className="btn-info">
              <button>Get Started</button>
              <button>Contact Us</button>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}

export default InvestmentPlan;
