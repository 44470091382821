export const BankNames = [
  "State Bank of India",
  "Punjab National Bank",
  "Bank of Baroda",
  "Canara Bank",
  "Union Bank of India",
  "Bank of India",
  "Indian Bank",
  "Central Bank of India",
  "Indian Overseas Bank",
  "UCO Bank",
  "Bank of Maharashtra",
  "Punjab & Sindh Bank",
  "Axis Bank",
  "Bandhan Bank",
  "Catholic Syrian Bank",
  "City Union Bank",
  "DCB Bank",
  "Dhanlaxmi Bank",
  "Federal Bank",
  "HDFC Bank",
  "ICICI Bank",
  "IDBI Bank",
  "IDFC First Bank",
  "IndusInd Bank",
  "Jammu & Kashmir Bank",
  "Karnataka Bank",
  "Karur Vysya Bank",
  "Kotak Mahindra Bank",
  "Lakshmi Vilas Bank",
  "Nainital Bank",
  "RBL Bank",
  "South Indian Bank",
  "Tamilnad Mercantile Bank Limited",
  "Yes Bank",
];

export const PreviousInsurer = [
  { id: 1, Digit_Code: "159", Name: "Acko General Insurance Ltd" },
  {
    id: 2,
    Digit_Code: "153",
    Name: "Aditya Birla Health Insurance Co Limited",
  },
  {
    id: 3,
    Digit_Code: "126",
    Name: "Agriculture Insurance Co of India Ltd.",
  },
  {
    id: 4,
    Digit_Code: "131",
    Name: "Apollo Munich Health Insurance Co Ltd",
  },
  {
    id: 5,
    Digit_Code: "113",
    Name: "Bajaj Allianz General Insurance Company Limited",
  },
  {
    id: 6,
    Digit_Code: "139",
    Name: "BHARATI AXA GENERAL INSURANCE CO LTD",
  },
  {
    id: 7,
    Digit_Code: "123",
    Name: "CHOLAMANDALAM MS GENERAL INSURANCE CO.LTD.",
  },
  {
    id: 8,
    Digit_Code: "151",
    Name: "Cigna TTK Health Insurance Company Ltd. ",
  },
  {
    id: 9,
    Digit_Code: "XXX",
    Name: "DHFL General Insurance Ltd",
  },
  {
    id: 10,
    Digit_Code: "161",
    Name: "Edelweiss General Insurance Co Ltd",
  },
  {
    id: 11,
    Digit_Code: "124",
    Name: "Export Credit Guarantee Corporation of India Ltd.",
  },
  {
    id: 12,
    Digit_Code: "132",
    Name: "FUTURE GENERALI INDIA INSURANCE COMPANY LTD.",
  },
  { id: 13, Digit_Code: "158", Name: "Go Digit General Insurance Ltd", HDFC: null, Bajaj: " 34", HDFCSHORTCODE: null },
  {
    id: 14,
    Digit_Code: "125",
    Name: "HDFC ERGO GENERAL INSURANCE COMPANY LTD.",
  },
  {
    id: 15,
    Digit_Code: "115",
    Name: "ICICI Lombard General Insurance Company Limited",
  },
  {
    id: 16,
    Digit_Code: "106",
    Name: "IFFCO TOKIO General Insurance Company Limited",
  },
  {
    id: 17,
    Digit_Code: "152",
    Name: "KOTAK MAHINDRA GENERAL INSURANCE COMPANY LTD.",
  },
  { id: 18, Digit_Code: "150", Name: "Liberty General Insurance Ltd" },
  {
    id: 19,
    Digit_Code: "149",
    Name: "Magma HDI General Insurance Co Ltd",
  },
  {
    id: 20,
    Digit_Code: "145",
    Name: "Max Bupa Health Insurance Company Ltd.",
  },
  {
    id: 21,
    Digit_Code: "58",
    Name: "National Insurance Company Limited",
  },
  {
    id: 22,
    Digit_Code: "ONA",
    Name: "Others  Not available",
  },
  {
    id: 23,
    Digit_Code: "141",
    Name: "Raheja QBE General Insurance Co Ltd",
  },
  {
    id: 24,
    Digit_Code: "103",
    Name: "Reliance General Insurance Co Ltd",
  },
  {
    id: 25,
    Digit_Code: "148",
    Name: "Religare Health Insurance Company Limited",
  },
  {
    id: 26,
    Digit_Code: "102",
    Name: "Royal Sundaram General Insurance Co Ltd",
  },
  {
    id: 27,
    Digit_Code: "144",
    Name: "SBI General Insurance Co Ltd",
  },
  {
    id: 28,
    Digit_Code: "137",
    Name: "SHRIRAM GENERAL INSURANCE COMPANY LTD",
  },
  {
    id: 29,
    Digit_Code: "129",
    Name: "Star Health And Allied Ins Co Ltd",
  },
  {
    id: 30,
    Digit_Code: "108",
    Name: "Tata AIG General Insurance Co Ltd",
  },
  { id: 31, Digit_Code: "190", Name: "The New India Assurance Co Ltd" },
  {
    id: 32,
    Digit_Code: "556",
    Name: "Oriental Insurance Company Limited",
  },
  {
    id: 33,
    Digit_Code: "545",
    Name: "United India Insurance Company Limited",
  },
  {
    id: 34,
    Digit_Code: "134",
    Name: "Universal Sompo General Insurance Co Ltd",
  },
  {
    id: 35,
    Digit_Code: "XXX",
    Name: "L&T General Insurance Company Limited",
  },
  {
    id: 36,
    Digit_Code: "XXX",
    Name: "GUJRAT GOVERNMENT INSURANCE FUND",
  },
];
