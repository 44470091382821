import moment from "moment";
import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { GetDataWithToken, PostDataWithToken } from "../../../../api/apiHelper";
import { inspectionQuickQuoteSet, selectedPlanAction } from "../../../../store/actions/userActions";
import { digitPaymentApi } from "../../../common/Homepage/TPApiCall";
import Layout from "../../../common/Layout";

const MyPolicy = () => {
  const [myPolicies, setMyPolicies] = useState([]);
  const [policyStatus, setPolicyStatus] = useState({
    status: "",
    payment: false,
    applicationId: "",
    index: null,
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    GetDataWithToken("motor/get-leads", "").then((response) => {
      if (response.status === true) {
        setMyPolicies(response.data);
      }
    });
  }, []);

  const checkPolicyStatus = (policyNo, insurer, index) => {
    if (insurer == "Go Digit General Insurance Ltd") {
      PostDataWithToken("motor/search-digit", { policyNo: policyNo && policyNo.trim() }).then((response) => {
        if (response.status === true) {
          setPolicyStatus({
            status: response.data.policyStatus,
            payment: response.data.policyStatus === "INCOMPLETE" ? true : false,
            applicationId: response.data.applicationId,
            index: index,
          });
        }
      });
    } else if (insurer == "FUTURE GENERALI INDIA INSURANCE COMPANY LTD.") {
      GetDataWithToken(`motor/future-inspection-status/${policyNo}`, "").then((response) => {
        if (response.status === true) {
          {
            const data = response.data;
            setPolicyStatus({
              status: data.data.result && data.data.result[0].status,
              payment: data.data.result && data.data.result[0].status === "accepted" ? true : false,
              applicationId: data.data.result && data.data.result[0].refId,
              index: index,
            });
          }
        }
      });
    }
  };

  const makePayment = (insurer, item) => {
    if (insurer == "Go Digit General Insurance Ltd") {
      digitPaymentApi(policyStatus.applicationId);
    } else if (insurer == "FUTURE GENERALI INDIA INSURANCE COMPANY LTD.") {
      dispatch(inspectionQuickQuoteSet(createPolicyObject(item)));
      dispatch(selectedPlanAction(createSelectedPlan(item)));
      navigate("/policy-overview");
    }
  };

  const getCompanyStatus = (item, i) => {
    if (item.insurance_company == "FUTURE GENERALI INDIA INSURANCE COMPANY LTD.") {
      checkPolicyStatus(item?.inspection_no, item?.insurance_company, i);
    } else {
      checkPolicyStatus(item?.policy_no, item?.insurance_company, i);
    }
  };
  return (
    <Layout>
      <section className="policyPUrView mt-5 pt-5">
        <div className="container-fluid px-lg-5 px-3">
          <div className="row justify-content-between align-items-center">
            <div className="col-lg-10 col-12 text-center mx-auto mt-5 pt-5 pb-lg-5 mb-lg-5">
              <h1 className="text-capitalize about-heading mx-auto d-inline-block px-5">My Policies</h1>
              <table className="table mt-5 table-bordered table-striped align-middle text-center fs-3">
                <thead>
                  <tr>
                    <th scope="col">Regisration Number</th>
                    <th scope="col">Policy Number</th>
                    <th scope="col">Make</th>
                    <th scope="col">Model</th>
                    <th scope="col">Variant</th>
                    <th scope="col">Gross Premium</th>
                    <th scope="col">Insurer</th>
                    <th scope="col">Inspection Status</th>

                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {myPolicies &&
                    myPolicies.length > 0 &&
                    myPolicies
                      .filter((item) => item.isBreakIn == true)
                      .map((item, i) => (
                        <tr key={i}>
                          <td>{item?.vehicle_no}</td>
                          <td>{item?.policy_no}</td>
                          <td>{item?.vehicle_make}</td>
                          <td>{item?.vehicle_model}</td>
                          <td>{item?.vehicle_variant}</td>
                          <td>{item?.gross_premium}</td>
                          <td>{item?.insurance_company}</td>
                          <td>
                            {policyStatus.index === i ? (
                              <>
                                <p>{policyStatus.status}</p>
                                {policyStatus.payment === true && (
                                  <a
                                    className="btn btn-primary fs-3 px-4 py-2 fw-bold"
                                    onClick={() => makePayment(item?.insurance_company, item)}
                                  >
                                    Make Payment
                                  </a>
                                )}
                              </>
                            ) : (
                              "-"
                            )}
                          </td>
                          <td>
                            <button
                              className="btn btn-primary fs-3 px-4 py-2 fw-bold"
                              type="button"
                              onClick={() => getCompanyStatus(item, i)}
                            >
                              {" "}
                              check Status
                            </button>
                          </td>
                        </tr>
                      ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default MyPolicy;

const createPolicyObject = (policy) => {
  let addons = JSON.parse(policy.addons);
  return {
    isFiltered: false,
    VehicleType: policy.vehicle_type == "Pvt Car" ? "4w" : "2w",
    CustomerType: policy.customer_type,
    IsPreviousInsurerKnown: true,
    Salutation: policy.customer.gender == "Male" ? "Mr" : "Mrs",
    RegistrationDate: policy.registration_date,
    RegistrationNumber: policy.vehicle_no,
    PolicyEndDate: policy.policy_expiry,
    PolicyStartDate: policy.policy_issue,
    PreInsurerCode: policy.previous_policy_insurance_company,
    PreInsurerComp: "",
    PrePolicyNumber: policy.previous_policy_no,
    ProductCode: "",
    ManufaturingDate: policy.vehicle_mfg_yr,
    ProposalId: "",

    VariantCode: {
      Digit: policy.mmv_master.Vehicle_Code,
      Bajaj: policy.mmv_master.Bajaj,
      HDFC: policy.mmv_master.HDFC,
      Shriram: policy.mmv_master.Shriram,
      Kotak: policy.mmv_master.Kotak,
      Reliance: policy.mmv_master.Reliance,
      Future: policy.mmv_master.Future,
      Royal: policy.mmv_master.Royal,
      Zuno: policy.mmv_master.Zuno,
      vehicleId: policy.mmv_master.id,
    },
    Email: policy.customer.email,
    FirstName: policy.customer.first_name,
    LastName: policy.customer.last_name,
    MiddleName: "",
    MobileNumber: policy.customer.phone,
    FlatNumber: "",
    StreetNumber: policy.customer.address_line1,
    Street: policy.customer.address_line2,
    District: policy.customer.address_line3,
    City: policy.customer.city,
    State: policy.customer.state,
    StateCode: "",
    Country: "",
    Pincode: policy.customer.pincode,
    PermanentAddress: "",
    Dob: policy.customer.dob,
    Gender: policy.customer.gender,
    PrePolicyEndDate: policy.previous_policy_end_date,
    MakeName: policy.mmv_master.Make,
    ModelName: policy.mmv_master.Model,
    VariantName: policy.mmv_master.Variant,
    PolicyType: "Renewal",
    RegistrationYear: moment(policy.registration_date, "YYYY-MM-DD").format("YYYY"),
    PreviousPolicyType: "comprehensive",
    NewPolicyType:
      policy?.policy_type === "comprehensive"
        ? "Comprehensive"
        : policy?.policy_type == "third_party"
          ? "ThirdParty"
          : "ODOnly",
    FuelType: policy.fuel_type,
    customerId: policy.customer.id,
    ApiId: "",
    policyId: "",
    RtoCode: policy.rto,
    EngineNumber: policy.engine_no,
    ChassisNumber: policy.chassis_no,
    NomineeFirstName: policy.customer.nominee_name.split(" ")[0],
    NomineeLastName: policy.customer.nominee_name.split(" ")[0],
    NomineeDateOfBirth: moment().subtract(policy.customer.nominee_age, "year").format("YYYY-MM-DD"),
    NomineeRelationship: policy.nominee_relation,
    PreviousInsuranceClaimed: false,
    Idv: policy.idv,
    PreviousNoClaimBonus: policy.previous_policy_discount,
    PersonalAccident: {
      Selection: false,
      InsuredAmount: false,
      CoverTerm: false,
    },
    AddOns: addons,
    PaymentAmount: 0,
    PaymentId: "",
    CubicCapacity: policy.mmv_master.Cubic_Capacity,
    CarryingCapacity: policy.mmv_master.Seating_Capacity,
    VehicleMakeCode: "",
    VehicleModelCode: "",
    VehicleSubTypeCode: "",
    RegistrationCity: policy.customer.city,
    IsVehicleNew: false,
    applicationId: "",
    PolicyStatus: "continue",
    PanNumber: policy.customer.pan_card,
    PanImage: "",
    IsHypothecation: false,
    HpnBank: "",
    HpnCity: "",
    HpnHDFCID: "",
    PreTPPolicyNumber: "",
    PreTPInsurerCode: "",
    PreTPPolicyEndDate: "",
    GSTNo: "",
    IsValidPuc: false,
    PUCNumber: "",
    PUCValidUpto: "",
    RelianceRTOCode: "",
    KGIStatus: false,
    IsInternalCNG: false,
    KYC: {
      HDFC: "",
      Future: policy.customer.kyc_no,
      Reliance: "",
      Kotak: "",
      RoyalRefNo: "",
      Royal: "",
      Shriram: "",
      AadharNo: "",
      FatherName: "",
      MotherName: "",
      AadharBackPic: "",
      PassportPic: "",
    },
  };
};

const createSelectedPlan = (policy) => {
  return {
    Logo: "assets/images/logos/partners/fg.png",
    Api_name: "Future",
    ApiId: "",
    PolicyId: "",
    RegisterNumber: policy.vehicle_no,
    Make: policy.mmv_master.Make,
    Model: policy.mmv_master.Model,
    Variant: policy.mmv_master.Variant,
    BasicODPremium: policy.od_net_premium,
    BasicTPPremium: policy.terrorism_prem,
    PersonalAccident: false,
    NetPremium: policy.net_premium,
    GST: policy.tax_amount,
    FinalPremium: policy.gross_premium,
    Year: "1 Years",
    MinMaxIdv: "261079.2 - 319096.8",
    idv: policy.idv,
    insurer: policy.insurance_company,
    discount: false,
    StartDate: policy.policy_start,
    EndDate: policy.policy_expiry,
    policyType:
      policy?.policy_type === "comprehensive"
        ? "Comprehensive"
        : policy?.policy_type == "third_party"
          ? "ThirdParty"
          : "ODOnly",
    RoadSideAssistance: false,
    TyreProtection: false,
    RimProtection: false,
    Consumables: false,
    EngineGearBox: false,
    EngineProtection: false,
    IsElectricalAccessories: false,
    IsNonElectricalAccessories: false,
    InvoiceCover: false,
    VoluntaryDeductive: false,
    NCBProtection: false,
    PassengerCover: false,
    LossOfPersonalBelongings: false,
    ZeroDepriciationCover: false,
    KeyReplacement: false,
    PaCoverAmount: false,
    PassengerCoverAmount: false,
    ElectricAmount: false,
    nonElectricalAmount: false,
    VoluntaryAmount: false,
    NCBDiscount: false,
    CNGValue: null,
    DriverLiability: false,
  };
};
