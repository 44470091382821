import React from "react";
import "./footerpages.css";
import Header from "../Header";
import Footer from "../Footer";

function HomeInsurance() {
  return (
    <>
      <Header />
      <div>
        <div className="insurance-cantainer">
          <h1>Home insurance</h1>
        </div>
        <div className="cantainer">
          <div className="box-info">
            <div className="box-1">
              <h2>
                "Home Insurance: Safeguarding Your Residence and Peace of Mind"
              </h2>
              <p>
                Home insurance, often referred to as homeowners insurance or
                property insurance, is a vital financial tool that provides
                protection and peace of mind to homeowners. It typically covers
                damage to the physical structure of the home, personal
                belongings, and liability for injuries or damages that occur on
                the property. The coverage for the physical structure includes
                events like fire, theft, vandalism, natural disasters, and more.
                Personal belongings coverage extends to items such as furniture,
                electronics, clothing, and other valuables, both within and
                outside the home. Liability coverage can help in the unfortunate
                event of a lawsuit resulting from someone getting injured on the
                property.
              </p>
              <p>
                Furthermore, home insurance provides additional living expenses
                (ALE) coverage, which assists with costs like temporary housing
                and meals if the home becomes uninhabitable due to covered
                perils. Different policies offer various levels of coverage, and
                homeowners can tailor their plans based on their specific needs
                and budget. It's crucial to regularly review and update the
                policy to ensure it aligns with any changes in the value of the
                home or its contents. Overall, home insurance serves as a vital
                safeguard, providing financial support and enabling homeowners
                to rebuild and recover in the face of unforeseen events.
              </p>
            </div>
            <div className="box-2 homeinsurance">
              <img src="./assetss/images/homeinsurance1.jpg" alt="Erroe" />
            </div>
          </div>
        </div>
        <div className="vision-info">
          <div className="vision-box">
            <div className="img-box">
              <img src="./assetss/images/vision.png" alt="Error" />
            </div>
            <div className="vision-box1">
              <h2>Our vision</h2>
              <p>
                There are many variations of passages of Lorem Ipsum available,
                but the majority have suffered alteration in some form, by
                injected humour, or randomised words which don't look even
                slightly believable. If you are going to use a passage of Lorem
                Ipsum, you need to be sure there isn't anything embarrassing
                hidden in the middle of text. All the Lorem Ipsum generators on
                the Internet tend to repeat predefined chunks as necessary,
              </p>
            </div>
          </div>
        </div>
        <div className="btn-cantainer">
          <div className="btn-1">
            <h1>Solving your Insurance Needs on Time</h1>
          </div>
          <div className="btn-1">
            <h2>
              Get world-class insurance coverage that not only protects you but
              also helps you build wealth. Let us be your insurance partner
            </h2>
            <div className="btn-info">
              <button>Get Started</button>
              <button>Contact Us</button>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default HomeInsurance;
