import React from "react";

const CarInsuranceDetails = () => {
  return (
    <div>
      <div className="abouthealthins">
        <div className="healthinsimages">
          <img
            src={"/assetss/images/carimg02.png"}
            alt="img"
            className="img-fluid"
          />
        </div>
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-lg-7">
              <h2>
                Know More about <span>Car Insurance</span>
              </h2>
              <ul>
                <li className="checkmark">
                  <div>
                    <img
                      src="/assetss/images/bluecheck.png"
                      alt="blue-checkmark"
                    />
                  </div>
                  Car insurance is a vital component of responsible car
                  ownership. It functions as a financial safety net, providing
                  comprehensive protection for both you and your vehicle.
                </li>
                <li className="checkmark">
                  <div>
                    <img
                      src="/assetss/images/bluecheck.png"
                      alt="blue-checkmark"
                    />
                  </div>
                  In the unfortunate event of an accident, theft, or damage, car
                  insurance steps in to cover various expenses. This includes
                  the costs associated with repairing your vehicle, covering
                  medical expenses in case of injuries, and addressing any legal
                  liabilities that might arise.
                </li>
                <li className="checkmark">
                  <div>
                    <img
                      src="/assetss/images/bluecheck.png"
                      alt="blue-checkmark"
                    />
                  </div>
                  Car insurance policies come in various forms, ranging from
                  comprehensive coverage to liability-only options. Selecting
                  the right policy involves considering your specific needs and
                  budget constraints.
                </li>
                <li className="checkmark">
                  <div>
                    <img
                      src="/assetss/images/bluecheck.png"
                      alt="blue-checkmark"
                    />
                  </div>
                  To ensure you drive with confidence, it's imperative to
                  research and carefully choose the car insurance plan that
                  aligns with your requirements. This helps provide peace of
                  mind on the road, knowing you're financially protected in
                  various scenarios.
                </li>
                <li className="checkmark">
                  <div>
                    <img
                      src="/assetss/images/bluecheck.png"
                      alt="blue-checkmark"
                    />
                  </div>
                  Additionally, car insurance offers protection against natural
                  disasters, vandalism, and other non-collision-related damage,
                  giving you peace of mind in various situations.
                </li>
                <li className="checkmark">
                  <div>
                    <img
                      src="/assetss/images/bluecheck.png"
                      alt="blue-checkmark"
                    />
                  </div>
                  Some car insurance policies also cover uninsured or
                  underinsured motorists, ensuring you're protected if you're
                  involved in an accident with a driver who lacks sufficient
                  coverage.
                </li>
              </ul>
              <div className="healthinsimgsm">
                <img
                  src={"/assetss/images/carinsurancesmimg01.png"}
                  alt="img"
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="abouthealthins boxrevers">
        <div className="healthinsimages">
          <img
            src={"/assetss/images/carimg03.png"}
            alt="img"
            className="img-fluid"
          />
        </div>
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-lg-5"></div>
            <div className="col-md-12 col-lg-7">
              <h2>
                Benefits of <span>Car Insurance</span>
              </h2>
              <ul>
                <li className="checkmark">
                  <div>
                    <img
                      src="/assetss/images/bluecheck.png"
                      alt="blue-checkmark"
                    />
                  </div>
                  Car insurance offers a profound sense of security and peace of
                  mind. It acts as a safeguard against the unpredictable nature
                  of the road, assuring that you are well-protected in a variety
                  of circumstances.
                </li>
                <li className="checkmark">
                  <div>
                    <img
                      src="/assetss/images/bluecheck.png"
                      alt="blue-checkmark"
                    />
                  </div>
                  In case of accidents, car insurance goes beyond just covering
                  repair costs. It also extends to the replacement of your
                  vehicle, significantly alleviating the financial burden
                  associated with such unexpected incidents.
                </li>
                <li className="checkmark">
                  <div>
                    <img
                      src="/assetss/images/bluecheck.png"
                      alt="blue-checkmark"
                    />
                  </div>
                  Furthermore, depending on the specific policy you choose, car
                  insurance can offer a host of additional benefits. These can
                  include coverage for rental cars during repairs, access to
                  roadside assistance, and protection from legal and financial
                  repercussions in the event of an accident.
                </li>
                <li className="checkmark">
                  <div>
                    <img
                      src="/assetss/images/bluecheck.png"
                      alt="blue-checkmark"
                    />
                  </div>
                  By making the wise decision to secure reliable car insurance,
                  you can drive with confidence, knowing that you are
                  safeguarded in all driving situations, ensuring a more secure
                  and stress-free journey.
                </li>
                <li className="checkmark">
                  <div>
                    <img
                      src="/assetss/images/bluecheck.png"
                      alt="blue-checkmark"
                    />
                  </div>
                  In the unfortunate event of a hit-and-run accident, some car
                  insurance policies offer coverage to help you cover damages
                  when the at-fault driver cannot be identified or is uninsured.
                </li>
                <li className="checkmark">
                  <div>
                    <img
                      src="/assetss/images/bluecheck.png"
                      alt="blue-checkmark"
                    />
                  </div>
                  Car insurance also extends its protection when you lend your
                  car to a friend or family member. It provides coverage even
                  when someone else is driving your vehicle with your permission
                </li>
              </ul>
              <div className="healthinsimgsm">
                <img
                  src={"/assetss/images/carinsurancesmimg02.png"}
                  alt="img"
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CarInsuranceDetails;
