import React from "react";
import "./footerpages.css";
import Header from "../Header";
import Footer from "../Footer";

function LifeInsurance() {
  return (
    <>
      <div>
        <Header />
        <div className="insurance-cantainer">
          <h1>Life insurance</h1>
        </div>
        <div className="cantainer">
          <div className="box-info">
            <div className="box-1">
              <h2>
                LifeGuard: Securing Your Future, Protecting Your Loved Ones
              </h2>
              <p>
                Life insurance, in reality, is a financial product offered by
                insurance companies to provide financial protection to the
                policyholder's beneficiaries (typically family members or loved
                ones) in the event of the policyholder's death. The insurance
                company pays out a predetermined sum of money, known as the
                death benefit, to the beneficiaries upon the death of the
                insured individual.
              </p>
              <p>
                Life policies are legal contracts and the terms of each contract
                describe the limitations of the insured events. Often, specific
                exclusions written into the contract limit the liability of the
                insurer; common examples include claims relating to suicide,
                fraud, war, riot, and civil commotion. Difficulties may arise
                where an event is not clearly defined, for example, the insured
                knowingly incurred a risk by consenting to an experimental
                medical procedure or by taking medication resulting in injury or
                death.
              </p>
            </div>
            <div className="box-2">
              <img src="/assetss/images/LifeInsuranceImg.jpg" alt="Erroe" />
            </div>
          </div>
        </div>
        <div className="vision-info">
          <div className="vision-box">
            <div className="img-box">
              <img src="/assetss/images/vision.png" alt="Error" />
            </div>
            <div className="vision-box1">
              <h2>Our vision</h2>
              <p>
                There are many variations of passages of Lorem Ipsum available,
                but the majority have suffered alteration in some form, by
                injected humour, or randomised words which don't look even
                slightly believable. If you are going to use a passage of Lorem
                Ipsum, you need to be sure there isn't anything embarrassing
                hidden in the middle of text. All the Lorem Ipsum generators on
                the Internet tend to repeat predefined chunks as necessary,
              </p>
            </div>
          </div>
        </div>
        <div className="btn-cantainer">
          <div className="btn-1">
            <h1>Solving your Insurance Needs on Time</h1>
          </div>
          <div className="btn-1">
            <h2>
              Get world-class insurance coverage that not only protects you but
              also helps you build wealth. Let us be your insurance partner.
            </h2>
            <div className="btn-info">
              <button>Get Started</button>
              <button>Contact Us</button>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}

export default LifeInsurance;
