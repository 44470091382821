import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Layout from "../../common/Layout";
const PolicyDetails = () => {
  const navigate = useNavigate();
  const location = useLocation();
  return (
    <Layout>
      <section className="about-us-section">
        {/*----- Blur set effect -----*/}
        <div className="blur-bg-blocks">
          <aside className="blur-bg-set">
            <div className="blur-bg blur-bg-a" />
            <div className="blur-bg blur-bg-b" />
            <div className="blur-bg blur-bg-c" />
          </aside>
        </div>

        {/*----- container start -----*/}
        <div className="container-lg container-fluid">
          <div className="row justify-content-between align-items-center">
            <div className="col-lg-8 col-12 text-center mx-auto mt-5 pt-5">
              <h1 className="text-capitalize about-heading mx-auto">Expert Cover Providings</h1>
              <p className="my-5 fs-2">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit voluptates quisquam nulla reiciendis
                illum placeat dolorem sed. Corporis officiis explicabo non nulla, fuga doloremque magni cumque, ratione
                iste amet voluptatum. Lorem ipsum dolor.
              </p>
            </div>

            <div className="col-12 my-4" />

            <div className="col-12">
              <nav className="detilsNav">
                <div className="nav nav-tabs border-0 justify-content-lg-start justify-content-center" id="nav-tab">
                  <button
                    onClick={() => navigate("/car-insurance")}
                    className={
                      location.pathname === "/car-insurance"
                        ? "nav-link rounded-0 border-0 active"
                        : "nav-link rounded-0 border-0"
                    }
                    data-bs-toggle="tab"
                    data-bs-target="#carinsuretab"
                    type="button"
                  >
                    <i className="fas me-3 fa-car ms-2" />
                    Car Insurance
                  </button>

                  <button
                    onClick={() => navigate("/bike-insurance")}
                    className={
                      location.pathname === "/bike-insurance"
                        ? "nav-link rounded-0 border-0 active"
                        : "nav-link rounded-0 border-0"
                    }
                    data-bs-toggle="tab"
                    data-bs-target="#bikeinsuretab"
                    type="button"
                  >
                    <i className="fas me-3 fa-motorcycle ms-2" />
                    Bike Insurance
                  </button>

                  <button
                    onClick={() => navigate("/gcv-insurance")}
                    className={
                      location.pathname === "/gcv-insurance"
                        ? "nav-link rounded-0 border-0 active"
                        : "nav-link rounded-0 border-0"
                    }
                    data-bs-toggle="tab"
                    data-bs-target="#gcvinsuretab"
                    type="button"
                  >
                    <i className="fas me-3 fa-truck ms-2" />
                    GCV Insurance
                  </button>

                  <button
                    onClick={() => navigate("/pcv-insurance")}
                    className={
                      location.pathname === "/pcv-insurance"
                        ? "nav-link rounded-0 border-0 active"
                        : "nav-link rounded-0 border-0"
                    }
                    data-bs-toggle="tab"
                    data-bs-target="#pcvinsuretab"
                    type="button"
                  >
                    <i className="fas me-3 fa-taxi ms-2" />
                    PCV Insurance
                  </button>
                </div>
              </nav>

              <div className="tab-content">
                <div className="tab-pane fade show active" id="carinsuretab">
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-12 position-relative my-5 text-start">
                        <h1 className="fs-1">Car Insurance</h1>
                        <p className="fs-2">
                          When you buy or lease a car, it's important to protect that investment. Getting auto insurance
                          can offer reassurance in case you're involved in an accident or the vehicle is stolen,
                          vandalized or damaged by a natural disaster. Instead of paying out-of-pocket for auto
                          accidents. Car insurance is effectively a contract between yourself and an insurance company
                          in which you agree to pay premiums in exchange for protection against financial losses
                          stemming from an accident or other damage to the vehicle.
                        </p>
                      </div>
                    </div>

                    <div className="row justify-content-between align-items-center">
                      <div className="col-lg-4 col-12 d-lg-block d-none">
                        <img src="./assets/images/g8.png" className="w-100" alt="" />
                      </div>

                      <div className="col-md-7 col-12 mx-auto">
                        <div className="card border-0 shadow-lg">
                          <div className="card-body py-5 px-sm-5 px-4">
                            <p className="fs-2 mb-5">
                              Secure Your Car &amp; Save upto
                              <span className="text-success mx-2 fw-bold">75%*</span>
                              on Plans offered by Insurers
                            </p>

                            <div className="row mx-0 px-0">
                              <div className="col-lg-6 ps-lg-0 mb-4">
                                <div className="form-floating">
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="vehinum"
                                    placeholder="Enter Your Car Number"
                                  />
                                  <label htmlFor="vehinum">Enter Your Car Number</label>
                                </div>
                              </div>

                              <div className="col-lg-6 pe-lg-0 mb-4">
                                <div className="form-floating">
                                  <input type="text" className="form-control" id="venName" placeholder="Enter Name" />
                                  <label htmlFor="venName">Enter Name</label>
                                </div>
                              </div>

                              <div className="col-lg-6 ps-lg-0 mb-4">
                                <div className="form-floating">
                                  <input
                                    type="number"
                                    className="form-control"
                                    id="Nobinum"
                                    placeholder="Mobile Number"
                                    maxLength="10"
                                  />
                                  <label htmlFor="Nobinum">Mobile Number</label>
                                </div>
                              </div>
                            </div>

                            <div className="d-sm-flex justify-content-between align-items-center pt-2">
                              <a href="quotelist.html" className="btn btn-primary px-4 py-3 fs-3 ">
                                Get Quote
                              </a>
                              <div className="text-lg-end text-center mt-lg-0 mt-3">
                                <p className="fs-3 mb-0">
                                  <a href="#" className=" ms-2 fw-bold text-decoration-underline">
                                    Don't Know your car number?
                                  </a>
                                </p>
                                <p className="fs-3 mb-0">
                                  <a href="#" className="ms-2 fw-bold text-decoration-underline">
                                    Bought a new car?
                                  </a>
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row justify-content-between align-items my-5 pt-3">
                      <div className="col-12">
                        <p className="fs-1 mb-4">Key Features of Car Insurance Policy</p>
                        <table className="table table-bordered border-dark bg-primary-light fs-3">
                          <tbody>
                            <tr>
                              <td className="p-3">
                                <strong>Key Features</strong>
                              </td>
                              <td className="p-3">
                                <strong>Benefits Offered</strong>
                              </td>
                            </tr>
                            <tr>
                              <td className="p-3">Third Party Damages</td>
                              <td className="p-3">Covers both third-party death, injuries &amp; property damages</td>
                            </tr>
                            <tr>
                              <td className="p-3">Own Damage Cover</td>
                              <td className="p-3">Available under standalone &amp; comprehensive plan</td>
                            </tr>
                            <tr>
                              <td className="p-3">Personal Accident Cover</td>
                              <td className="p-3">Up to Rs. 15 lakh</td>
                            </tr>
                            <tr>
                              <td className="p-3">No Claim Bonus</td>
                              <td className="p-3">Up to 50%</td>
                            </tr>
                            <tr>
                              <td className="p-3">Add-On Covers</td>
                              <td className="p-3">More than 10 add-ons depending on the plan</td>
                            </tr>
                            <tr>
                              <td className="p-3">Car Insurance Premium</td>
                              <td className="p-3">Starting @ 2094/year*</td>
                            </tr>
                            <tr>
                              <td className="p-3">Buying/Renewal Process</td>
                              <td className="p-3">Online &amp; Offline</td>
                            </tr>
                            <tr>
                              <td className="p-3">Cashless Repairs</td>
                              <td className="p-3">Available</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div className="col-12 position-relative mt-5 mb-3">
                        <h1 className="fs-1">Types of Car Insurance Policy In India</h1>
                        <p className="fs-3">
                          A car insurance policy is a protection shield for your car against any damages caused by
                          natural calamities such as floods, earthquakes, cyclones, accidents, thefts, etc. There are
                          three types of car insurance policies:A car insurance policy is a protection shield for your
                          car against any damages caused by natural calamities such as floods, earthquakes, cyclones,
                          accidents, thefts, etc. There are three types of car insurance policies:
                        </p>
                      </div>
                      <div className="col-12">
                        <div className="accordion" id="vehicleAccordion">
                          {/*----- accordion -----*/}
                          <div className="accordion-item bg-transparent mb-4">
                            <h2 className="accordion-header">
                              <button
                                className="accordion-button collapsed fs-2 px-lg-5"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#vehicleAccordion1"
                              >
                                Third Party Car Insurance
                              </button>
                            </h2>
                            <div
                              id="vehicleAccordion1"
                              className="accordion-collapse collapse"
                              data-bs-parent="#vehicleAccordion"
                            >
                              <div className="accordion-body fs-3 p-lg-5 p-3">
                                Third-party liability car insurance provides coverage for third-party financial
                                liabilities such as car damage, physical injury, death, disability as well as property
                                damage. The coverage offered in case of death is unlimited, while for property damage,
                                the coverage is limited to up to Rs. 7.5 lakh. Buying at least a third party car
                                insurance is mandatory n India for all cars as per the Motor Vehicles Act, 1988.
                                Third-party liability car insurance provides coverage for third-party financial
                                liabilities such as car damage, physical injury, death, disability as well as property
                                damage. The coverage offered in case of death is unlimited, while for property damage,
                                the coverage is limited to up to Rs. 7.5 lakh. Buying at least a third party car
                                insurance is mandatory n India for all cars as per the Motor Vehicles Act, 1988.
                              </div>
                            </div>
                          </div>
                          <div className="accordion-item bg-transparent mb-4">
                            <h2 className="accordion-header">
                              <button
                                className="accordion-button collapsed fs-2 px-lg-5"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#vehicleAccordion2"
                              >
                                Comprehensive Car Insurance
                              </button>
                            </h2>
                            <div
                              id="vehicleAccordion2"
                              className="accordion-collapse collapse"
                              data-bs-parent="#vehicleAccordion"
                            >
                              <div className="accordion-body fs-3 p-lg-5 p-3">
                                A comprehensive insurance policy provides coverage for third-party liability as well as
                                damages caused to your own car. In comparison with third-party liability insurance, a
                                comprehensive car insurance policy offers extensive coverage, more benefits &amp; covers
                                the damages caused to the insured car in case of an accident, collision, theft, etc. A
                                comprehensive insurance policy provides coverage for third-party liability as well as
                                damages caused to your own car. In comparison with third-party liability insurance, a
                                comprehensive car insurance policy offers extensive coverage, more benefits &amp; covers
                                the damages caused to the insured car in case of an accident, collision, theft, etc.
                              </div>
                            </div>
                          </div>
                          <div className="accordion-item bg-transparent mb-4">
                            <h2 className="accordion-header">
                              <button
                                className="accordion-button collapsed fs-2 px-lg-5"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#vehicleAccordion3"
                              >
                                Own Damage
                              </button>
                            </h2>
                            <div
                              id="vehicleAccordion3"
                              className="accordion-collapse collapse"
                              data-bs-parent="#vehicleAccordion"
                            >
                              <div className="accordion-body fs-3 p-lg-5 p-3">
                                Own damage car policy provides coverage to the policyholder in case their car sustains
                                any kind of damage. The third-party insurance does not cover your own damage so you have
                                to purchase this cover separately with your mandatory third party cover. Own damage car
                                policy provides coverage to the policyholder in case their car sustains any kind of
                                damage. The third-party insurance does not cover your own damage so you have to purchase
                                this cover separately with your mandatory third party cover.
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="tab-pane fade" id="bikeinsuretab">
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-12 position-relative my-5 text-start">
                        <h1 className="fs-1">Bike Insurance</h1>
                        <p className="fs-2">
                          Two-wheeler insurance is a type of insurance that is mandatory in India. Falling under the
                          General insurance product category, it helps protect people against accidents that take place
                          on the road. Active two wheeler insurance shields the vehicle owner from any unforeseen
                          occurrences like the accident or any serious damage to the motor vehicle. A two-wheeler
                          insurance policy is provided by any of the authorized insurance companies registered under the
                          Insurance Regulatory Development Authority of India (IRDAI).
                        </p>
                      </div>
                    </div>
                    <div className="row justify-content-between align-items-center">
                      <div className="col-lg-4 col-12 d-lg-block d-none">
                        <img src="./assets/images/g8.png" className="w-100" alt="" />
                      </div>
                      <div className="col-md-7 col-12 mx-auto">
                        <div className="card border-0 shadow-lg">
                          <div className="card-body py-5 px-sm-5 px-4">
                            <p className="fs-2 mb-5">
                              Secure two wheeler &amp; Save upto
                              <span className="text-success mx-2 fw-bold">75%*</span>
                              on Plans offered by Insurers
                            </p>

                            <div className="row mx-0 px-0">
                              <div className="col-lg-6 ps-lg-0 mb-4">
                                <div className="form-floating">
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="vehinum"
                                    placeholder="Enter Your Bike Number"
                                  />
                                  <label htmlFor="vehinum">Enter two wheeler Reg. No.</label>
                                </div>
                              </div>

                              <div className="col-lg-6 pe-lg-0 mb-4">
                                <div className="form-floating">
                                  <input type="text" className="form-control" id="venName" placeholder="Enter Name" />
                                  <label htmlFor="venName">Enter Name</label>
                                </div>
                              </div>
                              <div className="col-lg-6 ps-lg-0 mb-4">
                                <div className="form-floating">
                                  <input
                                    type="number"
                                    className="form-control"
                                    id="Nobinum"
                                    placeholder="Mobile Number"
                                  />
                                  <label htmlFor="Nobinum">Mobile Number</label>
                                </div>
                              </div>
                            </div>
                            <div className="d-sm-flex justify-content-between align-items-center pt-2">
                              <a href="quotelist.html" className="btn btn-primary px-4 py-3 fs-3">
                                Get Quote
                              </a>
                              <div className="text-lg-end text-center mt-lg-0 mt-3">
                                <p className="fs-3 mb-0">
                                  <a href="#" className=" ms-2 fw-bold text-decoration-underline">
                                    Don't Know your bike number?
                                  </a>
                                </p>
                                <p className="fs-3 mb-0">
                                  <a href="#" className=" ms-2 fw-bold text-decoration-underline">
                                    Bought a new bike?
                                  </a>
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row justify-content-between align-items my-5 pt-3">
                      <div className="col-12">
                        <p className="fs-1 mb-4">Key Features of Two wheeler Insurance Policy</p>
                        <table className="table table-bordered border-dark bg-primary-light fs-3">
                          <tbody>
                            <tr>
                              <td className="p-3">
                                <strong>Key Features</strong>
                              </td>
                              <td className="p-3">
                                <strong>Benefits Offered</strong>
                              </td>
                            </tr>
                            <tr>
                              <td className="p-3">Third Party Damages</td>
                              <td className="p-3">Covers both third-party death, injuries &amp; property damages</td>
                            </tr>
                            <tr>
                              <td className="p-3">Own Damage Cover</td>
                              <td className="p-3">Available under standalone &amp; comprehensive plan</td>
                            </tr>
                            <tr>
                              <td className="p-3">Personal Accident Cover</td>
                              <td className="p-3">Up to Rs. 15 lakh</td>
                            </tr>
                            <tr>
                              <td className="p-3">No Claim Bonus</td>
                              <td className="p-3">Up to 50%</td>
                            </tr>
                            <tr>
                              <td className="p-3">Add-On Covers</td>
                              <td className="p-3">More than 10 add-ons depending on the plan</td>
                            </tr>
                            <tr>
                              <td className="p-3">Two wheeler Insurance Premium</td>
                              <td className="p-3">Starting @ 2094/year*</td>
                            </tr>
                            <tr>
                              <td className="p-3">Buying/Renewal Process</td>
                              <td className="p-3">Online &amp; Offline</td>
                            </tr>
                            <tr>
                              <td className="p-3">Cashless Repairs</td>
                              <td className="p-3">Available</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div className="col-12 position-relative mt-5 mb-3">
                        <h1 className="fs-1">Types of two wheeler Insurance Policy In India</h1>
                        <p className="fs-3">
                          A car insurance policy is a protection shield for your car against any damages caused by
                          natural calamities such as floods, earthquakes, cyclones, accidents, thefts, etc. There are
                          three types of car insurance policies:A car insurance policy is a protection shield for your
                          car against any damages caused by natural calamities such as floods, earthquakes, cyclones,
                          accidents, thefts, etc. There are three types of car insurance policies:
                        </p>
                      </div>
                      <div className="col-12">
                        <div className="accordion" id="vehicleAccordion">
                          {/*----- accordion -----*/}
                          <div className="accordion-item bg-transparent mb-4">
                            <h2 className="accordion-header">
                              <button
                                className="accordion-button collapsed fs-2 px-lg-5"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#vehicleAccordion1"
                              >
                                Third Party Insurance
                              </button>
                            </h2>
                            <div
                              id="vehicleAccordion1"
                              className="accordion-collapse collapse"
                              data-bs-parent="#vehicleAccordion"
                            >
                              <div className="accordion-body fs-3 p-lg-5 p-3">
                                Third-party liability vehicle insurance provides coverage for third-party financial
                                liabilities such as car damage, physical injury, death, disability as well as property
                                damage. The coverage offered in case of death is unlimited, while for property damage,
                                the coverage is limited to up to Rs. 7.5 lakh. Buying at least a third party vehicle
                                insurance is mandatory n India for all vehicles as per the Motor Vehicles Act, 1988.
                              </div>
                            </div>
                          </div>
                          <div className="accordion-item bg-transparent mb-4">
                            <h2 className="accordion-header">
                              <button
                                className="accordion-button collapsed fs-2 px-lg-5"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#vehicleAccordion2"
                              >
                                Comprehensive vehicle Insurance
                              </button>
                            </h2>
                            <div
                              id="vehicleAccordion2"
                              className="accordion-collapse collapse"
                              data-bs-parent="#vehicleAccordion"
                            >
                              <div className="accordion-body fs-3 p-lg-5 p-3">
                                A comprehensive insurance policy provides coverage for third-party liability as well as
                                damages caused to your own vehicle. In comparison with third-party liability insurance,
                                a comprehensive vehicle insurance policy offers extensive coverage, more benefits &amp;
                                covers the damages caused to the insured vehicle in case of an accident, collision,
                                theft, etc.
                              </div>
                            </div>
                          </div>
                          <div className="accordion-item bg-transparent mb-4">
                            <h2 className="accordion-header">
                              <button
                                className="accordion-button collapsed fs-2 px-lg-5"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#vehicleAccordion3"
                              >
                                Own Damage
                              </button>
                            </h2>
                            <div
                              id="vehicleAccordion3"
                              className="accordion-collapse collapse"
                              data-bs-parent="#vehicleAccordion"
                            >
                              <div className="accordion-body fs-3 p-lg-5 p-3">
                                Own damage car policy provides coverage to the policyholder in case their vehicle
                                sustains any kind of damage. The third-party insurance does not cover your own damage so
                                you have to purchase this cover separately with your mandatory third party cover. Own
                                damage car policy provides coverage to the policyholder in case their vehicle sustains
                                any kind of damage. The third-party insurance does not cover your own damage so you have
                                to purchase this cover separately with your mandatory third party cover.
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="tab-pane fade" id="gcvinsuretab">
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-12 position-relative my-5 text-start">
                        <h1 className="fs-1">GCV Insurance</h1>
                        <p className="fs-2">
                          Economies depend on goods being transferred across the country and the world. Goods carrying
                          vehicles are the real heroes but can experience downtime due to strenuous journeys. With
                          Expert Cover Insurance, be rest assured of minimum interruption and maximum care for the
                          vehicles.In case of injuries due to vehicle accidents, we cover all your treatments and make
                          sure you are healthy and lively above anything else! We also offer coverage to your
                          co-passengers with a slight change in the premiums!
                        </p>
                      </div>
                    </div>
                    <div className="row justify-content-between align-items-center">
                      <div className="col-lg-4 col-12 d-lg-block d-none">
                        <img src="./assets/images/g8.png" className="w-100" alt="" />
                      </div>
                      <div className="col-md-7 col-12 mx-auto">
                        <div className="card border-0 shadow-lg">
                          <div className="card-body py-5 px-sm-5 px-4">
                            <p className="fs-2 mb-5">
                              Secure Your GCV &amp; Save upto
                              <span className="text-success fw-bold mx-2">75%*</span>
                              on Plans offered by Insurers
                            </p>

                            <div className="row mx-0 px-0">
                              <div className="col-lg-6 ps-lg-0 mb-4">
                                <div className="form-floating mb-4">
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="vehinum"
                                    placeholder="Enter Your GCCV Number"
                                  />
                                  <label htmlFor="vehinum">Enter Your GCV Number</label>
                                </div>
                              </div>

                              <div className="col-lg-6 pe-lg-0 mb-4">
                                <div className="form-floating">
                                  <input type="text" className="form-control" id="venName" placeholder="Enter Name" />
                                  <label htmlFor="venName">Enter Name</label>
                                </div>
                              </div>

                              <div className="col-lg-6 ps-lg-0 mb-4">
                                <div className="form-floating">
                                  <input
                                    type="number"
                                    className="form-control"
                                    id="Nobinum"
                                    placeholder="Mobile Number"
                                  />
                                  <label htmlFor="Nobinum">Mobile Number</label>
                                </div>
                              </div>
                            </div>
                            <div className="d-sm-flex justify-content-between align-items-center pt-2">
                              <a href="quotelist.html" className="btn btn-primary px-4 py-3 fs-3">
                                Get Quote
                              </a>
                              <div className="text-lg-end text-center mt-lg-0 mt-3">
                                <p className="fs-3 mb-0">
                                  <a href="#" className=" ms-2 fw-bold text-decoration-underline">
                                    Don't Know your GCV number?
                                  </a>
                                </p>
                                <p className="fs-3 mb-0">
                                  <a href="#" className=" ms-2 fw-bold text-decoration-underline">
                                    Bought a new GCV?
                                  </a>
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row justify-content-between align-items my-5 pt-3">
                      <div className="col-12">
                        <p className="fs-1 mb-4">Key Features of GCV Insurance Policy</p>
                        <table className="table table-bordered border-dark bg-primary-light fs-3">
                          <tbody>
                            <tr>
                              <td className="p-3">
                                <strong>Key Features</strong>
                              </td>
                              <td className="p-3">
                                <strong>Benefits Offered</strong>
                              </td>
                            </tr>
                            <tr>
                              <td className="p-3">Third Party Damages</td>
                              <td className="p-3">Covers both third-party death, injuries &amp; property damages</td>
                            </tr>
                            <tr>
                              <td className="p-3">Own Damage Cover</td>
                              <td className="p-3">Available under standalone &amp; comprehensive plan</td>
                            </tr>
                            <tr>
                              <td className="p-3">Personal Accident Cover</td>
                              <td className="p-3">Up to Rs. 15 lakh</td>
                            </tr>
                            <tr>
                              <td className="p-3">No Claim Bonus</td>
                              <td className="p-3">Up to 50%</td>
                            </tr>
                            <tr>
                              <td className="p-3">Add-On Covers</td>
                              <td className="p-3">More than 10 add-ons depending on the plan</td>
                            </tr>
                            <tr>
                              <td className="p-3">GCV Insurance Premium</td>
                              <td className="p-3">Starting @ 2094/year*</td>
                            </tr>
                            <tr>
                              <td className="p-3">Buying/Renewal Process</td>
                              <td className="p-3">Online &amp; Offline</td>
                            </tr>
                            <tr>
                              <td className="p-3">Cashless Repairs</td>
                              <td className="p-3">Available</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div className="col-12 position-relative mt-5 mb-3">
                        <h1 className="fs-1">Types of GCV Insurance Policy In India</h1>
                        <p className="fs-3">
                          A GCV insurance policy is a protection shield for your vehicle against any damages caused by
                          natural calamities such as floods, earthquakes, cyclones, accidents, thefts, etc. There are
                          three types of GCV insurance policies:A GCV insurance policy is a protection shield for your
                          vehicle against any damages caused by natural calamities such as floods, earthquakes,
                          cyclones, accidents, thefts, etc. There are three types of GCV insurance policies:
                        </p>
                      </div>
                      <div className="col-12">
                        <div className="accordion" id="vehicleAccordion">
                          {/*----- accordion -----*/}
                          <div className="accordion-item bg-transparent mb-4">
                            <h2 className="accordion-header">
                              <button
                                className="accordion-button collapsed fs-2 px-lg-5"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#vehicleAccordion1"
                              >
                                Third Party Insurance
                              </button>
                            </h2>
                            <div
                              id="vehicleAccordion1"
                              className="accordion-collapse collapse"
                              data-bs-parent="#vehicleAccordion"
                            >
                              <div className="accordion-body fs-3 p-lg-5 p-3">
                                Third-party liability vehicle insurance provides coverage for third-party financial
                                liabilities such as vehicle damage, physical injury, death, disability as well as
                                property damage. The coverage offered in case of death is unlimited, while for property
                                damage, the coverage is limited to up to Rs. 7.5 lakh. Buying at least a third party
                                vehicle insurance is mandatory n India for all vehicle as per the Motor Vehicles Act,
                                1988.
                              </div>
                            </div>
                          </div>
                          <div className="accordion-item bg-transparent mb-4">
                            <h2 className="accordion-header">
                              <button
                                className="accordion-button collapsed fs-2 px-lg-5"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#vehicleAccordion2"
                              >
                                Comprehensive Insurance
                              </button>
                            </h2>
                            <div
                              id="vehicleAccordion2"
                              className="accordion-collapse collapse"
                              data-bs-parent="#vehicleAccordion"
                            >
                              <div className="accordion-body fs-3 p-lg-5 p-3">
                                A comprehensive insurance policy provides coverage for third-party liability as well as
                                damages caused to your own vehicle. In comparison with third-party liability insurance,
                                a comprehensive car insurance policy offers extensive coverage, more benefits &amp;
                                covers the damages caused to the insured vehicle in case of an accident, collision,
                                theft, etc.
                              </div>
                            </div>
                          </div>
                          <div className="accordion-item bg-transparent mb-4">
                            <h2 className="accordion-header">
                              <button
                                className="accordion-button collapsed fs-2 px-lg-5"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#vehicleAccordion3"
                              >
                                Own Damage
                              </button>
                            </h2>
                            <div
                              id="vehicleAccordion3"
                              className="accordion-collapse collapse"
                              data-bs-parent="#vehicleAccordion"
                            >
                              <div className="accordion-body fs-3 p-lg-5 p-3">
                                Own damage vehicle policy provides coverage to the policyholder in case their vehicle
                                sustains any kind of damage. The third-party insurance does not cover your own damage so
                                you have to purchase this cover separately with your mandatory third party cover.
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="tab-pane fade" id="pcvinsuretab">
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-12 position-relative my-5 text-start">
                        <h1 className="fs-1">PCV Insurance</h1>
                        <p className="fs-3">
                          Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quasi facilis rerum, eum laudantium
                          esse voluptatum sapiente modi totam harum necessitatibus odio, officiis dignissimos porro
                          voluptatibus natus veritatis! Quas, magnam nostrum.Lorem, ipsum dolor sit amet consectetur
                          adipisicing elit. Quasi facilis rerum, eum laudantium esse voluptatum sapiente modi totam
                          harum necessitatibus odio, officiis dignissimos porro voluptatibus natus veritatis! Quas,
                          magnam nostrum.
                        </p>
                      </div>
                    </div>
                    <div className="row justify-content-between align-items-center">
                      <div className="col-lg-4 col-12 d-lg-block d-none">
                        <img src="./assets/images/g8.png" className="w-100" alt="" />
                      </div>
                      <div className="col-md-7 col-12 mx-auto">
                        <div className="card border-0 shadow-lg">
                          <div className="card-body py-5 px-sm-5 px-4">
                            <p className="fs-2 mb-5">
                              Secure Your Car &amp; Save upto
                              <span className="text-success">75%*</span>
                              on Plans offered by Insurers
                            </p>

                            <div className="row mx-0 px-0">
                              <div className="col-lg-6 ps-lg-0 mb-4">
                                <div className="form-floating">
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="vehinum"
                                    placeholder="Enter Your PCV Number"
                                  />
                                  <label htmlFor="vehinum">Enter Your PCV Number</label>
                                </div>
                              </div>

                              <div className="col-lg-6 pe-lg-0 mb-4">
                                <div className="form-floating">
                                  <input type="text" className="form-control" id="venName" placeholder="Enter Name" />
                                  <label htmlFor="venName">Enter Name</label>
                                </div>
                              </div>

                              <div className="col-lg-6 ps-lg-0 mb-4">
                                <div className="form-floating">
                                  <input
                                    type="number"
                                    className="form-control"
                                    id="Nobinum"
                                    placeholder="Mobile Number"
                                  />
                                  <label htmlFor="Nobinum">Mobile Number</label>
                                </div>
                              </div>
                            </div>

                            <div className="d-sm-flex justify-content-between align-items-center pt-2">
                              <a href="quotelist.html" className="btn btn-primary px-4 py-3 fs-3">
                                Get Quote
                              </a>

                              <div className="text-lg-end text-center mt-lg-0 mt-3">
                                <p className="fs-3 mb-0">
                                  Don't Know your bike number?
                                  <a href="#" className=" ms-2 fw-bold">
                                    Click Here...
                                  </a>
                                </p>
                                <p className="fs-3 mb-0">
                                  Bought a new bike?
                                  <a href="#" className=" ms-2 fw-bold">
                                    Click Here...
                                  </a>
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row justify-content-between align-items my-5 pt-3">
                      <div className="col-12">
                        <p className="fs-1 mb-4">Key Features of Car Insurance Policy</p>
                        <table className="table table-bordered border-dark bg-primary-light fs-3">
                          <tbody>
                            <tr>
                              <td className="p-3">
                                <strong>Key Features</strong>
                              </td>
                              <td className="p-3">
                                <strong>Benefits Offered</strong>
                              </td>
                            </tr>
                            <tr>
                              <td className="p-3">Third Party Damages</td>
                              <td className="p-3">Covers both third-party death, injuries &amp; property damages</td>
                            </tr>
                            <tr>
                              <td className="p-3">Own Damage Cover</td>
                              <td className="p-3">Available under standalone &amp; comprehensive plan</td>
                            </tr>
                            <tr>
                              <td className="p-3">Personal Accident Cover</td>
                              <td className="p-3">Up to Rs. 15 lakh</td>
                            </tr>
                            <tr>
                              <td className="p-3">No Claim Bonus</td>
                              <td className="p-3">Up to 50%</td>
                            </tr>
                            <tr>
                              <td className="p-3">Add-On Covers</td>
                              <td className="p-3">More than 10 add-ons depending on the plan</td>
                            </tr>
                            <tr>
                              <td className="p-3">Car Insurance Premium</td>
                              <td className="p-3">Starting @ 2094/year*</td>
                            </tr>
                            <tr>
                              <td className="p-3">Buying/Renewal Process</td>
                              <td className="p-3">Online &amp; Offline</td>
                            </tr>
                            <tr>
                              <td className="p-3">Cashless Repairs</td>
                              <td className="p-3">Available</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>

                      <div className="col-12 position-relative mt-5 mb-3">
                        <h1 className="fs-1">Types of Car Insurance Policy In India</h1>
                        <p className="fs-3">
                          A car insurance policy is a protection shield for your car against any damages caused by
                          natural calamities such as floods, earthquakes, cyclones, accidents, thefts, etc. There are
                          three types of car insurance policies:A car insurance policy is a protection shield for your
                          car against any damages caused by natural calamities such as floods, earthquakes, cyclones,
                          accidents, thefts, etc. There are three types of car insurance policies:
                        </p>
                      </div>
                      <div className="col-12">
                        <div className="accordion" id="vehicleAccordion">
                          {/*----- accordion -----*/}
                          <div className="accordion-item bg-transparent mb-4">
                            <h2 className="accordion-header">
                              <button
                                className="accordion-button collapsed fs-2 px-lg-5"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#vehicleAccordion1"
                              >
                                Third Party Car Insurance
                              </button>
                            </h2>
                            <div
                              id="vehicleAccordion1"
                              className="accordion-collapse collapse"
                              data-bs-parent="#vehicleAccordion"
                            >
                              <div className="accordion-body fs-3 p-lg-5 p-3">
                                Third-party liability car insurance provides coverage for third-party financial
                                liabilities such as car damage, physical injury, death, disability as well as property
                                damage. The coverage offered in case of death is unlimited, while for property damage,
                                the coverage is limited to up to Rs. 7.5 lakh. Buying at least a third party car
                                insurance is mandatory n India for all cars as per the Motor Vehicles Act, 1988.
                                Third-party liability car insurance provides coverage for third-party financial
                                liabilities such as car damage, physical injury, death, disability as well as property
                                damage. The coverage offered in case of death is unlimited, while for property damage,
                                the coverage is limited to up to Rs. 7.5 lakh. Buying at least a third party car
                                insurance is mandatory n India for all cars as per the Motor Vehicles Act, 1988.
                              </div>
                            </div>
                          </div>
                          <div className="accordion-item bg-transparent mb-4">
                            <h2 className="accordion-header">
                              <button
                                className="accordion-button collapsed fs-2 px-lg-5"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#vehicleAccordion2"
                              >
                                Comprehensive Car Insurance
                              </button>
                            </h2>
                            <div
                              id="vehicleAccordion2"
                              className="accordion-collapse collapse"
                              data-bs-parent="#vehicleAccordion"
                            >
                              <div className="accordion-body fs-3 p-lg-5 p-3">
                                A comprehensive insurance policy provides coverage for third-party liability as well as
                                damages caused to your own car. In comparison with third-party liability insurance, a
                                comprehensive car insurance policy offers extensive coverage, more benefits &amp; covers
                                the damages caused to the insured car in case of an accident, collision, theft, etc. A
                                comprehensive insurance policy provides coverage for third-party liability as well as
                                damages caused to your own car. In comparison with third-party liability insurance, a
                                comprehensive car insurance policy offers extensive coverage, more benefits &amp; covers
                                the damages caused to the insured car in case of an accident, collision, theft, etc.
                              </div>
                            </div>
                          </div>
                          <div className="accordion-item bg-transparent mb-4">
                            <h2 className="accordion-header">
                              <button
                                className="accordion-button collapsed fs-2 px-lg-5"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#vehicleAccordion3"
                              >
                                Own Damage
                              </button>
                            </h2>
                            <div
                              id="vehicleAccordion3"
                              className="accordion-collapse collapse"
                              data-bs-parent="#vehicleAccordion"
                            >
                              <div className="accordion-body fs-3 p-lg-5 p-3">
                                Own damage car policy provides coverage to the policyholder in case their car sustains
                                any kind of damage. The third-party insurance does not cover your own damage so you have
                                to purchase this cover separately with your mandatory third party cover. Own damage car
                                policy provides coverage to the policyholder in case their car sustains any kind of
                                damage. The third-party insurance does not cover your own damage so you have to purchase
                                this cover separately with your mandatory third party cover.
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*----- container End -----*/}
      </section>
    </Layout>
  );
};

export default PolicyDetails;
