import moment from "moment";
import React from "react";

const InputBox = ({ label, type, error, name, register, policyStatus, id, placeholder, defaultValue, value }) => {
  let minDate =
    policyStatus === "continue"
      ? moment().format(`YYYY-MM-DD`)
      : policyStatus === "expired within 90 day"
      ? moment().subtract(90, "day").format(`YYYY-MM-DD`)
      : "";
  let maxDate =
    policyStatus === "continue" ? moment().add(90, "day").format(`YYYY-MM-DD`) : moment().format(`YYYY-MM-DD`);
  return (
    <div className="form-floating position-relative">
      {name == "PrePolicyEndDate" ? (
        <input
          {...register}
          name={name}
          type={type}
          min={minDate}
          max={maxDate}
          id={id}
          defaultValue={defaultValue}
          placeholder={placeholder}
          className={type == "date" ? "datepicker form-control w-100" : "form-control w-100"}
          value={value}
          data-date-format="mm/dd/yyyy"
        />
      ) : (
        <input
          {...register}
          name={name}
          type={type}
          id={id}
          defaultValue={defaultValue}
          placeholder={placeholder}
          className={type == "date" ? "datepicker form-control w-100" : "form-control w-100"}
          value={value}
          data-date-format="mm/dd/yyyy"
        />
      )}

      <label className="w-100" htmlFor={id}>
        {label}
      </label>

      {error && <p className="m-0 f-error">{error}</p>}
    </div>
  );
};

export default InputBox;
