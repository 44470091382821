import React from "react";
import "./footerpages.css";
import Header from "../Header";
import Footer from "../Footer";

function CapitalGuaranteePlan() {
  return (
    <>
      <Header />
      <div>
        <div className="insurance-cantainer">
          <h1> Capital Guarantee Plans</h1>
        </div>
        <div className="cantainer">
          <div className="box-info">
            <div className="box-1">
              <h2>
                "Capital Assured: Exploring Capital Guarantee Plans for
                Financial Security"
              </h2>
              <p>
                Capital guarantee plans in the insurance realm provide a secure
                and stable way for individuals to grow their investments while
                ensuring the safety of their principal amount. These plans
                combine elements of insurance and investment, offering a
                guarantee to protect the initial investment from market
                downturns or losses.When an individual invests in a capital
                guarantee plan, a portion of the premium is typically allocated
                to low-risk fixed-income securities, ensuring the capital
                remains intact. The insurance component comes into play by
                providing a life cover or death benefit, which guarantees a lump
                sum payout to the nominee or beneficiary in case of the insured
                individual's demise during the policy term.
              </p>
              <p>
                These plans often have a predefined maturity period, at the end
                of which the policyholder receives the assured amount along with
                potential bonuses or returns generated from the invested
                portion. The insurance aspect provides an added layer of
                financial security for the family or dependents in the event of
                the policyholder's untimely death.Capital guarantee plans with
                insurance coverage offer a balanced approach for individuals
                seeking growth on their investments while prioritizing the
                safety of their family's financial future. It's important to
                carefully read and understand the policy terms, conditions,
                premiums, returns, and other features before selecting the most
                suitable capital guarantee plan with insurance coverage.
                Consulting with a financial advisor is advisable to make an
                informed decision based on individual financial goals and risk
                tolerance.
              </p>
            </div>
            <div className="box-2 capitalimg">
              <img src="./assetss/images/capitalimg.png" alt="Error" />
            </div>
          </div>
        </div>
        <div className="vision-info">
          <div className="vision-box">
            <div className="img-box">
              <img src="./assetss/images/vision.png" alt="Error" />
            </div>
            <div className="vision-box1">
              <h2>Our vision</h2>
              <p>
                There are many variations of passages of Lorem Ipsum available,
                but the majority have suffered alteration in some form, by
                injected humour, or randomised words which don't look even
                slightly believable. If you are going to use a passage of Lorem
                Ipsum, you need to be sure there isn't anything embarrassing
                hidden in the middle of text. All the Lorem Ipsum generators on
                the Internet tend to repeat predefined chunks as necessary,
              </p>
            </div>
          </div>
        </div>
        <div className="btn-cantainer">
          <div className="btn-1">
            <h1>Solving your Insurance Needs on Time</h1>
          </div>
          <div className="btn-1">
            <h2>
              Get world-class insurance coverage that not only protects you but
              also helps you build wealth. Let us be your insurance partner
            </h2>
            <div className="btn-info">
              <button>Get Started</button>
              <button>Contact Us</button>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default CapitalGuaranteePlan;
