import React, { useEffect } from "react";
import Layout from "../../../common/Layout";
import { TabContent, TabPane } from "reactstrap";
import {
  getFiancierBankData,
  getStates,
  getVehiclePincode,
  getVehiclePreviousInsurer,
  getVehicleRto,
} from "../../../Services/masterServices";
import OwnerDetails from "../../../common/ProposalPage/OwnerDetails";
import VehicleDetails from "../../../common/ProposalPage/VehicleDetails";
import NomineeDetails from "../../../common/ProposalPage/NomineeDetails";
import PreviousPolicy from "../../../common/ProposalPage/PreviousPolicy";
import { useSelector } from "react-redux";
import moment from "moment";
import { PreviousInsurer } from "../../../utility/BankNames";
import { useState } from "react";
import Header from "../../../common/Header";
import { Link } from "react-router-dom";
import { dispatchMotorQuote } from "../../../../store/actions/userActions";
const Proposal = () => {
  const [activeTab, setActiveTab] = React.useState(1);
  const toggle = (tab) => setActiveTab(tab);
  const [state, setState] = React.useState([]);

  const [rtoData, setRtoData] = React.useState([]);
  const [pincodeData, setPincodeData] = React.useState([]);
  const [cityData, setCityData] = useState([]);
  const [financierData, setFinancierData] = useState([]);
  const [insurerData, setInsurerData] = React.useState([]);
  const [scrollY, setScrollY] = useState(0);
  const selectedPlan = useSelector((state) => state.root.selectedPlan);
  const apiRequestQQ = useSelector((state) => state.root.apiRequestQQ);
  const getData = useSelector((store) => store.root.QuickQouteResult);
  const [rtoLocation, setRtoLocation] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // getStates()
    //   .then(response => {
    //     if (response.state === true) {
    //       let i = 0;
    //       let arr = []
    //       while (i < response.data.length) {
    //         arr.push({
    //           value: response.data[i].Digit_Code,
    //           label: response.data[i].State_Name,
    //         })
    //         i++
    //       }
    //       setState(arr);
    //     }
    //   }).catch(err => console.log(err));
    console.log("getData", getData);
    setScrollY(window.scrollY);
    getVehiclePincode()
      .then((response) => {
        if (response.status === true) {
          let i = 0;
          let arr = [];
          let arr1 = [];
          let j = 0;
          while (i < response.data.length) {
            arr.push({
              value: response.data[i].Pin_Code,
              label:
                response.data[i].Pin_Code + " - " + response.data[i].District,
              District: response.data[i].District,
              City: response.data[i].City,
            });

            i++;
          }
          while (j < response.data.length) {
            arr1.push({
              value: response.data[j].Pin_Code,
              label: response.data[j].District,
              District: response.data[j].District,
              City: response.data[j].City,
            });

            j++;
          }
          setPincodeData(arr);
          setCityData(arr1);
        }
      })
      .catch((err) => console.log(err));

    getFiancierBankData()
      .then((response) => {
        if (response.status === true) {
          let i = 0;
          let arr = [];
          while (i < response.data.length) {
            arr.push({
              value: response.data[i].bank_name,
              label: response.data[i].bank_name,
              HDFCId: response.data[i].HDFCId,
            });

            i++;
          }
          setFinancierData(arr);
        }
      })
      .catch((err) => console.log(err));

    getVehiclePreviousInsurer().then((response) => {
      if (response.status === true) {
        let i = 0;
        let data = response.data;
        let arr = [];
        while (i < data.length) {
          let item = data[i];
          arr.push({
            option: item.Name,
            value: item.Digit_Code,
            insurerId: item?.prevInsurerId,
          });
          i++;
        }
        setInsurerData(arr);
      }
    });
    getVehicleRto().then((response) => {
      if (response.status === true) {
        let data = response.data;
        let i = 0;
        let arr = [];
        while (i < data.length) {
          let item = data[i];

          arr.push({
            label: item.registered_city_name + " (" + item.RTO_Code + ")",
            value: item.RTO_Code,
            registered_state_name: item.registered_state_name,
            id: item.id,
          });
          i++;
        }
        console.log(arr, "arrrrrrrrrrr");
        setRtoData(arr);

        setRtoLocation(
          arr.filter((option) => option.value === apiRequestQQ.RtoCode)
        );
      }
    });
  }, []);
  console.log(rtoData, "RTODATAAA");
  console.log(apiRequestQQ);
  console.log(rtoLocation, "rtoLocation");

  // useEffect(() => {
  //   if (rtoData.length > 0) {
  //     console.log("RTOOOOOOOO", rtoData);
  //     // setRtoLocation(rtoData);
  //     setRtoLocation(rtoData);
  //   }
  // }, [rtoData]);

  useEffect(() => {}, [rtoData]);

  const getPreviousPolicyName = () => {
    let index = PreviousInsurer.findIndex(
      (item) => item.Digit_Code == apiRequestQQ.PreInsurerCode
    );
    if (index > -1) {
      return PreviousInsurer[index].Name;
    } else {
      return "N/A";
    }
  };

  const handleStepClick = (step) => {
    activeTab > step ? setActiveTab(step) : "";
  };

  // if (rtoData.length === 0) {
  //   return <div>Loading...</div>;
  // }

  return (
    <Layout header2={false}>
      <section className="quotelist">
        <div className="container-fluid px-lg-5 px-3">
          <div
            className="Vehicleshortdisc px-3 py-4 pl-4"
            style={{ background: "#F1FDFF" }}
          >
            <div className="row mx-0 px-0 justify-content-between">
              <div className="col-10 d-flex justify-content-between">
                <ul>
                  <li className="mb-0 fs-3 fw-bold text-uppercase">
                    {" "}
                    {apiRequestQQ?.MakeName} {apiRequestQQ?.ModelName}{" "}
                    {apiRequestQQ?.VariantName} - ({apiRequestQQ.CubicCapacity})
                  </li>
                  <li className="my-3 fs-3">
                    {apiRequestQQ.VehicleType == "4w"
                      ? "Private Car"
                      : "Two Wheeler"}{" "}
                    -{" "}
                    {apiRequestQQ.IsVehicleNew
                      ? apiRequestQQ.RegistrationCity
                      : apiRequestQQ.RegistrationNumber}
                  </li>
                  {/* <p className="mb-0 fs-4 text-uppercase">
                    <strong>Quotation Number</strong> - PIBLMTRPC292202943432
                  </p> */}
                </ul>
                <ul>
                  <li className="mb-0 fs-3 text-uppercase">
                    <strong className="text-capitalize">
                      Previous Insurer
                    </strong>{" "}
                    - {getPreviousPolicyName()}
                  </li>
                  <li className="my-3 fs-3 text-uppercase">
                    <strong className="text-capitalize">
                      Previous Policy Type
                    </strong>{" "}
                    -{" "}
                    {apiRequestQQ?.PreviousPolicyType
                      ? apiRequestQQ?.PreviousPolicyType
                      : "N/A"}
                  </li>
                  <li className="mb-0 fs-3 text-uppercase">
                    <strong className="text-capitalize">
                      Policy Expiry Date
                    </strong>{" "}
                    -{" "}
                    {apiRequestQQ?.PrePolicyEndDate
                      ? moment(
                          apiRequestQQ.PrePolicyEndDate,
                          "YYYY-MM-DD"
                        ).format("DD MMM, YYYY")
                      : "N/A"}
                  </li>
                </ul>
                <ul>
                  <li className="mb-0 fs-3 text-uppercase">
                    <strong className="text-capitalize">
                      Manufacture Date
                    </strong>{" "}
                    -{" "}
                    {apiRequestQQ?.ManufaturingDate
                      ? moment(
                          apiRequestQQ.ManufaturingDate,
                          "YYYY-MM-DD"
                        ).format("DD MMM, YYYY")
                      : "N/A"}
                  </li>
                  <li className="my-3 fs-3 text-uppercase">
                    <strong className="text-capitalize">
                      Registration Date
                    </strong>{" "}
                    -{" "}
                    {apiRequestQQ?.RegistrationDate
                      ? moment(
                          apiRequestQQ.RegistrationDate,
                          "YYYY-MM-DD"
                        ).format("DD MMM, YYYY")
                      : "N/A"}
                  </li>
                </ul>
              </div>
              {/* <div className="col-2 text-end">
                <Link
                  to="/quotelist"
                  className="btn btn-outline-light py-3 px-4 bg-white text-dark my-4"
                >
                  <i className="fa fa-arrow-left fs-4" />
                </Link>
              </div> */}
            </div>
          </div>
          <div className="becomepos">
            <div className="container ">
              <div className="row ">
                <div className="col-md-12 ">
                  <div className="proposal">
                    <ul className="nav nav-tabs formsteps d-flex justify-content-start my-5 d-lg-flex d-block ">
                      <li
                        className="nav-item position-relative"
                        role="presentation"
                      >
                        <button
                          className={
                            activeTab === 1
                              ? "nav-link fs-2 shadow active"
                              : "nav-link fs-2 shadow"
                          }
                          onClick={() => handleStepClick(1)}
                        >
                          1
                        </button>
                        <small
                          className="proposalButtonOne me-lg-4 mt-lg-2 mt-3 d-block fs-3 fw-bold"
                          id="proposalBtnOne"
                          // style={{ marginLeft: "-20rem" }}
                        >
                          Personal Details
                        </small>
                      </li>
                      {selectedPlan.Api_name === "Magma" ?   ( apiRequestQQ.AddOns.PersonalAccident === true   && (<li className="nav-item position-relative">
                        <button
                          className={
                            activeTab ===  2
                              ? "nav-link fs-2 shadow active"
                              : "nav-link fs-2 shadow"
                          }
                          onClick={() => handleStepClick(2)}
                        >
                          2
                        </button>
                        <small
                          className=" proposalButtonTwo me-lg-4 mt-lg-2 mt-3 d-block fs-3 mb-0 fw-bold"
                          // style={{ marginLeft: "-20rem" }}
                        >
                          Nominee Details
                        </small>
                      </li>)) : (<li className="nav-item position-relative">
                        <button
                          className={
                            activeTab ===  2
                              ? "nav-link fs-2 shadow active"
                              : "nav-link fs-2 shadow"
                          }
                          onClick={() => handleStepClick(2)}
                        >
                          2
                        </button>
                        <small
                          className=" proposalButtonTwo me-lg-4 mt-lg-2 mt-3 d-block fs-3 mb-0 fw-bold"
                          // style={{ marginLeft: "-20rem" }}
                        >
                          Nominee Details
                        </small>
                      </li>)
                      }
                      <li className="nav-item position-relative">
                        <button
                          className={
                              activeTab === 3
                              ? "nav-link no-after fs-2 shadow active"
                              : "nav-link no-after fs-2 shadow"
                          }
                          onClick={() => handleStepClick(3)}
                        >
                         {selectedPlan.Api_name === "Magma" ?  (apiRequestQQ.AddOns.PersonalAccident === true || apiRequestQQ.docType === 'AADHAAR' || apiRequestQQ.docType === 'ckyc' ? 3 : 2) :  2}
                        </button>
                        <small
                          className=" proposalButtonThree me-lg-4 mt-lg-2 mt-3 d-block fs-3  mb-0 fw-bold"
                          // style={{ marginLeft: "-4rem" }}
                        >
                          Vehicle Details
                        </small>
                      </li>
                    </ul>

                    <TabContent activeTab={activeTab}>
                      <TabPane tabId={1}>
                        <OwnerDetails
                          pincodeData={pincodeData}
                          state={state}
                          activeTab={activeTab}
                          toggle={(tab) => toggle(tab)}
                          scrollY={scrollY}
                          setScrollY={setScrollY}
                        />
                      </TabPane>

                      <TabPane tabId={selectedPlan.Api_name === "Magma" ?  ( apiRequestQQ.AddOns.PersonalAccident === true || apiRequestQQ.docType === 'AADHAAR' || apiRequestQQ.docType === 'ckyc' ? 2 : 3 ): 2}>
                        {selectedPlan.Api_name === "Magma" ? (apiRequestQQ.AddOns.PersonalAccident === true || apiRequestQQ.docType === 'AADHAAR' || apiRequestQQ.docType === 'ckyc'?  (<NomineeDetails
                          activeTab={activeTab}
                          toggle={(tab) => toggle(tab)}
                          scrollY={scrollY}
                          setScrollY={setScrollY}
                        />)  : (rtoData.length > 0 && (
                          <VehicleDetails
                            cityData={cityData.reduce(
                              (c, n) =>
                                c.find((el) => el.District == n.District)
                                  ? c
                                  : [...c, n],
                              []
                            )}
                            insurerData={insurerData}
                            financierData={financierData}
                            rtoData={rtoLocation}
                            activeTab={activeTab}
                            toggle={(tab) => toggle(tab)}
                            scrollY={scrollY}
                            setScrollY={setScrollY}
                          />)
                        ) ) : <NomineeDetails
                          activeTab={activeTab}
                          toggle={(tab) => toggle(tab)}
                          scrollY={scrollY}
                          setScrollY={setScrollY}
                        /> }
                      </TabPane>
                      <TabPane tabId={selectedPlan.Api_name === "Magma" ?   (apiRequestQQ.AddOns.PersonalAccident === true || apiRequestQQ.docType === 'AADHAAR' || apiRequestQQ.docType === 'ckyc'  ? 3 : 2) : 3}>
                        {rtoData.length > 0 && (
                          <VehicleDetails
                            cityData={cityData.reduce(
                              (c, n) =>
                                c.find((el) => el.District == n.District)
                                  ? c
                                  : [...c, n],
                              []
                            )}
                            insurerData={insurerData}
                            financierData={financierData}
                            rtoData={rtoLocation}
                            activeTab={activeTab}
                            toggle={(tab) => toggle(tab)}
                            scrollY={scrollY}
                            setScrollY={setScrollY}
                          />
                        )}
                      </TabPane>
                    </TabContent>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default Proposal;
