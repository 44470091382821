import React, { useEffect } from "react";
import cookie from "react-cookies";
import { useDispatch } from "react-redux";
import { Route, Routes } from "react-router-dom";
import Index from "./components/pages/Index";
import BecomePos from "./components/pages/POS/BecomePos";
import RegisterPos from "./components/pages/POS/RegisterPos";
import Training from "./components/pages/POS/Training";
import PosExam from "./components/pages/POS/PosExam";
import Login from "./components/pages/Login";
import ChangePassword from "./components/pages/ChangePassword";
import InsuranceProcess1 from "./components/pages/OnlineProcess/insurance/InsuranceProcess1";
import InsuranceProcess2 from "./components/pages/OnlineProcess/insurance/InsuranceProcess2";
import Quotelist from "./components/pages/OnlineProcess/insurance/Quotelist";
import Proposal from "./components/pages/OnlineProcess/insurance/Proposal";
import PolicyOverview from "./components/pages/OnlineProcess/insurance/PolicyOverview";
import SuccessMessage from "./components/common/ProposalPage/SuccessMessage";
import ErrorMessage from "./components/common/ProposalPage/ErrorMessage";
import Aboutus from "./components/pages/Additional/Aboutus";
import ContactUs from "./components/pages/Additional/ContactUs";
import PrivacyandConditions from "./components/pages/Additional/PrivacyandConditions";
import PolicyDetails from "./components/pages/Additional/PolicyDetails";
import Faq from "./components/pages/Additional/Faq";
import HDFCPayment from "./components/pages/Additional/HDFCPayment";
import NewLogin from "./components/pages/NewLogin";
import ShriramPayment from "./components/pages/Additional/ShriramPaymentIntegration";
import MyQuote from "./components/pages/MyQuote";
import MyPolicy from "./components/pages/OnlineProcess/insurance/MyPolicy";
import ResetPassword from "./components/pages/ResetPassword";
import ForgetPassword from "./components/pages/ForgetPassword";
import HomeSection from "./components/common/Homepage/HomeSection";
import Blog from "./components/pages/Additional/Blog";
import BlogDetails from "./components/pages/Additional/BlogDetails";
import LifeInsurance from "./components/common/FooterPages/LifeInsurance";
import TermInsurance from "./components/common/FooterPages/TermInsurance";
import InvestmentPlan from "./components/common/FooterPages/InvestmentPlans";
import CapitalGuaranteePlan from "./components/common/FooterPages/CapitalGuranteePlans";
import ChildPlan from "./components/common/FooterPages/ChildPlan";
import BusinessInsurance from "./components/common/FooterPages/BusinessInsurance";
import SeniorCitizen from "./components/common/FooterPages/SeniorCitizen";
import SpouseTermPlan from "./components/common/FooterPages/SpouseTermPlan";
import Coronavirus from "./components/common/FooterPages/Coronavirus";
import HomeInsurance from "./components/common/FooterPages/HomeInsurance";
import Cyber from "./components/common/FooterPages/Cyber";
import TermReturn from "./components/common/FooterPages/TermReturn";
import FamilyHealthInsurance from "./components/common/FooterPages/FamilyHealthInsurance";
import GroupHealthInsurance from "./components/common/FooterPages/GroupHealthInsurance";
import InvestmentNriPlan from "./components/common/FooterPages/InvestmentNriPlans";
const App = () => {
  const dispatch = useDispatch();

  return (
    <Routes>
      <Route path="/" element={<Index />} />
      <Route path="/becomepos" element={<BecomePos />} />
      <Route path="/posregister" element={<RegisterPos />} />
      <Route path="/postraining" element={<Training />} />
      <Route path="/posExam" element={<PosExam />} />
      <Route path="/login" element={<Login />} />
      <Route path="/newlogin" element={<NewLogin />} />
      <Route path="/change-password" element={<ChangePassword />} />
      <Route path="/myquotelist" element={<MyQuote />} />

      {/* Online insurance */}
      <Route path="/insurance-select" element={<InsuranceProcess1 />} />
      <Route path="/insurance-mmv" element={<InsuranceProcess2 />} />
      {/* <Route path="/quotelist" element={<Quotelist />} /> */}
      <Route path="/quotelist/:id" element={<Quotelist />} />
      <Route path="/proposal" element={<Proposal />} />
      <Route path="/proposal/:id" element={<Proposal />} />
      <Route path="/policy-overview" element={<PolicyOverview />} />
      <Route path="/success-page" element={<SuccessMessage />} />
      <Route path="/success-page/:id" element={<SuccessMessage />} />
      <Route path="/error-page" element={<ErrorMessage />} />
      <Route path="/about-us" element={<Aboutus />} />
      <Route path="/blog" element={<Blog />} />
      <Route path="/blog-details" element={<BlogDetails />} />
      <Route path="/contact-us" element={<ContactUs />} />
      <Route path="/privacyandcondition" element={<PrivacyandConditions />} />
      <Route path="/car-insurance" element={<PolicyDetails />} />
      <Route path="/bike-insurance" element={<PolicyDetails />} />
      <Route path="/pcv-insurance" element={<PolicyDetails />} />
      <Route path="/gcv-insurance" element={<PolicyDetails />} />
      {/* <Route path="/hdfcpayment" element={<HDFCPayment />} /> */}
      {/* <Route path="/shrirampayment" element={<ShriramPayment />} /> */}
      <Route path="/faq" element={<Faq />} />
      <Route path="/my-policies" element={<MyPolicy />} />
      <Route path="/resetpassword" element={<ResetPassword />} />
      <Route path="/forgetpassword" element={<ForgetPassword />} />
      <Route path="/motor-process" element={<HomeSection />} />
      <Route path="/life-insurance" element={<LifeInsurance />} />
      <Route path="/term-insurance" element={<TermInsurance />} />
      <Route path="/investment-plans" element={<InvestmentPlan />} />
      <Route
        path="/capital-guarantee-plan"
        element={<CapitalGuaranteePlan />}
      />
      <Route path="/child-plan" element={<ChildPlan />} />
      <Route path="/business-insurance" element={<BusinessInsurance />} />
      <Route path="/senior-citizen" element={<SeniorCitizen />} />
      <Route path="/spouse-term-plan" element={<SpouseTermPlan />} />
      <Route path="/coronavirus" element={<Coronavirus />} />
      <Route path="/home-insurance" element={<HomeInsurance />} />
      <Route path="/cyber" element={<Cyber />} />
      <Route path="/term-return" element={<TermReturn />} />
      <Route
        path="/family-health-insurance"
        element={<FamilyHealthInsurance />}
      />
      <Route
        path="/group-health-insurance"
        element={<GroupHealthInsurance />}
      />
      <Route path="/investment-nri-plan" element={<InvestmentNriPlan />} />

      {/* .. */}
    </Routes>
  );
};

export default App;
