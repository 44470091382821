import React from "react";
import Layout from "../../common/Layout";
const PrivacyandConditions = () => {
  return (
    <Layout>
      <div>
        {/*----- Privacy Policy Section start -----*/}
        <section className="privacyterms-section">
          {/*----- Blur set effect -----*/}
          <div className="blur-bg-blocks">
            <aside className="blur-bg-set">
              <div className="blur-bg blur-bg-a" />
              <div className="blur-bg blur-bg-b" />
              <div className="blur-bg blur-bg-c" />
            </aside>
          </div>

          {/*----- container start -----*/}
          <div className="container-lg container-fluid">
            <div className="row justify-content-between align-items-center">
              <div className="col-lg-8 col-12 text-center mx-auto mt-5 pt-5 mb-lg-5 pb-lg-5">
                <h1 className="text-capitalize about-heading mx-auto">
                  Expert Cover Privacy Policy
                </h1>
                <p className="my-5 fs-2">
                  Generally, we may collect and use personal information for
                  many purposes, including, but not limited to: billing, product
                  and service fulfillment, understanding customer needs,
                  providing a better website, improving products and services,
                  and we are communicating with customers and potential
                  customers regarding our products and services with third-party
                  products and services.
                </p>
              </div>

              <div className="col-12">
                <h1 className="main-heading">Privacy Policy -</h1>

                <p className="my-5 fs-2">
                  Expert Cover Insurance Broker Pvt. Ltd, as mentioned in this
                  privacy policy, is deeply committed to safeguarding the
                  privacy of user data and information. We are dedicated to
                  ensuring the security of your information when you access and
                  utilize our services across various Expert websites, including
                  but not limited to 'www.expertcover.com', mobile sites, mobile
                  applications, chrome extensions, and plugins accessible
                  through internet-enabled smart devices or when you engage in
                  business transactions with us. This Privacy Policy pertains to
                  Expert Cover and has been designed to provide you with a clear
                  understanding of how we manage, collect, utilize, store,
                  process, transfer, share, and protect your data when you visit
                  our platform or avail of our services, or even when you simply
                  visit the platform. It is essential to note that this Privacy
                  Policy is considered an electronic record, aligning with the
                  Indian Information Technology Act, 2000, and its associated
                  rules, as amended from time to time. As such, it does not
                  require any physical signature or seal. Throughout this
                  document, the terms "We," "Us," and "Our" refer to our system,
                  Expert Cover Insurance Broker Pvt. Ltd, and the terms "You,"
                  "Your," and "Yourself" refer to the users who visit, access,
                  or use our system.
                </p>

                <h1 className="main-heading">Terms and Conditions -</h1>

                <h1 className="text-decoration-underline mt-5 mb-4 fs-1">
                  Acknowledgment:
                </h1>

                <p className=" fs-2">
                  Please read this privacy policy carefully. You indicate that
                  you understand, agree, and consent to this privacy policy.
                  Hence, by visiting our platform or by availing any of our
                  services, you hereby give your unconditional consent or
                  agreement to Expert Cover Insurance as provided under Section
                  43A and Section 72A of the Indian Information Technology Act,
                  2000 for the collection, use, storage, processing, sharing,
                  transfer, and disclosure of your information. You acknowledge
                  that you have all legal rights and lawful authority to share
                  the information with us and further acknowledge that by
                  collecting, sharing, processing, and transferring information
                  provided by you shall not cause any loss or wrongful gain to
                  you or any other person. If you do not agree with the terms of
                  this privacy policy, please do not access/use our platform or
                  avail any of our services on our platform.
                </p>

                <h1 className="text-decoration-underline mt-5 mb-4 fs-1">
                  Topics Covered:
                </h1>

                {/* <p className=" fs-2">
                  This policy is designed to make you understand:
                </p> */}

                <ul className="fs-2">
                  <li>
                    The type of information which You share with Us or which We
                    collect during Your usage of our Platform or availing any
                    Services from Our Platform.
                  </li>

                  <li>
                    The purpose of collection, storage, processing and
                    transferring of Your Information by Us.
                  </li>

                  <li>
                    Security measures implemented by Us to protect Your
                    Information as mandated by law.
                  </li>

                  <li>
                    Disclosure, sharing and transfer of Your personal
                    information by Us and purpose of such disclosure, sharing or
                    transfer.
                  </li>
                </ul>

                <h1 className="text-decoration-underline mt-5 mb-4 fs-1">
                  Collection of Data:
                </h1>

                <ul className="fs-2 mb-5 pb-5">
                  <li className="fw-bold">
                    Personal Identification Information
                    <p className="fw-normal">
                      Collection of particular identification information in
                      ways including but not limited to when you visit,
                      register, distribute, fill out a form on our website
                      mobile operation independently and in connection with
                      other conditioning, services, features, or coffers which
                      we make available on our website mobile operation. You may
                      be asked for, as applicable, name, dispatch address,
                      posting address, phone number. You may, still, visit our
                      website mobile operation anonymously. We'll collect
                      particular identification information from you only if you
                      freely submit similar information to us. You can always
                      refuse to give particular identification information,
                      which may keep you from engaging in certain website mobile
                      operation- related conditioning.
                    </p>
                  </li>

                  <li className="fw-bold">
                    Non-Personal Identification Information
                    <p className="fw-normal">
                      Collection of non-personal identification information
                      whenever you interact with our website mobile operation.
                      Non-personal identification information may include the
                      cybersurfer name, the type of computer and specialized
                      information about your means of connection to our website
                      mobile operation, similar as the operating system, the
                      Internet service providers employed, and other analogous
                      information.
                    </p>
                  </li>

                  <li className="fw-bold">
                    Security
                    <p className="fw-normal">
                      We take complete care to cover the information collected
                      from you by employing applicable specialized and security
                      measure at all cases. We insure complete protection
                      against unauthorized/ unlawful use/ revision of
                      information and any accidental loss, destruction or damage
                      to information by planting multiple physical, procedural
                      and electronic security measures.
                      <p></p>
                      In malignancy of all these measures, no electronic
                      storehouse system or transmission system can be considered
                      as fully secure and neither can we guarantee you ultimate
                      security. It's largely recommended that indeed you take
                      the responsibility of maintaining the confidentiality and
                      security of your login, id and word and avoid telling
                      these credentials to any third party.
                    </p>
                  </li>

                  <li className="fw-bold">
                    Collection of cookies
                    <p className="fw-normal">
                      Our site may use cookies to enhance your experience.
                      <p></p>A piece of data stored on the user’s computer tied
                      to user’s information is a cookie. Probus might use both
                      sessions: Persistent Cookies (used by PRP to store
                      whether, for example, you want your password remembered or
                      not, and other information)
                      <p></p>
                      <mark>
                        {" "}
                        Note: Eyefuls used on the PRP website don't contain
                        tête-à-tête identifiable information and ID eyefuls(
                        wherein once you close your cybersurfer or log out, the
                        cookie terminates and is canceled ).
                      </mark>
                      <p></p>A patient cookie is a small textbook train stored
                      on your computer’s hard drive for an extended period.
                      Session ID eyefuls may be used by PRP to track stoner
                      preferences while the stoner is visiting the website. They
                      also help to minimize cargo times and save on garçon
                      processing.
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          {/*----- container End -----*/}
        </section>
        {/*----- Privacy Policy Section End -----*/}
      </div>
    </Layout>
  );
};

export default PrivacyandConditions;
