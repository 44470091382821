import {
  GetDataWithToken,
  PostDataWithToken,
  PostImageDataWithToken,
} from "../../api/apiHelper";
import { posEndPoint } from "./endpoints";
import { Store } from "react-notifications-component";
export const handleBecomePos = (data) => {
  try {
    const response = PostDataWithToken(posEndPoint.becomePos, data);
    return response;
  } catch (err) {
    throw err;
  }
};

export const handleLoginPos = (data) => {
  try {
    const response = PostDataWithToken(posEndPoint.posLogin, data);
    return response;
  } catch (err) {
    throw err;
  }
};

export const handleRegisterPos = (id, data) => {
  try {
    const response = PostImageDataWithToken(
      posEndPoint.registerPos + "/" + id,
      data
    );
    return response;
  } catch (err) {
    throw err;
  }
};

export const getTrainingModule = () => {
  try {
    const response = GetDataWithToken(posEndPoint.trainingModule, "");
    return response;
  } catch (err) {
    throw err;
  }
};

export const handleModuleComplete = (id) => {
  try {
    const response = GetDataWithToken(
      posEndPoint.moduleComplete + "/" + id,
      ""
    );
    return response;
  } catch (err) {
    throw err;
  }
};

export const handleStartExam = () => {
  try {
    const response = GetDataWithToken(posEndPoint.startExam, "");
    return response;
  } catch (err) {
    throw err;
  }
};

export const handlePostAnswer = (data) => {
  try {
    const response = PostDataWithToken(posEndPoint.postAnwer, data);
    return response;
  } catch (err) {
    throw err;
  }
};

export const handleEndExam = () => {
  try {
    const response = GetDataWithToken(posEndPoint.endExam, "");
    return response;
  } catch (err) {
    throw err;
  }
};

export const getExamResult = () => {
  try {
    const response = GetDataWithToken(posEndPoint.examResult, "");
    return response;
  } catch (err) {
    throw err;
  }
};

export const handleChangePassword = (data) => {
  try {
    const response = PostDataWithToken(posEndPoint.changePassword, data);
    return response;
  } catch (err) {
    throw err;
  }
};

export const handleGetStates = () => {
  try {
    const response = GetDataWithToken(posEndPoint.getStates, "");
    return response;
  } catch (err) {
    throw err;
  }
};

export const handleGetDistrict = (id) => {
  try {
    const response = GetDataWithToken(posEndPoint.getDistricts + "/" + id, "");
    return response;
  } catch (err) {
    throw err;
  }
};
export const handleGetBranch = (id) => {
  try {
    const response = GetDataWithToken(
      posEndPoint.getBranches + "/?district=" + id,
      ""
    );
    return response;
  } catch (err) {
    throw err;
  }
};

export const sendErrorMessage = (response) => {
  console.log("response", response.data.message);
  Store.addNotification({
    // title: "Error!",
    message: response.data.message,
    type: "danger",
    insert: "top",
    container: "top-right",
    animationIn: ["animate__animated", "animate__fadeIn"],
    animationOut: ["animate__animated", "animate__fadeOut"],
    dismiss: {
      duration: 3000,
      onScreen: true,
    },
  });
  //   swal("Error", response.data.message, "error");
};

export const sendSuccessMessage = (response) => {
  //   swal("Success", response.message, "success");
  Store.addNotification({
    // title: "Seccess!",
    message: response.message,
    type: "success",
    insert: "top",
    container: "top-right",
    animationIn: ["animate__animated", "animate__fadeIn"],
    animationOut: ["animate__animated", "animate__fadeOut"],
    dismiss: {
      duration: 3000,
      onScreen: true,
    },
  });
};

export const sendErrorInfo = (message) => {
  //   swal("Error", message, "error");
  Store.addNotification({
    // title: "Error!",
    message: message,
    type: "danger",
    insert: "top",
    container: "top-right",
    animationIn: ["animate__animated", "animate__fadeIn"],
    animationOut: ["animate__animated", "animate__fadeOut"],
    dismiss: {
      duration: 3000,
      onScreen: true,
    },
  });
};

export const sendSuccessInfo = (message) => {
  //   swal("Success", message, "success");
  Store.addNotification({
    // title: "Seccess!",
    message: message,
    type: "success",
    insert: "top",
    container: "top-right",
    animationIn: ["animate__animated", "animate__fadeIn"],
    animationOut: ["animate__animated", "animate__fadeOut"],
    dismiss: {
      duration: 5000,
      onScreen: true,
    },
  });
};
