import React from "react";
import { Link } from "react-router-dom";
import Header from "../../common/Header";
import Footer from "../../common/Footer";

const AboutUs = () => {
  return (
    <div>
      <Header />
      <div className="breadcrumb">
        <h2>About Us</h2>
      </div>

      <div className="findinsurance">
        <div className="container">
          <div className="findinscnt">
            <h2>Your Trusted Insurance Partner Since 2008</h2>
            <p>
              Expert Cover has been your unwavering insurance partner since
              2008, providing you with peace of mind through a decade of
              dedicated service.
            </p>
            <p>
              We understand that your insurance needs are as unique as you are.
              Our commitment to excellence has set us apart in the industry.
              With a focus on trust and transparency, we've made it our mission
              to provide comprehensive insurance solutions that cater to your
              individual requirements.
            </p>
            <p>
              Over the years, we've witnessed the importance of safeguarding
              what matters most to you and your loved ones. We take the time to
              listen, to understand, and to guide you through the insurance
              process. We ensure that you have the right coverage for your
              unique circumstances. We're not just an insurance provider; we're
              your reliable partner in protecting your future.
            </p>
            <p>
              {" "}
              At Expert Cover, we believe in making insurance easy to understand
              and accessible. We're dedicated to delivering the peace of mind
              you deserve.
            </p>
          </div>
          <div className="aboutimg">
            <img
              src={"/assetss/images/aboutimg01.png"}
              alt="img"
              className="img-fluid"
            />
          </div>
        </div>
      </div>

      {/* <!-- How do we do --> */}
      <div className="howdowe">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h2 className="howdoheading">How do we do it?</h2>
              <p className="wedotitle">
                Our approach is grounded in trust, transparency, and
                professionalism. We carefully tailor insurance solutions to your
                unique needs, simplifying the buying process, providing expert
                guidance in policy management, and ensuring swift and fair claim
                settlements. Your peace of mind is our top priority.
              </p>
            </div>
          </div>

          <div className="row">
            <div className="col-md-4">
              <div className="ourmanegement">
                <img
                  src={"/assetss/images/buyinginsuicon.png"}
                  alt="img"
                  className="img-fluid"
                />
                <h2>Buying Insurance</h2>
                <p>
                  We simplify the process of buying insurance. It's our priority
                  to ensure you have the right coverage for your unique
                  circumstances.
                </p>
              </div>
            </div>

            <div className="col-md-4">
              <div className="ourmanegement">
                <img
                  src={"/assetss/images/manageinsicon.png"}
                  alt="img"
                  className="img-fluid"
                />
                <h2>Managing Insurance</h2>
                <p>
                  We're here to assist you in managing your insurance policies.
                  Our experts are always available to answer your questions and
                  provide support.
                </p>
              </div>
            </div>

            <div className="col-md-4">
              <div className="ourmanegement">
                <img
                  src={"/assetss/images/claimgicon.png"}
                  alt="img"
                  className="img-fluid"
                />
                <h2>Claim Settlement</h2>
                <p>
                  In the unfortunate event of a claim, we guide you through the
                  process and ensure a swift and fair settlement. Your peace of
                  mind is our priority.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <!-- Vision --> */}
      <div className="visionsec">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-lg-6">
              <img
                src={"/assetss/images/vision.png"}
                alt="img"
                className="img-fluid"
              />
            </div>

            <div className="col-md-12 col-lg-6">
              <div className="visioncnt">
                <h2>Our vision</h2>
                <p>
                  Our vision at Expert Cover is to provide tailored insurance
                  solutions that protect you against life's uncertainties. We
                  aim to build trust with our clients, deliver quality service,
                  and become your long-term partner in safeguarding your future.
                </p>
                <p>
                  {" "}
                  We are committed to ensuring you are prepared for any
                  situation. Your peace of mind is our top priority, and our
                  goal is to help you achieve your financial goals while staying
                  protected.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="solutionsec">
        <div className="container">
          <h2>Solving Your Insurance Needs on Time</h2>
          <div className="getcover">
            <p>
              Get world-class insurance coverage that not only protects you but
              also helps you build wealth. Let us be your insurance partner.
            </p>
            <div className="getworldbtn">
              <button>
                <Link to="/">Get Started</Link>
              </button>
              <button>
                <Link to="/contact-us">Contact Us</Link>
              </button>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default AboutUs;
