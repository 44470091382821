import CryptoJS from "crypto-js";

export const VEHICLE_TYPE = {
    FOUR_WHEELER: "4W",
    TWO_WHEELER: "2W",
    GCV: "gcv",
    PCV: "pcv",
  };
  
  export const CUSTOMER_TYPE = {
    INDIVIDUAL: "individual",
    ORGANISATION: "organisation",
  };
  
  export const POLICY_TYPE = {
    COMPREHENSIVE: "comprehensive",
    THIRDPARTY: "thirdParty",
    ODONLY: "odOnly",
    BUNDLED: "comprehensive",
  };
  
  export const POLICY_STATUS = {
    NOTEXPIRED: "rollover",
    EXPIREDWITHIN90: "ExpiredWithin90Days",
    EXPIREDBEFORE90: "ExpiredBefore90Days",
    NEW: "new",
  };
  
  export const kycSteps = {
    before_proposal: "before_proposal",
    in_proposal: "in_proposal",
    before_payment: "before_payment",
    before_policy_doc: "before_policy_doc",
  };
  
  export const kycStepPriority = {
    before_proposal: 1,
    in_proposal: 2,
    before_payment: 3,
    before_policy_doc: 4,
  };
  
  export const caseStatus = {
    quotesRequested: "Quotes Requested",
    quotesInProcess: "Quotes In Process",
    quotesGenerated: "Quotes Generated",
    proposalRequest: "Proposal Requested",
    proposalPremiumMismatch: "Proposal Premium Mismatch",
    proposalSuccess: "Proposal Successful",
    proposalFailure: "Proposal Failure",
    paymentRequested: "Payment Requested",
    paymentSuccess: "Payment Success",
    paymentPending: "Payment Pending",
    paymentFailure: "Payment Failed",
    policyDocRequested: "Policy Document Requested",
    policyDocSuccess: "Policy Document Requested",
    policyDocPending: "Policy Document Pending",
    policyDocFailure: "Policy Document Fail",
  };
  
  export const kycStatus = {
    success: "success",
    failure: "failure",
    pending: "pending",
  };
  
  export const kycDoc = {
    passport: "passport",
    voterId: "voterId",
    pan: "pan",
    dl: "dl",
    adhaar: "adhaar",
    nregaCard: "nregaCard",
    gstin: "gstin",
    ckycNumber: "ckycNumber",
  };
  
  export const kycType = {
    ckyc: "ckyc",
    ovd: "ovd",
  };
  

  export const decryptAes256Text = (encrypted, key) => {
    // Decode base64 encoded string to a WordArray
    const input = CryptoJS.enc.Base64.parse(encrypted);
  
    // Extract the IV (first 16 bytes) and the ciphertext (remaining bytes)
    const iv = CryptoJS.lib.WordArray.create(input.words.slice(0, 4), 16); // First 16 bytes (128 bits)
    const ciphertext = CryptoJS.lib.WordArray.create(
      input.words.slice(4),
      input.sigBytes - 16
    ); // Remaining bytes
  
    // Hash the key using SHA-256
    const hashedKey = CryptoJS.SHA256(key);
  
    // Decrypt the ciphertext using the hashed key and IV
    const decrypted = CryptoJS.AES.decrypt(
      { ciphertext: ciphertext },
      hashedKey,
      {
        iv: iv,
        mode: CryptoJS.mode.CTR,
        padding: CryptoJS.pad.NoPadding, // NoPadding because CTR mode doesn't require padding
      }
    );
  
    // Convert decrypted WordArray to UTF-8 string
    return CryptoJS.enc.Utf8.stringify(decrypted);
  };