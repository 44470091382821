import React from "react";
import "./footerpages.css";
import Header from "../Header";
import Footer from "../Footer";

function ChildPlan() {
  return (
    <>
      <Header />
      <div>
        <div className="insurance-cantainer">
          <h1>Child Plans</h1>
        </div>
        <div className="cantainer">
          <div className="box-info">
            <div className="box-1">
              <h2>
                "FutureShield: Safeguarding Tomorrow with Child Insurance Plans"
              </h2>
              <p>
                A child insurance plan is a specialized financial product
                designed to secure a child's future by providing a financial
                safety net and meeting key life milestones. This type of
                insurance ensures that your child's financial needs are met even
                in your absence, offering peace of mind to parents. Typically,
                child insurance plans come with a dual benefit of life insurance
                coverage for the parent or guardian and a savings component for
                the child. The policy continues to grow and accumulate value
                over time, serving as an investment for important life events
                such as higher education, marriage, or starting a business.
              </p>
              <p>
                Upon the policyholder's unfortunate demise, the insurance
                company usually waives future premiums, ensuring that the policy
                remains active and benefits are paid out to the child as
                planned. Some plans offer staggered payouts to fund various
                milestones like higher education expenses at different stages of
                the child's life. Parents can customize these plans based on
                their financial goals and desired coverage. It's essential to
                carefully choose the coverage amount, premium payment frequency,
                and policy tenure to align with the child's anticipated needs
                and the family's financial situation.
              </p>
            </div>
            <div className="box-2 child">
              <img src="./assetss/images/child.png" alt="Erroe" />
            </div>
          </div>
        </div>
        <div className="vision-info">
          <div className="vision-box">
            <div className="img-box">
              <img src="./assetss/images/vision.png" alt="Error" />
            </div>
            <div className="vision-box1">
              <h2>Our vision</h2>
              <p>
                There are many variations of passages of Lorem Ipsum available,
                but the majority have suffered alteration in some form, by
                injected humour, or randomised words which don't look even
                slightly believable. If you are going to use a passage of Lorem
                Ipsum, you need to be sure there isn't anything embarrassing
                hidden in the middle of text. All the Lorem Ipsum generators on
                the Internet tend to repeat predefined chunks as necessary,
              </p>
            </div>
          </div>
        </div>
        <div className="btn-cantainer">
          <div className="btn-1">
            <h1>Solving your Insurance Needs on Time</h1>
          </div>
          <div className="btn-1">
            <h2>
              Get world-class insurance coverage that not only protects you but
              also helps you build wealth. Let us be your insurance partner.
            </h2>
            <div className="btn-info">
              <button>Get Started</button>
              <button>Contact Us</button>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default ChildPlan;
