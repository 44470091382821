import React, { useState } from "react";
import { Link } from "react-router-dom";
import Header from "../../common/Header";
import Footer from "../../common/Footer";
import { useForm } from "react-hook-form";

const ContactUs = () => {
  const [message, setMessage] = useState("");

  const {
    control,
    handleSubmit,
    formState: { errors },
    register,
  } = useForm();

  const handleMessageChange = (e) => {
    const input = e.target.value;

    if (input.length <= 200) {
      setMessage(input);
    }
  };

  const onSubmit = (data) => {
    // Handle form submission here
    console.log(data);
  };

  return (
    <div>
      <Header />
      {/* <!-- Bread Crumb --> */}
      <div className="breadcrumb">
        <h2>Contact Us</h2>
      </div>

      <div className="contactsec">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-lg-6">
              <div className="contactcnt">
                <h2>Contact Info</h2>
                <p className="contactinfo">
                  Feel free to get in touch with us for any of your
                  insurance-related needs. Our team is dedicated to providing
                  you with personalized assistance and expert guidance. We
                  understand that every question or concern matters, and we're
                  here to make your insurance experience as smooth and
                  transparent as possible. Your peace of mind is our priority.
                </p>
                <ul>
                  <li>
                    <img
                      src={"/assetss/images/mapicon.png"}
                      alt="img"
                      className="img-fluid"
                    />
                    <div className="addressbox">
                      <h3>Address</h3>
                      <p>
                        F-201, Tower A, Palam Corporate Plaza, Near Park View
                        Building, Palam Vihar, <br />
                        Gurgaon-122017
                      </p>
                    </div>
                  </li>

                  <li>
                    <img
                      src={"/assetss/images/mobileicon.png"}
                      alt="img"
                      className="img-fluid"
                    />
                    <div className="addressbox">
                      <h3>Mobile</h3>
                      <a
                        href="tel:+917862002244"
                        className="fs-3 text-decoration-none"
                      >
                        +91 - 7862002244
                      </a>
                      <span className="fs-3"> / </span>
                      <a
                        href="tel:01244363759"
                        className="fs-3 text-decoration-none"
                      >
                        0124 - 4363759
                      </a>
                    </div>
                  </li>

                  <li>
                    <img
                      src={"/assetss/images/emailicon.png"}
                      alt="img"
                      className="img-fluid"
                    />
                    <div className="addressbox">
                      <h3>Email</h3>
                      <h4>
                        <a
                          href="mailto:support@expertcover.com"
                          className="text-lowercase"
                        >
                          support@expertcover.com
                        </a>
                      </h4>
                    </div>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-md-12 col-lg-6">
              <div className="gettouchform">
                <h2>Get in Touch</h2>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <ul>
                    <li>
                      <label>Name</label>
                      <input
                        type="text"
                        name="Name"
                        className="form-control"
                        maxLength={31}
                        {...register("Name", {
                          required: "Name is required",
                          maxLength: {
                            value: 30,
                            message: "Name should not exceed 30 characters.",
                          },
                          pattern: {
                            value: /^[A-Za-z\s]+$/,
                            message:
                              "Name should only contain alphabetic characters and spaces.",
                          },
                        })}
                      />
                      {errors.Name && (
                        <p className="f-error fs-4 mx-2">
                          {errors.Name.message}
                        </p>
                      )}
                    </li>

                    <li>
                      <label>Email</label>
                      <input
                        type="email"
                        name="Email"
                        className="form-control"
                        maxLength={31}
                        {...register("Email", {
                          required: "Email is required",

                          maxLength: {
                            value: 30,
                            message: "Email should not exceed 30 characters.",
                          },
                          pattern: {
                            value: /^\S+@\S+\.\S+$/,
                            message: "Invalid email format.",
                          },
                        })}
                      />
                      {errors.Email && (
                        <p className="f-error fs-4 mx-2">
                          {errors.Email.message}
                        </p>
                      )}
                    </li>

                    <li>
                      <label>Mobile</label>
                      <input
                        type="number"
                        name="Mobile"
                        className="form-control"
                        {...register("Mobile", {
                          required: "Mobile is required",
                          pattern: {
                            value:
                              /((\+*)((0[ -]*)*|((91 )*))((\d{12})+|(\d{10})+))|\d{5}([- ]*)\d{6}/,
                            message: "Invalid Mobile Number",
                          },
                        })}
                        onInput={(e) => {
                          e.target.value = e.target.value.slice(0, 10);
                        }}
                      />
                      {errors.Mobile && (
                        <p className="f-error fs-4 mx-2">
                          {errors.Mobile.message}
                        </p>
                      )}
                    </li>

                    <li>
                      <label>Message</label>
                      <textarea
                        name="Message"
                        className="form-control"
                        maxLength={201}
                        onChange={handleMessageChange}
                        {...register("Message", {
                          required: "Message is required",
                          maxLength: {
                            value: 200,
                            message:
                              "Message should not exceed 200 characters.",
                          },
                        })}
                      ></textarea>
                      {errors.Message && (
                        <p className="f-error fs-4 mx-2">
                          {errors.Message.message}
                        </p>
                      )}
                    </li>
                  </ul>
                  <button type="submit" className="btn btn-primary">
                    Submit
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default ContactUs;
