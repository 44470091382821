import React from "react";
import "./footerpages.css";
import Header from "../Header";
import Footer from "../Footer";

function Coronavirus() {
  return (
    <>
      <Header />
      <div>
        <div className="insurance-cantainer">
          <h1>Coronavirus insurance</h1>
        </div>
        <div className="cantainer">
          <div className="box-info">
            <div className="box-1">
              <h2>
                "Delving into insurance options that cover cancellations of
                events, trips, or plans due to COVID-19, offering insights into
                how such coverage works."
              </h2>
              <p>
                "Coronavirus insurance" refers to insurance coverage
                specifically tailored to address the unique circumstances and
                risks posed by the COVID-19 pandemic caused by the novel
                coronavirus, SARS-CoV-2. This specialized insurance provides
                financial protection and assistance in various scenarios related
                to the virus. Typically, coronavirus insurance covers medical
                expenses related to COVID-19, encompassing hospitalization,
                testing, treatment, and medications. It may also extend to cover
                quarantine costs if required due to exposure or infection.
                Travel-related coverage is common, offering protection for trip
                cancellations or interruptions caused by the pandemic.
                Additionally, event cancellation insurance can mitigate losses
                from events that need to be called off due to COVID-19
                restrictions.
              </p>
              <p>
                Business interruption coverage is crucial for enterprises,
                providing compensation for losses resulting from
                pandemic-induced closures or reduced operations. Life insurance
                policies may include provisions regarding coverage and payouts
                in case of COVID-19-related fatalities. However, it's essential
                to carefully review the policy terms, including any
                pandemic-related exclusions or limitations. Adapting insurance
                coverage to evolving circumstances is vital, ensuring the policy
                remains appropriate and effective amidst changing dynamics of
                the pandemic."
              </p>
            </div>
            <div className="box-2 corona">
              <img src="./assetss/images/corona.png" alt="Erroe" />
            </div>
          </div>
        </div>
        <div className="vision-info">
          <div className="vision-box">
            <div className="img-box">
              <img src="./assetss/images/vision.png" alt="Error" />
            </div>
            <div className="vision-box1">
              <h2>Our vision</h2>
              <p>
                There are many variations of passages of Lorem Ipsum available,
                but the majority have suffered alteration in some form, by
                injected humour, or randomised words which don't look even
                slightly believable. If you are going to use a passage of Lorem
                Ipsum, you need to be sure there isn't anything embarrassing
                hidden in the middle of text. All the Lorem Ipsum generators on
                the Internet tend to repeat predefined chunks as necessary,
              </p>
            </div>
          </div>
        </div>
        <div className="btn-cantainer">
          <div className="btn-1">
            <h1>Solving your Insurance Needs on Time</h1>
          </div>
          <div className="btn-1">
            <h2>
              Get world-class insurance coverage that not only protects you but
              also helps you build wealth. Let us be your insurance partner.
            </h2>
            <div className="btn-info">
              <button>Get Started</button>
              <button>Contact Us</button>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Coronavirus;
