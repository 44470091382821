import React from "react";
import "./footerpages.css";
import Header from "../Header";
import Footer from "../Footer";

function BusinessInsurance() {
  return (
    <>
      <Header />
      <div>
        <div className="insurance-cantainer">
          <h1>Business insurance</h1>
        </div>
        <div className="cantainer">
          <div className="box-info">
            <div className="box-1">
              <h2>
                "Safeguard Your Business: Comprehensive Business Insurance
                Solutions"
              </h2>
              <p>
                Business insurance is a critical component of safeguarding a
                company's assets, operations, and future. It provides financial
                protection against various risks that a business may face,
                ensuring continuity and peace of mind for the stakeholders
                involved. These insurance policies typically cover a range of
                areas, including property damage, liability, employee-related
                risks, and business interruption due to unforeseen events like
                natural disasters, accidents, or legal disputes. Property
                insurance protects physical assets such as buildings, equipment,
                and inventory, while liability insurance covers legal
                responsibilities arising from injuries, accidents, or
                negligence.
              </p>
              <p>
                Furthermore, business insurance often includes coverage for
                employees, encompassing health insurance, workers' compensation,
                and disability insurance, promoting the well-being of the
                workforce. It's essential for companies to carefully assess
                their needs and select the appropriate insurance coverage to
                mitigate potential financial losses and liabilities. Ultimately,
                investing in business insurance is an investment in the
                sustainability and resilience of a business, providing a safety
                net and supporting growth in an unpredictable and competitive
                business landscape.
              </p>
            </div>
            <div className="box-2">
              <img src="./assetss/images/business.jpg" alt="Erroe" />
            </div>
          </div>
        </div>
        <div className="vision-info">
          <div className="vision-box">
            <div className="img-box">
              <img src="./assetss/images/vision.png" alt="Error" />
            </div>
            <div className="vision-box1">
              <h2>Our vision</h2>
              <p>
                There are many variations of passages of Lorem Ipsum available,
                but the majority have suffered alteration in some form, by
                injected humour, or randomised words which don't look even
                slightly believable. If you are going to use a passage of Lorem
                Ipsum, you need to be sure there isn't anything embarrassing
                hidden in the middle of text. All the Lorem Ipsum generators on
                the Internet tend to repeat predefined chunks as necessary,
              </p>
            </div>
          </div>
        </div>
        <div className="btn-cantainer">
          <div className="btn-1">
            <h1>Solving your Insurance Needs on Time</h1>
          </div>
          <div className="btn-1">
            <h2>
              Get world-class insurance coverage that not only protects you but
              also helps you build wealth. Let us be your insurance partner
            </h2>
            <div className="btn-info">
              <button>Get Started</button>
              <button>Contact Us</button>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default BusinessInsurance;
